export default {
  setupInterceptors: (
    axios: { interceptors: { request: { use: any }; response: { use: any } } },
    isFormData = false,
  ) => {
    axios.interceptors.request.use(
      (configs: any) => {
        const config = { ...configs }
        let isTokens: any = localStorage.getItem("token")

        if (isTokens) {
          config.headers["Authorization"] = `Bearer ${isTokens}`
        }
        if (!isTokens) {
          if (
            !window.location.href.includes("/") &&
            !window.location.href.includes("register") &&
            !window.location.href.includes("reset-password") &&
            !window.location.href.includes("forgot-password")
          ) {
            window.location.href = "/"
          }
        }
        if (isFormData) {
          config.headers["Content-Type"] = "multipart/form-data"
        }
        if (window.location.href.includes("register")) {
          config.headers["Content-Type"] = "application/x-www-form-urlencoded"
        }
        return config
      },
      (error: any) => {
        return Promise.reject(error)
      },
    )
    axios.interceptors.response.use(
      (response: any) => successHandler(response),
      (error: any) => errorHandler(error),
    )
    const errorHandler = (error: any) => {
      return Promise.reject({ ...error })
    }

    const successHandler = (response: any) => {
      return response
    }
  },
}
