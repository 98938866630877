import React from "react"
import { useSelector } from "react-redux"
import store from "../../store"
import Titles from "../common/Title/Title"
import VideoModal from "./videomodel"
// eslint-disable-next-line import/no-extraneous-dependencies
// import ReactPlayer from "react-player"

const OnlineCourses = () => {
  const storeReducer = useSelector(
    (state: ReturnType<typeof store.getState>) => state.courseReducers.course,
  )

  const newStore = useSelector(
    (state: ReturnType<typeof store.getState>) =>
      state.courseReducers?.multipleVideo,
  )

  const [playing, setPlaying] = React.useState(false)
  const [url, setUrl] = React.useState("")
  const [name, setName] = React.useState("")

  const handleShowModal = (value: string, value_name: string) => {
    setPlaying(true)
    setUrl(value)
    setName(value_name)
  }

  const handleCloseModal = () => {
    setPlaying(false)
  }

  return (
    <>
      <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6'>
        <div className='add-category-details'>
          <Titles label={`ONLINE CLASS`} />
          <div className='recipe-uploaded-video-details'>
            <div className='recipe-uploaded-video-image'>
              {storeReducer.selectedImage &&
              storeReducer.selectedImage !== "offline_image" &&
              storeReducer.selectedImage !== undefined &&
              storeReducer.selectedImage !== "Thubnails" ? (
                <img
                  src={URL.createObjectURL(storeReducer?.selectedImage)}
                  alt='Thumb'
                />
              ) : storeReducer?.editImage ? (
                <img src={storeReducer?.editImage} alt='' />
              ) : (
                <img src='/images/online-class-img.png' alt='' />
              )}
            </div>
            <div className='uploaded-video-title'>
              <p>{`${
                storeReducer.course_name !== "" ? storeReducer.course_name : ""
              }`}</p>
            </div>
            <div className='uploaded-video-discription border-class'>
              <p>
                {`${
                  storeReducer.course_details !== ""
                    ? storeReducer.course_details
                    : ""
                }`}
              </p>
            </div>

            {/*<!--------multiple image section---------!>*/}
            {storeReducer.showNextSection === false
              ? storeReducer?.contentImg !== undefined &&
                storeReducer?.contentImg?.file
                ? storeReducer?.contentImg?.file.map((img: any) => {
                    return (
                      <div className='multiple-image-show'>
                        <img src={URL.createObjectURL(img)} alt={""} />
                        <p>{img?.name}</p>
                      </div>
                    )
                  })
                : storeReducer?.editContentImg &&
                  storeReducer?.editContentImg.map((img: any) => {
                    return (
                      <div className='multiple-image-show'>
                        <img src={img?.media_fk?.media_url} alt={""} />
                        <p>{img?.media_fk?.media_name}</p>
                      </div>
                    )
                  })
              : ""}

            {/*<!--------promo video section---------!>*/}
            {storeReducer.showNextSection === true &&
              storeReducer?.PromoUploadVideo && (
                <div className='multiple-image-show promo-video'>
                  <div className='icon-section'>
                    <div>
                      <button
                        onClick={() =>
                          handleShowModal(
                            storeReducer.PromoUploadVideo.media_fk.media_url,
                            storeReducer.PromoUploadVideo.media_fk.media_name,
                          )
                        }
                        className='btn btn-link'
                      >
                        <img
                          className=''
                          src={"/images/video-icon.svg"}
                          alt={""}
                        />
                      </button>
                      <VideoModal
                        type='normal'
                        show={playing}
                        handleClose={handleCloseModal}
                        titleName={name}
                        videoUrl={`${url}`}
                      />
                    </div>
                    <p
                      style={{
                        width: "360px",
                        padding: "0",
                        overflow: "hidden",
                        position: "relative",
                        display: "inline-block",
                        textAlign: "center",
                        textDecoration: "none",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        paddingLeft: "11px",
                      }}
                    >
                      {storeReducer.PromoUploadVideo?.media_fk
                        ? storeReducer.PromoUploadVideo?.media_fk?.media_name
                        : storeReducer.PromoUploadVideo?.name}
                    </p>
                  </div>
                  <p className='ml-auto'>
                    {Number(storeReducer?.durationValue)?.toFixed(0) +
                      " Minutes"}
                  </p>
                </div>
              )}

            {/*<!--------multiple video section---------!>*/}
            {storeReducer.showNextSection === true &&
              newStore &&
              newStore?.multipleVideo &&
              newStore?.multipleVideo.map((video: any, index: React.Key) => {
                const duration = newStore?.durationMultiple[index]
                  ? newStore?.durationMultiple[index]
                  : newStore?.durationMultiple[0]
                if (video?.media_fk?.media_desc === "course_videos") {
                  if (video?.media_fk?.media_url) {
                    return (
                      <div className='multiple-image-show promo-video'>
                        <div className='icon-section'>
                          <div>
                            <button
                              onClick={() =>
                                handleShowModal(
                                  `https://api.foodcouture.in/hlsvideo/${video.media_fk.media_url}/index.m3u8`,
                                  video.media_fk.media_name,
                                )
                              }
                              className='btn btn-link'
                            >
                              <img
                                className=''
                                src={"/images/video-icon.svg"}
                                alt={""}
                              />
                            </button>
                          </div>
                          <p>
                            {video?.selectedImage?.name
                              ? video?.selectedImage?.name
                              : video.media_fk?.media_name
                              ? video.media_fk?.media_name
                              : video?.selectedImage?.name}
                          </p>
                        </div>
                        <p className='ml-auto'>
                          {video?.media_fk?.media_duration !== null &&
                          video?.media_fk?.media_duration !== undefined
                            ? Number(video?.media_fk?.media_duration)?.toFixed(
                                0,
                              ) + " Minutes"
                            : newStore?.durationMultiple[index] !== undefined &&
                              video?.media_fk?.media_duration !== null
                            ? Number(duration)?.toFixed(0) + " Minutes"
                            : ""}
                        </p>
                      </div>
                    )
                  }
                } else if (video?.selectedImage) {
                  return (
                    <div className='multiple-image-show promo-video'>
                      <div className='icon-section'>
                        <img
                          className=''
                          src={"/images/video-icon.svg"}
                          alt={""}
                        />
                        <p>
                          {video?.selectedImage?.name
                            ? video?.selectedImage?.name
                            : video.selectedImage?.media_name
                            ? video.selectedImage?.media_name
                            : video?.selectedImage?.name}
                        </p>
                      </div>
                      <p className='ml-auto'>
                        {video?.media_fk?.media_duration !== null &&
                        video?.media_fk?.media_duration !== undefined
                          ? duration?.toFixed(0) + " Minutes"
                          : video?.media_duration !== undefined &&
                            video?.media_duration + " Minutes"}
                      </p>
                    </div>
                  )
                }
              })}

            {/*<!--------pdf show section---------!>*/}
            {storeReducer.showNextSection === false &&
              storeReducer?.selectPdf !== "" &&
              storeReducer?.selectPdf !== undefined && (
                <div className='multiple-image-show promo-video'>
                  <div className='icon-section'>
                    {storeReducer?.selectPdf !== "" &&
                      storeReducer?.selectPdf !== undefined && (
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='38'
                          height='38'
                          fill='currentColor'
                          className='bi bi-filetype-pdf'
                          viewBox='0 0 16 16'
                        >
                          <path
                            fillRule='evenodd'
                            d='M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z'
                          />
                        </svg>
                      )}
                    <p className={"mb-2"}>
                      {storeReducer.selectPdf?.name
                        ? storeReducer.selectPdf?.name
                        : storeReducer.editPdfCourse !== false &&
                          storeReducer.editPdfCourse[0] &&
                          storeReducer.editPdfCourse[0]?.media_fk &&
                          storeReducer.editPdfCourse[0]?.media_fk?.media_name}
                    </p>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  )
}

export default OnlineCourses
