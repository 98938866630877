import React from "react"
import CoursesLists from "../../components/onlineCoursesList/onlineCourseList"

const CoursesList = () => {
  return (
    <>
      <CoursesLists />
    </>
  )
}

export default CoursesList
