/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { singleRecord, courseType } from "./types"

const initialState: courseType = {
  course: {},
}

export const singleCourseSlice = createSlice({
  name: "courseSlice",
  initialState,
  reducers: {
    SingleCourse: (state, action: PayloadAction<Partial<singleRecord>>) => {
      state.course = action.payload
    },
  },
})

export const { SingleCourse } = singleCourseSlice.actions

export default singleCourseSlice.reducer
