import React, { useEffect, useState } from "react"
import UploadVideo from "../common/uploadVideo/uploadVideo"
import {
  CourseVideoProps,
  onlinePrepareData,
  inputMap,
} from "../../config/commonType"
import Inputs from "../common/Inputs/InputField"
import OnlineAddVideoButton from "../common/Buttons/onlineAddVideoButton"
import apiConfigWithFormData from "../../config/apiConfigWithFormData"
import { toast } from "react-toastify"
import {
  errorMessage,
  errorMessageShow,
  requestOptions,
  resetMessageShow,
  uploadError,
} from "../common/Function/commonFunction"
import { useNavigate } from "react-router-dom"
import { MakeStoreMultipleVideo } from "../../store/coursestore/courseSlice"
import { useDispatch } from "react-redux"
import { useMutation } from "@apollo/client"
import { DELETE_COURSE_MEDIA, SINGLE_RECORD_FETCH } from "./queries"
import { urls } from "../../constants"
import { SingleCourse } from "../../store/singleCourse/courseSlice"

const OnlineCourseVideos = (props: CourseVideoProps) => {
  const { newArray, course_id, courseMultipleArray } = props
  let navigate = useNavigate()
  const dispatch = useDispatch()

  const [deleteCourse] = useMutation(DELETE_COURSE_MEDIA)
  const toastId = React.useRef<any>(null)
  const [isProcess, setIsProcess] = useState<boolean>(false)
  const [selectedImage, setSelectedImage] = useState<any>()
  const [durationMultiple, setDurationMultiple] = useState<any>([])
  const [disabled, setDisabled] = useState(false)
  const [inputList, setInputList] = useState<any>([
    { course_name: "", selectedImage: "" },
  ])

  // handle input change
  const handleInputChange = (value?: any, name?: any, index?: any) => {
    const list = [...inputList]
    const newArray =
      list &&
      list.map((item, ids) => {
        if (ids === index) {
          if (name === "course_name") {
            return { ...item, course_name: value }
          } else if (name === "selectedImage") {
            return { ...item, selectedImage: value }
          }
        } else {
          return item
        }
      })
    setInputList(newArray)
  }

  useEffect(() => {
    if (newArray !== "") {
      editValue()
    }
  }, [newArray.course_media])

  useEffect(() => {
    const data = {
      multipleVideo:
        selectedImage === undefined
          ? inputList[0].course_name !== ""
            ? inputList
            : newArray.course_media
          : inputList,
      durationMultiple:
        selectedImage === undefined ? newArray.course_media : durationMultiple,
    }
    dispatch(MakeStoreMultipleVideo(data))
  }, [durationMultiple, newArray])

  const editValue = () => {
    courseMultipleArray &&
      courseMultipleArray.map((item: any[] | undefined) => {
        if (item !== undefined) {
          return setInputList(item)
        }
      })
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { course_name: "", selectedImage: "" }])
  }

  // handle click event of the Remove button
  const handleRemoveClick = (index: number, media_id: number) => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
    if (media_id) {
      deleteCourse({
        variables: { media_id },
        onCompleted: async (data) => {
          if (data) {
            const graphql = JSON.stringify({
              query: SINGLE_RECORD_FETCH,
              variables: { course_id },
            })

            const Url = urls.api_url
            // @ts-ignore
            const res = await fetch(Url, requestOptions(graphql))
            const usersData = await res.json()
            dispatch(SingleCourse(usersData.data?.courses))
            toast.success(`Course video delete successfully!`)
            const data = {
              multipleVideo:
                selectedImage === undefined
                  ? inputList[0].course_name !== ""
                    ? inputList
                    : newArray.course_media
                  : inputList,
              durationMultiple:
                selectedImage === undefined
                  ? newArray.course_media
                  : durationMultiple,
            }
            dispatch(MakeStoreMultipleVideo(data))
          }
        },
        onError: (error) => {
          if (error.message.includes("Foreign key violation.")) {
            setIsProcess(false)
            return errorMessage("Something went wrong!")
          } else {
            return errorMessageShow(error)
          }
        },
      })
    }
  }
  // const time: any = Math.abs(Number(durationMultiple[0]))
  const prepareFormVideo = (course_id: any, item: onlinePrepareData | any) => {
    const formData = new FormData()
    formData.append("file", item.selectedImage)
    formData.append("media_name", item?.course_name)
    formData.append("media_type", "video")
    if (item?.media_id !== "" && item?.media_id !== undefined) {
      formData.append("media_id", item?.media_id?.toString())
    } else {
      formData.append("id", course_id.toString())
    }
    formData.append("type", "10")
    // formData.append(
    //   "media_duration",
    //   durationMultiple[index] ? Math.abs(durationMultiple[index]) : time,
    // )
    formData.append("media_video_type", "normal")
    formData.append("media_desc", "course_videos")
    formData.append("is_thumbnail", "false")
    return formData
  }

  //upload image api call
  function uploadMedia(data: any) {
    setIsProcess(true)
    apiConfigWithFormData
      .post("upload-media", data, {
        onUploadProgress: (p: any) => {
          const progress = (p.loaded / p.total) * 100
          const pro: any = Math.round(progress)

          if (toastId.current === null) {
            toastId.current = toast(`Upload video in Progress ${pro} %`, {
              // @ts-ignore
              pro,
            })
          } else {
            toast.update(toastId.current, {
              // @ts-ignore
              pro,
              render: `Upload video in ${pro} % successfully`,
              type: "success",
            })
          }
        },
      })
      .then(() => {
        setIsProcess(false)
        setSelectedImage(undefined)
        navigate(`/courses-list`)
        setDisabled(false)
      })
      .catch((response: any) => {
        setDisabled(false)
        if (response.message) {
          return errorMessage(response.message)
        } else {
          uploadError(response)
        }
      })
  }

  //on save button click event
  const submitSaveButton = () => {
    if (newArray !== "") {
      setDisabled(true)
      inputList &&
        inputList.map((item: any) => {
          if (item.media_id !== undefined) {
            if (!item?.selectedImage?.media_url) {
              return updateUploadMedia(
                item.media_id,
                prepareFormVideo("", item),
              )
            }
          } else {
            if (!item?.selectedImage?.media_url) {
              return uploadMedia(prepareFormVideo(course_id, item))
            }
          }
        })
    } else {
      setDisabled(true)
      inputList &&
        inputList.map((item: any) => {
          return uploadMedia(prepareFormVideo(course_id, item))
        })
    }
  }

  //update upload image api call
  function updateUploadMedia(media_id: any, data: any) {
    setIsProcess(true)
    apiConfigWithFormData
      .put(`/update-media/${media_id}`, data, {
        onUploadProgress: (p: any) => {
          const progress = (p.loaded / p.total) * 100
          const pro: any = Math.round(progress)

          // check if we already displayed a toast
          if (toastId.current === null) {
            toastId.current = toast(`Upload video in Progress ${pro} %`, {
              // @ts-ignore
              pro,
            })
          } else {
            toast.update(toastId.current, {
              // @ts-ignore
              pro,
              render: `Upload video in ${pro} % successfully`,
              type: "success",
            })
          }
        },
      })
      .then(() => {
        setIsProcess(false)
        setSelectedImage(undefined)
        navigate(`/courses-list`)
      })
      .catch((response) => {
        setIsProcess(false)
        setDisabled(false)
        if (response.message) {
          return errorMessage(response.message)
        } else {
          uploadError(response)
        }
      })
  }

  //on reset function
  const resetClick = () => {
    if (newArray !== "") {
      editValue()
      resetMessageShow().then((r) => console.log(r))
    } else {
      resetMessageShow().then((r) => console.log(r))
      setInputList([{ course_name: "", selectedImage: "" }])
      setSelectedImage(undefined)
    }
  }

  return (
    <>
      <div className='add-category-details'>
        <div className='add-category-and-close-button'>
          <div className='add-category-title'>
            <h1>{`${
              newArray !== "" ? "EDIT" : "ADD"
            } ONLINE COURSE VIDEOS`}</h1>
          </div>
        </div>
        <div className='only-online-video-padding'>
          <div className='scroll-video'>
            {inputList.map((x: inputMap, i: any) => {
              return (
                <div className='box'>
                  {inputList.length !== 1 && (
                    <img
                      className={"delete-img"}
                      src={"/images/deleteIcon.png"}
                      alt={"delete"}
                      onClick={() => handleRemoveClick(i, x?.media_id)}
                    />
                  )}
                  <Inputs
                    label={"Course Name"}
                    type={"text"}
                    placeHolder={"Enter Course name"}
                    name='course_name'
                    value={x.course_name}
                    onChange={(e) => {
                      handleInputChange(e.target.value, e.target.name, i)
                    }}
                  />
                  <UploadVideo
                    disable={!x.course_name}
                    setDurationMultiple={setDurationMultiple}
                    durationMultiple={durationMultiple}
                    title={"Upload Video"}
                    toastIdRef={toastId}
                    name='selectedImage'
                    setSelectedImage={setSelectedImage}
                    selectedImage={selectedImage}
                    handleInputChange={(e: any) =>
                      handleInputChange(
                        e.target.files && e.target.files[0],
                        "selectedImage",
                        i,
                      )
                    }
                  />
                </div>
              )
            })}
          </div>
          {/*<!------- button section start-----!>*/}
          <div className='button-div'>
            <OnlineAddVideoButton
              disableFirst={disabled}
              disable={!selectedImage || disabled}
              isProcess={isProcess}
              handleAddClick={handleAddClick}
              onClickSecond={submitSaveButton}
              onClickFirst={resetClick}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default OnlineCourseVideos
