import React from "react"
import AddBannerDetails from "./add-banner-details"
import BannerPrev from "./banner-prev"
import TabTitle from "../../pages/tab-title/TabTitle"

const Addbanner = (props: { id?: any; singleArray?: any }) => {
  const { id, singleArray } = props
  return (
    <>
      <TabTitle title={"Add Banner"} />
      <section className='add-recipes'>
        <div className='common-page-padding'>
          <div className='row'>
            {/*<!------ add Banner section start -----!>*/}
            <AddBannerDetails id={id} singleArray={singleArray} />

            {/*<!------ Banner-Prev section start -----!>*/}
            <BannerPrev />
          </div>
        </div>
      </section>
    </>
  )
}

export default Addbanner
