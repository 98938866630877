import { Routes, Route } from "react-router-dom"
import AddCategory from "./pages/addcategories/add-category"
import AddRecipe from "./pages/addrecipe/add-recipes"
import AmazonProduct from "./pages/amazonproducts/amazon-products"
import CoursesList from "./pages/onlinecourselist/courses-list"
import OfflinClassesLits from "./pages/offlinecourselist/offline-course-list"
import OfflineCourses from "./pages/offlinecourselist/offline-courses"
import RecipeLists from "./pages/recipelist/recipe-list"
import OnlineCourse from "./components/addOnlineCourses/onlineCourse"
import RecipeAddCategory from "./components/recipeAddCategory/recipeAddCategory"
import EditOnlineCourse from "./components/addOnlineCourses/editOnlineCourse"
import EditOfflineCourse from "./components/addOfflineCourse/editOfflineCourse"
import Dashboard from "./pages/dashboard/dashboard"
import AddOurProduct from "./pages/addOurProduct/add-our-product"
import AddBanner from "./pages/Home/add-banner"
import BannerList from "./pages/Home/banner-list"
import OurProductsList from "./pages/OurProductsList/our-products-list"
import Amazonproductlists from "./pages/amazonproductslist/amazon-products-list"
import AddCateringVideo from "./pages/catering/add-catering-video"
import EditRecipes from "./components/addRecipes/editRecipes"
import EditAmazonProduct from "./components/amazonProduct/editAmazonProduct"
import EditOurProduct from "./components/addOurProduct/editOurProduct"
import EditCatering from "./components/catering/editCatering"
import CateringVideoList from "./components/cateringVideoList/cateringVideosList"
import UserList from "./pages/user-lists/user-list"
import UserActive from "./pages/user-lists/user-active"
import EditBanner from "./components/Home/editBanner"

const Router = () => {
  return (
    <Routes>
      <Route path='/courses-list' element={<CoursesList />} />
      <Route path='/add-category' element={<AddCategory />} />
      <Route path='/add-course' element={<OnlineCourse />} />
      <Route path='/edit-online-course/:id' element={<EditOnlineCourse />} />
      <Route path='/edit-offline-course/:id' element={<EditOfflineCourse />} />
      <Route path='/edit-recipe/:id' element={<EditRecipes />} />
      <Route path='/edit-amazon-product/:id' element={<EditAmazonProduct />} />
      <Route path='/edit-our-product/:id' element={<EditOurProduct />} />
      <Route path='/edit-catering/:id' element={<EditCatering />} />
      <Route path='/amazon-products-list' element={<Amazonproductlists />} />
      <Route path='/offline-course-list' element={<OfflinClassesLits />} />
      <Route path='/recipe-list' element={<RecipeLists />} />
      <Route path='/add-recipes-category' element={<RecipeAddCategory />} />
      <Route path='/add-recipes' element={<AddRecipe />} />
      <Route path='/amazon-products' element={<AmazonProduct />} />
      <Route path='/add-offline-course' element={<OfflineCourses />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/add-our-product' element={<AddOurProduct />} />
      <Route path='/add-banner' element={<AddBanner />} />
      <Route path='/edit-banner/:id' element={<EditBanner />} />
      <Route path='/banner-list' element={<BannerList />} />
      <Route path='/our-products-list' element={<OurProductsList />} />
      <Route path='/catering-list' element={<CateringVideoList />} />
      <Route path='/add-catering-video' element={<AddCateringVideo />} />
      <Route path='/User-list' element={<UserList />} />
      <Route path='/user-active/:id' element={<UserActive />} />
    </Routes>
  )
}

export default Router
