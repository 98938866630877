import React from "react"
import RecipesList from "../../components/recipesList/recipesList"

const RecipeLists = () => {
  return (
    <>
      <RecipesList />
    </>
  )
}
export default RecipeLists
