import React from "react"
import { useSelector } from "react-redux"
import store from "../../store"
import Titles from "../common/Title/Title"

const OfflineClasses = () => {
  const storeReducer = useSelector(
    (state: ReturnType<typeof store.getState>) => state.courseReducers.course,
  )

  return (
    <>
      <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6'>
        <div className='add-category-details'>
          <Titles label={`OFFLINE CLASS`} />
          <div className='recipe-uploaded-video-details'>
            <div className='recipe-uploaded-video-image'>
              {storeReducer.selectedImage &&
              storeReducer.selectedImage !== "Thubnails" &&
              storeReducer.selectedImage !== undefined &&
              storeReducer.selectedImage !== "offline_image" ? (
                <img
                  src={URL.createObjectURL(storeReducer?.selectedImage)}
                  alt='Thumb'
                />
              ) : storeReducer?.editImage ? (
                <img src={storeReducer?.editImage} alt='' />
              ) : (
                <img src='/images/online-class-img.png' alt='' />
              )}
            </div>
            <div className='uploaded-video-title'>
              <p>{storeReducer.course_name}</p>
            </div>
            <div className='uploaded-video-discription'>
              <p>{storeReducer.course_details}</p>
            </div>
            <div className='online-preview-lable-and-name'>
              <p>
                {storeReducer.course_discount_amount
                  ? " ₹ " + storeReducer.course_discount_amount
                  : ""}
                <span>
                  {storeReducer.course_amount
                    ? " ₹ " + storeReducer.course_amount
                    : ""}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OfflineClasses
