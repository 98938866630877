import React from "react"
import EditDeleteButton from "../Buttons/editDeleteButton"
import { singleBannerCardProps } from "../../../config/commonType"

const SingleBannerCard = (props: singleBannerCardProps) => {
  const { item, onClick, banner_id, editClick, isIds, isProcess } = props

  return (
    <>
      <div className='col-12 col-sm-6 col-md-12 col-lg-6'>
        <div className='catering-list-details'>
          <div className='couses-images'>
            {item?.media_fk?.media_url && item?.media_fk?.media_url ? (
              <img src={item?.media_fk?.media_url} alt='' />
            ) : (
              <img src='/images/catering-img.png' alt='' />
            )}
          </div>
          <EditDeleteButton
            matchId={isIds}
            isProcess={isProcess}
            onClickDelete={onClick}
            course_id={banner_id}
            editClick={editClick}
          />
        </div>
      </div>
    </>
  )
}

export default SingleBannerCard
