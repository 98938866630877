import gql from "graphql-tag"

export const USER_PAGINATION = gql`
  query MyQuery($offset: Int, $limit: Int) {
    user_master(offset: $offset, limit: $limit) {
      user_id
      email_id
      user_status
      mobile_no
      full_name
      mobile_verified
      media_id
      is_deleted
      updated_dt
      role_id
      user_password
      email_verified
      created_dt
      channel
      dialer_code
    }
    user_master_aggregate {
      aggregate {
        count
      }
    }
  }
`

export const FETCH_COURSE_SINGLE = `
  query GetCourseEnrollments($user_id: Int) {
  course_enrollments(where: {user_id: {_eq: $user_id}}, order_by: {created_dt: desc}) {
    course_enrollment_id
    created_by
    updated_by
    is_deleted
    course_channel
    course_enrollment_status
    course_id
    user_id
  }
}
`

export const ALL_USERS = gql`
  query GetUserMaster {
    user_master {
      user_id
      email_verified
      is_deleted
      mobile_verified
      user_status
      channel
      email_id
      full_name
      mobile_no
      media_id
      role_id
      user_password
      created_dt
      updated_dt
      dialer_code
    }
  }
`

export const SEARCHED_USER_LIST = gql`
  query GetCourseEnrollments($full_name: String) {
    user_master(where: { full_name: { _ilike: $full_name } }) {
      user_id
      email_verified
      is_deleted
      mobile_verified
      user_status
      channel
      email_id
      full_name
      mobile_no
      media_id
      role_id
      user_password
      created_dt
      updated_dt
      dialer_code
    }
  }
`

export const GET_COURSE = `
query GetCourses ($user_id: bigint, $user_ids: Int) {
  courses {
    course_id
    course_status
    course_name
    course_discount_amount
    total_time
    course_type
  }
  user_master(where: {user_id: {_eq: $user_id}}) {
    full_name
    media_id
    mobile_no
    mobile_verified
    role_id
    updated_dt
    user_id
    user_password
    user_status
    course_enrollments(where: {user_id: {_eq: $user_ids}}, order_by: {created_dt: desc}) {
      course_id
      is_deleted
      user_id
      razorpay_payment_id
      payment_method
      transfer_amount
      updated_dt
      course_channel
      course_enrollment_id
      course_enrollment_status
      created_dt
      currency
    }
    created_dt
    email_verified
    email_id
  }
}
`

export const INSERT_COURSE_ENROLLMENT = gql`
  mutation InsertCourseEnrollments(
    $course_id: Int
    $user_id: Int
    $is_deleted: Boolean
    $created_by: bigint
    $updated_by: bigint
    $transfer_amount: numeric
  ) {
    insert_course_enrollments(
      objects: {
        course_id: $course_id
        user_id: $user_id
        payment_method: "offline"
        course_channel: "web"
        is_deleted: $is_deleted
        currency: "INR"
        created_by: $created_by
        updated_by: $updated_by
        transfer_amount: $transfer_amount
      }
    ) {
      affected_rows
      returning {
        course_enrollment_id
        created_by
        updated_by
        is_deleted
        course_channel
        course_enrollment_status
        currency
        payment_method
        razorpay_payment_id
        course_id
        user_id
        transfer_amount
        created_dt
        updated_dt
      }
    }
  }
`

export const UPDATE_ENROLLMENT = gql`
  mutation UpdateCourseEnrollments(
    $is_deleted: Boolean
    $course_enrollment_id: bigint
    $course_id: Int
    $updated_by: bigint
    $transfer_amount: numeric
  ) {
    update_course_enrollments(
      where: { course_enrollment_id: { _eq: $course_enrollment_id } }
      _set: {
        is_deleted: $is_deleted
        course_id: $course_id
        currency: "INR"
        updated_by: $updated_by
        transfer_amount: $transfer_amount
      }
    ) {
      returning {
        course_channel
        course_enrollment_status
        course_id
        created_by
        currency
        payment_method
        razorpay_payment_id
        transfer_amount
        updated_by
        user_id
        is_deleted
        created_dt
        updated_dt
        course_enrollment_id
      }
      affected_rows
    }
  }
`
