import React, { useState } from "react"
import Inputs from "../common/Inputs/InputField"
import ResetDoneButton from "../common/Buttons/resetDoneButton"
import UploadVideo from "../common/uploadVideo/uploadVideo"
import { useSelector } from "react-redux"
import store from "../../store"
import { useMutation } from "@apollo/client"
import {
  FETCH_RECIPES,
  INSERT_RECIPES,
  UPDATE_RECIPES,
  updateUploadMedia,
  uploadMedia,
} from "./queries"
import {
  errorMessageShow,
  resetMessageShow,
  successMessageShow,
} from "../common/Function/commonFunction"
import { RecipeVideoProps } from "../../config/commonType"
import { useNavigate } from "react-router-dom"
import apiConfigWithFormData from "../../config/apiConfigWithFormData"
import { toast } from "react-toastify"
import {
  prepareFormDataRecipe,
  prepareFormPasteLink,
  prepareFormVideo,
} from "../common/Function/prepareFormData"

const RecipeVideo = (props: RecipeVideoProps) => {
  const {
    setShowNextSection,
    EmptyAllField,
    newArray,
    editAllField,
    pasteLink,
    uploadVideo,
    onChangePasteLink,
    setUploadVideo,
    media_id,
    media_id1,
    media_id2,
    values,
    durationValue,
    setDurationValue,
  } = props
  let navigate = useNavigate()
  const toastId = React.useRef<any>(null)
  const [addRecipes] = useMutation(INSERT_RECIPES)
  const [updateRecipes] = useMutation(UPDATE_RECIPES)
  const [isProcess, setIsProcess] = useState<any>(false)
  const [disabled, setDisabled] = useState(false)
  const recipe_id = newArray.recipe_id
  const storeReducer = useSelector(
    (state: ReturnType<typeof store.getState>) => state.courseReducers.recipe,
  )

  const addRecipe = () => {
    setIsProcess(true)
    addRecipes({
      variables: values,
      refetchQueries: [{ query: FETCH_RECIPES }],
      onError: (error) => {
        if (error.message) {
          errorMessageShow(error).then((r) => console.log(r))
        }
      },
      onCompleted: (data) => {
        if (data.insert_recipes.__typename) {
          setIsProcess(false)
          const recipe_id = data.insert_recipes.returning[0].recipe_id
          if (
            storeReducer?.selectedImage !== undefined &&
            storeReducer?.selectedImage !== "recipe image"
          ) {
            uploadMedia(
              prepareFormDataRecipe(storeReducer, recipe_id, media_id),
            )
          }
          setTimeout(() => {
            if (storeReducer?.uploadVideo !== undefined) {
              uploadMediaVideo(
                prepareFormVideo(
                  storeReducer,
                  recipe_id,
                  media_id1,
                  durationValue,
                ),
                true,
              )
            } else {
              navigate("/recipe-list")
              setShowNextSection(false)
              successMessageShow("Recipes add successfully!").then((r) =>
                console.log(r),
              )
            }
            if (storeReducer?.pasteLink !== undefined) {
              uploadMedia(
                prepareFormPasteLink(storeReducer, recipe_id, media_id2),
              )
            }
          }, 1000)
        }
      },
    }).then((r) => console.log(r))
  }

  const editRecipe = () => {
    setIsProcess(true)
    updateRecipes({
      variables: values,
      refetchQueries: [{ query: FETCH_RECIPES }],
      onError: (error) => {
        if (error.message) {
          errorMessageShow(error).then((r) => console.log(r))
        }
      },
      onCompleted: (data) => {
        if (data.update_recipes.__typename) {
          if (
            media_id !== "" &&
            media_id !== undefined &&
            storeReducer?.selectedImage !== "recipe image" &&
            storeReducer?.selectedImage !== undefined
          ) {
            updateUploadMedia(
              media_id,
              prepareFormDataRecipe(storeReducer, recipe_id, media_id),
            )
          } else {
            if (
              storeReducer?.selectedImage !== undefined &&
              storeReducer?.selectedImage !== "recipe image"
            ) {
              uploadMedia(
                prepareFormDataRecipe(storeReducer, recipe_id, media_id),
              )
            }
          }
          setTimeout(() => {
            setIsProcess(false)
            if (
              media_id1 !== undefined &&
              media_id1 !== "" &&
              uploadVideo !== undefined
            ) {
              updateUploadMediaVideo(
                media_id1,
                prepareFormVideo(
                  storeReducer,
                  recipe_id,
                  media_id1,
                  durationValue,
                ),
                true,
              )
            } else if (uploadVideo !== undefined) {
              uploadMediaVideo(
                prepareFormVideo(
                  storeReducer,
                  recipe_id,
                  media_id1,
                  durationValue,
                ),
                true,
              )
            } else {
              successMessageShow("Recipes edit successfully!").then((r) =>
                console.log(r),
              )
              navigate(`/recipe-list`)
            }
            if (
              media_id2 !== undefined &&
              media_id2 !== "" &&
              storeReducer?.pasteLink !== undefined
            ) {
              updateUploadMedia(
                media_id2,
                prepareFormPasteLink(storeReducer, recipe_id, media_id2),
              )
            } else {
              if (storeReducer?.pasteLink !== undefined) {
                uploadMedia(
                  prepareFormPasteLink(storeReducer, recipe_id, media_id2),
                )
              }
            }
          }, 1000)
        }
      },
    }).then((r) => console.log(r))
  }

  //onClick save button
  const submitSaveButton = () => {
    if (newArray !== "") {
      setDisabled(true)
      editRecipe()
    } else {
      setDisabled(true)
      addRecipe()
    }
  }

  //on reset function
  const resetClick = () => {
    if (newArray !== "") {
      editAllField()
      resetMessageShow().then((r) => console.log(r))
    } else {
      resetMessageShow().then((r) => console.log(r))
      EmptyAllField()
    }
    setShowNextSection(false)
  }

  //update upload image api call
  function updateUploadMediaVideo(
    media_id: any,
    data: any,
    isNavigate: boolean,
  ) {
    setIsProcess(true)
    apiConfigWithFormData
      .put(`/update-media/${media_id}`, data, {
        onUploadProgress: (p: any) => {
          const progress = (p.loaded / p.total) * 100
          const pro: any = Math.round(progress)

          // check if we already displayed a toast
          if (toastId.current === null) {
            toastId.current = toast(`Upload video in Progress ${pro} %`, {
              // @ts-ignore
              pro,
              autoClose: 5000,
            })
          } else {
            toast.update(toastId.current, {
              // @ts-ignore
              pro,
              render: `Upload video in ${pro} % successfully`,
              type: "success",
              autoClose: 5000,
            })
          }
        },
      })
      .then(() => {
        if (isNavigate) {
          setIsProcess(false)
          navigate("/recipe-list")
          successMessageShow("Recipes edit successfully!").then((r) =>
            console.log(r),
          )
        }
      })
  }

  function uploadMediaVideo(data: any, isNavigate: boolean) {
    setIsProcess(true)
    apiConfigWithFormData
      .post("upload-media", data, {
        onUploadProgress: (p: any) => {
          const progress = (p.loaded / p.total) * 100
          const pro: any = Math.round(progress)

          if (toastId.current === null) {
            toastId.current = toast(`Upload video in Progress ${pro} %`, {
              // @ts-ignore
              pro,
            })
          } else {
            toast.update(toastId.current, {
              // @ts-ignore
              pro,
              render: `Upload video in ${pro} % successfully`,
              type: "success",
            })
          }
        },
      })
      .then(() => {
        if (isNavigate) {
          setIsProcess(false)
          navigate("/recipe-list")
          setShowNextSection(false)
          successMessageShow("Recipes add successfully!").then((r) =>
            console.log(r),
          )
        }
      })
  }

  return (
    <>
      <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-5'>
        <div className='add-category-details'>
          <div className='add-category-and-close-button'>
            <div className='add-category-title'>
              <h1>{`${newArray !== "" ? "EDIT" : "ADD"} RECIPES`}</h1>
            </div>
          </div>
          <div className='only-padding'>
            {/*<!------- upload video section start-----!>*/}
            <UploadVideo
              toastIdRef={toastId}
              selectedImage={uploadVideo}
              title={"Upload Video"}
              setSelectedImage={setUploadVideo}
              setDurationValue={setDurationValue}
            />

            <div className='category-input-feild'>
              <div className='separator'>
                <div className='line' />
                <p>Or</p>
                <div className='line' />
              </div>
            </div>

            <Inputs
              type={"text"}
              placeHolder={"Paste Link"}
              name={"pasteLink"}
              onChange={onChangePasteLink}
              label={"YT Video Link"}
              value={pasteLink}
              errors={""}
            />

            {/*<!------- button section start-----!>*/}
            <div className='button-div'>
              <ResetDoneButton
                isProcess={isProcess}
                disable={!pasteLink || disabled}
                // disable={!pasteLink || !uploadVideo}
                firstButton={"Reset"}
                secondButton={"Save"}
                onClickSecond={submitSaveButton}
                onClickFirst={resetClick}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RecipeVideo
