import { Link } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { USER_PAGINATION } from "./queries"
import React, { useEffect, useState } from "react"
import { PropertyUser } from "../../config/commonType"
import TabTitle from "../../pages/tab-title/TabTitle"
import Titles from "../common/Title/Title"
import Pagi from "../common/Pagination/pagi"
import { Button, Form, Modal } from "react-bootstrap"
import apiConfig from "../../config/apiConfig"
import { FaEdit, FaRegCheckSquare, FaRegWindowClose } from "react-icons/fa"
import { urls } from "../../constants"
import { toast } from "react-toastify"

const UserListPage = () => {
  // const { data } = useQuery(ALL_USERS)
  const {
    loading: loading,
    error,
    data: data1,
    fetchMore,
  } = useQuery(USER_PAGINATION, {
    variables: {
      offset: 0,
      limit: 10,
    },
  })
  const [totalRecords, setTotalRecords] = useState(0)
  const [newPageCount, setNewPageCount] = useState(0)
  const [token, setToken] = useState<string>("")
  // const [searchVal, setsearchVal] = useState<string>("")

  useEffect(() => {
    const authToken = localStorage.getItem("token")
    // @ts-ignore
    setToken(authToken)
  })

  const [limit, setLimit] = useState(10)

  const [userData, setUserData] = useState([])

  const [userDetail, setuserDetail] = useState<any>({})
  const [optionValue, setOptionValue] = useState([
    { name: "10", id: 10 },
    { name: "20", id: 20 },
    { name: "50", id: 50 },
    { name: "100", id: 100 },
  ])

  const [show, setShow] = useState(false)

  const handleShow = (item: any) => {
    setuserDetail({
      name: item.full_name,
      email: item.email_id,
      phone: item.mobile_no,
      code: item.dialer_code,
      userId: item.user_id,
    })

    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const editHandler = () => {
    const values = {
      name: userDetail.name,
      email: userDetail.email,
      mobile_number: userDetail.phone,
      code: userDetail.code,
    }

    apiConfig.post(`/user-detail/${userDetail.userId}`, values).then((res) => {
      handleClose()
      toast.success("User Edited successfully!")
      fetchMore({}).then((r) => {
        setUserData(r?.data?.user_master)
        // setsearchVal("")
        // setTotalRecords(data1?.user_master_aggregate?.aggregate?.count)
      })
      console.log(res, "res")
    })
  }

  useEffect(() => {
    if (data1?.user_master_aggregate) {
      setTotalRecords(data1?.user_master_aggregate?.aggregate?.count)
    }
  }, [data1, totalRecords])

  useEffect(() => {
    setUserData(data1?.user_master)
  }, [data1])

  const onPageChanges = React.useCallback(
    async (page: React.SetStateAction<number>) => {
      const offsetValue = Number(page) * 10
      setNewPageCount(page)
      fetchMore({
        variables: {
          offset: offsetValue,
          limit: Number(limit),
        },
      }).then((r) => {
        setUserData(r?.data?.user_master)
        // setTotalRecords(data1?.user_master_aggregate?.aggregate?.count)
      })
    },
    [],
  )

  if (loading) {
    return <div className='loading_message mt-200'>Loading...</div>
  }
  if (error) {
    return <div className='loading_message mt-200'>Error!</div>
  }
  if (data1?.user_master.length === 0) {
    return <div className='loading_message mt-200'>No User Found</div>
  }

  const onChangePageNumber = (page: any) => {
    const offsetValue = page * 10
    fetchMore({
      variables: {
        offset: offsetValue,
        limit: Number(limit),
      },
    }).then((r) => {
      setUserData(r?.data?.user_master)
      // setTotalRecords(data1?.user_master_aggregate?.aggregate?.count)
    })
  }
  let controller: any = null

  let timeout: any = null

  // search data function
  const HandleSearchBar = (full_name: string) => {
    // console.log(search)
    // setsearchVal(full_name)
    clearTimeout(timeout)

    timeout = setTimeout(async () => {
      searchFunc(full_name)
    }, 600)
  }

  const searchFunc = async (full_name: string) => {
    const Url = urls.api_url

    // @ts-ignore

    // const usersData = await res.json()
    // console.log(usersData,".......")
    if (full_name.length > 0) {
      if (controller) {
        controller.abort()
      }
      controller = new AbortController()
      // const tempArr2 = data?.user_master.filter((e: { full_name: string }) => {
      //   return e.full_name.toLowerCase().includes(full_name.toLowerCase())
      // })
      // setUserData(tempArr2)

      const res = await fetch(Url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
            query GetCourseEnrollments {
              user_master( where: {
                _or: [
                  { email_id: {_ilike: "%${full_name}%"} },
                  { full_name: {_ilike: "%${full_name}%"} },
                  { mobile_no: {_ilike: "%${full_name}%"} }
                ]
              }) {
                user_id
                email_verified
                is_deleted
                mobile_verified
                user_status
                channel
                email_id
                full_name
                mobile_no
                media_id
                role_id
                user_password
                created_dt
                updated_dt
                dialer_code
              }
            }`,
        }),
      })
        .then((res) => res.json())
        .then((res) => setUserData(res?.data?.user_master))
      console.log(res)
    } else {
      fetchMore({
        variables: {
          offset: 0,
          limit: Number(limit),
        },
      }).then((r) => {
        setUserData(r?.data?.user_master)
        // setTotalRecords(data1?.user_master_aggregate?.aggregate?.count)
      })
    }
  }

  const onSelectOption = (e: any) => {
    setLimit(e.target.value)
    fetchMore({
      variables: {
        limit: Number(e.target.value),
      },
    }).then((r) => {
      setUserData(r?.data?.user_master)
      setTotalRecords(
        data1?.user_master_aggregate?.aggregate?.count -
          r?.data?.user_master.length,
      )
    })
  }

  const handleBlock = (id: any, user_Status: any) => {
    // const status = {status: userStatus}
    apiConfig
      .post(`/active-deactive-user/${id}`, { status: !user_Status })
      .then((res) => {
        user_Status
          ? toast.success("User deactivate successfully!")
          : toast.success("User activate successfully!")
        fetchMore({}).then((r) => {
          // setsearchVal("")
          setUserData(r?.data?.user_master)
        })
        console.log(res, "res")
      })
  }

  return (
    <>
      <TabTitle title={"User List"} />
      <section className='add-category dashboard'>
        <div className='common-page-padding'>
          <div className='recent-courses-and-monthly-revenue'>
            <div className='add-category-details'>
              <Titles label={"User List"} isSearch={HandleSearchBar} />
              <div className='recent-courses-table'>
                <table className='recent-courses-table-inner'>
                  <thead>
                    <tr className='recent-table-heading'>
                      <th>SR.NO</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone number</th>
                      <th>View Courses</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  {userData &&
                    userData.map((item: PropertyUser, index: any) => {
                      return (
                        <tbody key={index}>
                          <tr className='recent-table-details'>
                            <td>{index + 1}</td>
                            <td>{item?.full_name}</td>
                            {item?.email_id ? (
                              <td>{item?.email_id}</td>
                            ) : (
                              <td className='padding-4'>{"-"}</td>
                            )}
                            {item?.mobile_no && item?.mobile_no !== " " ? (
                              <td>{item?.mobile_no}</td>
                            ) : (
                              <td className='padding-4'>{"-"}</td>
                            )}
                            <td>
                              <div className='view-courses-button'>
                                <Link to={`/user-active/${item?.user_id}`}>
                                  View Courses
                                </Link>
                              </div>
                            </td>
                            <td>
                              <div className='view-courses-button'>
                                <Button
                                  className='action-button'
                                  onClick={() => {
                                    handleShow(item)
                                  }}
                                >
                                  <FaEdit color='black' />
                                </Button>

                                <Button
                                  className='action-button'
                                  onClick={() => {
                                    handleBlock(
                                      item?.user_id,
                                      item?.user_status,
                                    )
                                  }}
                                >
                                  {item?.user_status ? (
                                    <FaRegCheckSquare color='green' />
                                  ) : (
                                    <FaRegWindowClose color='red' />
                                  )}
                                </Button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )
                    })}
                </table>
                <div>
                  <Pagi
                    optionValue={optionValue}
                    setOptionValue={setOptionValue}
                    onSelectOption={onSelectOption}
                    page={0}
                    limit={limit}
                    setlimit={setLimit}
                    totalCount={totalRecords}
                    totalPages={Math.ceil(totalRecords / 10)}
                    hasNext={true}
                    onChange={onPageChanges}
                    newPageCount={newPageCount}
                    onClickPage={onChangePageNumber}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter your name'
                  autoFocus
                  value={userDetail.name}
                  onChange={(e) => {
                    setuserDetail({ ...userDetail, name: e.target.value })
                  }}
                />
              </Form.Group>

              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label>Email </Form.Label>
                <Form.Control
                  type='email'
                  placeholder='name@example.com'
                  autoFocus
                  value={userDetail.email}
                  onChange={(e) => {
                    setuserDetail({ ...userDetail, email: e.target.value })
                  }}
                />
              </Form.Group>

              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type='number'
                  placeholder='Enter your phone'
                  autoFocus
                  value={userDetail.phone}
                  onChange={(e) => {
                    setuserDetail({ ...userDetail, phone: e.target.value })
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Close
            </Button>
            <Button variant='primary' onClick={editHandler}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </>
  )
}
export default UserListPage
