import Swal from "sweetalert2"
import { onKeyPressType } from "../../../config/commonType"
import React from "react"

export const CommonToken = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWRlZmF1bHQtcm9sZSI6ImFub255bW91cyIsIngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsiYW5vbnltb3VzIl19LCJpYXQiOjE2ODA3ODgwODAsImF1ZCI6IjM2IiwiaXNzIjoibW5iaiJ9.vPo2zjbF70BzvA-BfUxof582V8nPTj18IDl00s4H7LA`

//array split function
export function groupBy(arr: any[], property: string) {
  return (
    arr &&
    arr.reduce(function (res, x) {
      const memo = { ...res }
      if (!memo[x[property]]) {
        memo[x[property]] = []
      }
      memo[x[property]].push(x)
      return memo
    }, {})
  )
}

export function groupBys(arr: any[]) {
  return (
    arr &&
    arr.reduce(function (res: any, x) {
      const memo = { ...res }
      if (!memo[x?.course_type]) {
        memo[x.course_type] = []
      }
      memo[x.course_type].push(x)
      return memo
    }, {})
  )
}

//online course flag
export function matchingCondition({ itemB, itemA }: any) {
  return itemB.course_media
    .map((i: { media_fk: { media_desc: any } }) => i?.media_fk?.media_desc)
    .includes(itemA)
}

const ab = ["promo_video"]
export function matchingArray(items: any) {
  return (
    items &&
    items.filter((itemB: any) =>
      ab.some((itemA) => matchingCondition({ itemB, itemA })),
    )
  )
}

export function flagArray(items: any, matches: any) {
  return (
    items &&
    items.filter(
      ({ course_id: id1 }: any) =>
        !matches.some(({ course_id: id2 }: any) => id2 === id1),
    )
  )
}

// course online
export function uniqueByThumbnail(items: { course_media: any[] }) {
  return (
    items &&
    items.course_media &&
    items.course_media.filter((i: { media_fk: { media_desc: string } }) => {
      if (i?.media_fk?.media_desc === "Thubnails") {
        return i
      }
    })
  )
}

export function uniqueByIdRecipe(items: any[]) {
  const set = new Set()
  return (
    items &&
    items.filter((item) => {
      const isDuplicate = set.has(item.recipe_id)
      set.add(item.recipe_id)
      return !isDuplicate
    })
  )
}

// user list unique id function
export function fetchUserName(items: any[], id: any) {
  return (
    items &&
    items.filter((item) => {
      return item.user_id === Number(id) ? item?.full_name : ""
    })
  )
}

export function fetchCourseEnrollment(items: any[], id: any) {
  // return items && items[0]?.course_id === id && items[0]?.is_deleted === false
  return (
    items &&
    items.some((item) => item.course_id === id && item.is_deleted === false)
  )
}

export function fetchCourseEnrollmentIds(items: any[], id: any) {
  return items && items.find((item) => item.course_id === id)
}

//course edit time function
export function uploadImageThumbnail(items: any[]) {
  return (
    items !== undefined &&
    items.length &&
    items[0] &&
    items[0].course_media &&
    items[0].course_media.filter((i: { media_fk: { media_desc: string } }) => {
      if (i?.media_fk?.media_desc === "Thubnails") {
        return i
      }
    })
  )
}

export function uploadImageCourse(items: any[]) {
  return (
    items !== undefined &&
    items.length &&
    items[0] &&
    items[0].course_media &&
    items[0].course_media.filter((i: { media_fk: { media_desc: string } }) => {
      if (i?.media_fk?.media_desc === "Course Content") {
        return i
      }
    })
  )
}

export function uploadCourseVideo(items: any[]) {
  return (
    items !== undefined &&
    items.length &&
    items[0] &&
    items[0].course_media &&
    items[0].course_media.filter((i: { media_fk: { media_desc: string } }) => {
      if (i?.media_fk?.media_desc === "promo_video") {
        return i
      }
    })
  )
}
export function uploadCoursePdf(items: any[]) {
  return (
    items !== undefined &&
    items.length &&
    items[0] &&
    items[0].course_media &&
    items[0].course_media.filter((i: { media_fk: { media_desc: string } }) => {
      if (i?.media_fk?.media_desc === "pdf_online") {
        return i
      }
    })
  )
}

// recipe youtube link find edit time function
export function recipeYoutubeLink(items: any[]) {
  return (
    items !== undefined &&
    items.length &&
    items[0] &&
    items[0].recipe_media &&
    items[0].recipe_media.filter(
      (i: { media_fk: { media_video_type: string } }) => {
        if (i?.media_fk?.media_video_type === "youtube") {
          return i
        }
      },
    )
  )
}

//recipe video find edit time function
export function uploadVideoId(items: any[]) {
  return (
    items !== undefined &&
    items.length &&
    items[0] &&
    items[0].recipe_media &&
    items[0].recipe_media.filter((i: { media_fk: { media_desc: string } }) => {
      if (i?.media_fk?.media_desc === "recipe video upload") {
        return i
      }
    })
  )
}
export function imageRecipeGet(items: any) {
  return (
    items !== undefined &&
    items &&
    items.filter((i: { media_fk: { media_type: string } }) => {
      if (i?.media_fk?.media_type === "photo") {
        return i
      }
    })
  )
}

// video find catering function
export function uploadImageThumbnailId(items: any[]) {
  return (
    items !== undefined &&
    items.length &&
    items[0] &&
    items[0].catering_media &&
    items[0].catering_media.filter(
      (i: { media_fk: { media_desc: string } }) => {
        if (i?.media_fk?.media_desc === "Thumbnail image") {
          return i
        }
      },
    )
  )
}

export function uploadImageId(items: any[]) {
  return (
    items !== undefined &&
    items.length &&
    items[0] &&
    items[0].catering_media &&
    items[0].catering_media.filter(
      (i: { media_fk: { media_desc: string } }) => {
        if (i?.media_fk?.media_desc === "clientImage") {
          return i
        }
      },
    )
  )
}

export function uploadVideoCatering(items: any[]) {
  return (
    items !== undefined &&
    items.length &&
    items[0] &&
    items[0].catering_media &&
    items[0].catering_media.filter(
      (i: { media_fk: { media_desc: string } }) => {
        if (i?.media_fk?.media_desc === "cateringVideo") {
          return i
        }
      },
    )
  )
}

export function uploadVideoClient(items: any[]) {
  return (
    items !== undefined &&
    items.length &&
    items[0] &&
    items[0].catering_media &&
    items[0].catering_media.filter(
      (i: { media_fk: { media_desc: string } }) => {
        if (i?.media_fk?.media_desc === "catering client video") {
          return i
        }
      },
    )
  )
}

// error message show function
export const errorMessageShow = (error: { message: any }) => {
  return Swal.fire({
    icon: "warning",
    title: error.message,
    showConfirmButton: true,
  })
}

// success message show function
export const successMessageShow = (title: any) => {
  return Swal.fire({
    icon: "success",
    title: title,
    showConfirmButton: true,
  })
}

// reset message show function
export const resetMessageShow = () => {
  return Swal.fire({
    title: "Reset all field successfully!",
    showConfirmButton: true,
  })
}

// error message show function
export const errorMessage = (error: string) => {
  return Swal.fire({
    icon: "warning",
    title: error,
    showConfirmButton: true,
    customClass: "custom-height",
  })
}
const authToken = localStorage.getItem("token")

export const requestOptions = (graphql: string) => {
  return {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: graphql,
    redirect: "follow",
  }
}

export const uploadError = (response: any) => {
  const res: any = response?.response?.data?.error_description
  if (response?.response?.data?.error_description) {
    return errorMessage(res).then((r) => console.log(r))
  }
  if (response?.response?.data?.message) {
    return errorMessage(response?.response?.data?.message).then((r) =>
      console.log(r),
    )
  }
}

//on KeyPress Function
export const onKeyPressFunction = (e: onKeyPressType, MaxLength: any) => {
  if (e.target.value.length > MaxLength) {
    e.preventDefault()
    return false
  }
}

export const override: React.CSSProperties = {
  marginTop: "4px",
  width: "13px",
  height: "12px",
}

// duration find function
export const getVideoDuration = (file: Blob) =>
  new Promise((resolve, reject) => {
    const reader: any = new FileReader()
    reader.onload = () => {
      const media = new Audio(reader.result)
      media.onloadedmetadata = () => resolve(media.duration)
    }
    reader.readAsDataURL(file)
    reader.onerror = (error: any) => reject(error)
  })
