import React, { useEffect, useState } from "react"
import Inputs from "../common/Inputs/InputField"
import ResetDoneButton from "../common/Buttons/resetDoneButton"
import UploadImage from "../common/UploadImage/uploadImage"
import MultipleImage from "../common/UploadImage/multipleImage"
import SelectDropdown from "../common/Inputs/selectField"
import { useMutation, useQuery } from "@apollo/client"
import { FETCH_CATEGORY } from "../addCategoryies/queries"
import {
  ADD_COURSE,
  COURSE_STATUS_UPDATED,
  UPDATE_COURSE,
  uploadMedia,
} from "./queries"
import { useDispatch } from "react-redux"
import { MakeStore } from "../../store/coursestore/courseSlice"
import { FETCH_COURSE } from "../onlineCoursesList/queries"
import {
  groupBy,
  resetMessageShow,
  uploadImageCourse,
  uploadCourseVideo,
  uploadImageThumbnail,
  uploadCoursePdf,
  errorMessage,
  errorMessageShow,
} from "../common/Function/commonFunction"
import "react-toastify/dist/ReactToastify.css"
import PromoVideoSection from "./promoVideoSection"
import { toast } from "react-toastify"
import { updateUploadMedia } from "../addOfflineCourse/queries"
import { useFormik } from "formik"
import { validationOnline } from "../common/Function/validationFunction"
import Titles from "../common/Title/Title"
import { CourseVideo } from "../../config/commonType"
import RichTextEditors from "../common/Inputs/RichTextEditors"
import RichTextEditor, { EditorValue } from "react-rte"
import {
  prepareFormContent,
  prepareFormDataOnline,
  prepareFormDataPdfOnline,
} from "../common/Function/prepareFormData"
import UploadPdf from "../common/uploadPdf/uploadPdf"
import apiConfig from "../../config/apiConfig"

const errorVideos: any = []

const AddOnlineForm = (props: { singleArray?: any }) => {
  const { singleArray } = props
  let newArray: any
  newArray = singleArray !== undefined && singleArray ? singleArray[0] : ""
  const dispatch = useDispatch()

  const { data } = useQuery(FETCH_CATEGORY)
  const [addCourses] = useMutation(ADD_COURSE)
  const [updateCourses] = useMutation(UPDATE_COURSE)
  const [updatedCourseStatus] = useMutation(COURSE_STATUS_UPDATED)

  const categoryArray = groupBy(data?.category_master, "category_type")
  const imageThumbnail = uploadImageThumbnail(singleArray)
  const imageCourseContent = uploadImageCourse(singleArray)
  const courseVideo = uploadCourseVideo(singleArray)
  const pdfCourse = uploadCoursePdf(singleArray)

  const thumbnail =
    imageThumbnail && imageThumbnail[0] && imageThumbnail[0].media_fk

  const [course_id, setCourse_id] = useState<string>("")
  const [contentImg, setContentImg] = useState<any>()
  const [uploadVideo, setUploadVideo] = useState<any>()
  const [showNextSection, setShowNextSection] = useState(false)
  const [editShow, setEditShow] = useState(false)
  const [media_id, setMedia_id] = useState("")
  const [media_id1, setMedia_id1] = useState("")
  const [media_id2, setMedia_id2] = useState("")
  const [media_idPdf, setMedia_idPdf] = useState("")
  const [isProcess, setIsProcess] = useState<boolean>(false)
  const [inProgress, setinProgress] = useState<boolean>(false)
  const [isError, setIsisError] = useState<boolean>(false)
  // const [media_name, setMedia_name] = useState("")
  // const [media_name_array, setmedia_name_array] = useState<any>([])

  const [durationValue, setDurationValue] = useState<number>(0)

  const initialValues = {
    course_id: newArray ? newArray?.course_id : "",
    category_id: newArray ? newArray?.category_id : "",
    course_name: newArray ? newArray?.course_name : "",
    course_desc: newArray ? newArray?.course_desc : "",
    course_content: newArray ? newArray?.course_content : "",
    course_status: newArray ? newArray?.course_status : true,
    course_usage_steps: newArray ? newArray?.course_usage_steps : "",
    course_details: newArray ? newArray?.course_details : "",
    course_type: newArray ? newArray?.course_type : "",
    course_amount: newArray ? newArray?.course_amount : "",
    course_discount_amount: newArray ? newArray?.course_discount_amount : "",
    cook_time: newArray ? newArray?.cook_time : "",
    preparation_time: newArray ? newArray?.preparation_time : "",
    total_time: newArray ? newArray?.total_time : "",
    cuisine: newArray ? newArray?.cuisine : "",
    serving: newArray ? newArray?.serving : "",
    selectedImage: thumbnail ? thumbnail.media_desc : "",
    selectPdf:
      pdfCourse && pdfCourse[0] ? pdfCourse[0].media_fk?.media_desc : "",
  }

  useEffect(() => {
    if (newArray) {
      editAllField()
    }
  }, [newArray])

  const a: any = []

  function msToTime(duration: any) {
    var hours = Math.floor((duration / (1000 * 60 * 60)) % 24)
    //@ts-ignore
    hours = hours < 10 ? "0" + hours : hours

    return hours
  }

  useEffect(() => {
    // setmedia_name_array([])
  }, [])

  useEffect(() => {
    //  const errArray = newArray.course_media.filter((item:CourseVideo)=>{
    //     return item?.media_fk?.upload_status === "error"
    //   })

    //   errArray.map((item:CourseVideo)=>{
    //     errorVideos.push(
    //       item?.media_fk?.media_name
    //     )

    //   })
    newArray.course_media?.map((item: CourseVideo) => {
      // setMedia_name(item?.media_fk?.media_name)
      if (item?.media_fk?.upload_status === "error") {
        errorVideos.push(item?.media_fk?.media_name)
        // setmedia_name_array(errorVideos)
        setIsisError(false)
      }
      // setmedia_name_array(errorVideos)

      if (item?.media_fk?.upload_status === "inprogress") {
        setinProgress(true)
        if (item?.media_fk?.updated_dt) {
          const date1 = new Date(item?.media_fk?.updated_dt)
          const date2 = new Date()
          const diffTime = Math.abs(date2.valueOf() - date1.valueOf())

          const uploadedTime = msToTime(diffTime)

          if (uploadedTime > 5) {
            errorVideos.push(item?.media_fk?.media_name)
            setIsisError(true)
          }
        }
      }
    })

    // function removeDuplicates(errorVideos: any) {
    //   return errorVideos.filter(
    //     (item: CourseVideo, index: number) =>
    //       errorVideos.indexOf(item) === index,
    //   )
    // }
    // const new_array = removeDuplicates(errorVideos)

    // setmedia_name_array(new_array)
  }, [newArray.course_media])

  const courseMultipleArray =
    newArray.course_media &&
    newArray.course_media?.map((item: CourseVideo) => {
      if (item?.media_fk?.media_desc === "course_videos") {
        a.push({
          media_duration: item.media_fk?.media_duration,
          course_name: item.media_fk?.media_name,
          selectedImage: item.media_fk,
          media_id: item.media_id,
        })
        return a
      }
    })

  // empty field function
  const EmptyAllField = () => {
    setContentImg(undefined)
    setUploadVideo(undefined)
  }

  const editAllField = () => {
    setCourse_id(newArray.course_id)
    setMedia_id(imageThumbnail[0] && imageThumbnail[0].media_id)
    setMedia_id1(imageCourseContent[0] && imageCourseContent[0].media_id)
    setMedia_id2(courseVideo[0] && courseVideo[0].media_id)
    setMedia_idPdf(pdfCourse[0] && pdfCourse[0].media_id)
    setContentImg(imageCourseContent)
  }

  const addOnlineCourse = (values: any) => {
    setIsProcess(true)
    addCourses({
      variables: values,
      refetchQueries: [{ query: FETCH_COURSE }],
      onError: (error) => {
        if (error.message) {
          toast.error(`${error.message}`)
        }
      },
      onCompleted: (data) => {
        if (data.insert_courses.__typename) {
          setIsProcess(false)
          const course_id = data.insert_courses.returning[0].course_id
          setCourse_id(course_id)
          if (
            course_id !== "" &&
            formik.values.selectedImage !== undefined &&
            formik.values.selectedImage !== "Thubnails"
          ) {
            uploadMedia(prepareFormDataOnline(formik, course_id, media_id))
          }
          if (
            course_id !== "" &&
            formik.values.selectPdf !== undefined &&
            formik.values.selectPdf !== "pdf_online"
          ) {
            uploadMedia(
              prepareFormDataPdfOnline(formik, course_id, media_idPdf),
            )
          }
          setTimeout(() => {
            if (course_id !== "" && contentImg !== undefined) {
              contentImg &&
                contentImg.file.map((url: any) => {
                  return uploadMedia(
                    prepareFormContent(url, course_id, media_id1),
                  )
                })
            }
          }, 1000)
          toast.success("Online course add successfully!")
          setShowNextSection(true)
          EmptyAllField()
        }
      },
    }).then((r) => console.log(r))
  }

  const editOnlineCourse = (values: any) => {
    setIsProcess(true)
    updateCourses({
      variables: values,
      refetchQueries: [{ query: FETCH_COURSE }],
      onError: (error) => {
        if (error.message) {
          toast.error(`${error.message}`)
        }
      },
      onCompleted: (data) => {
        if (data.update_courses.__typename) {
          const course_id = data.update_courses.returning[0].course_id
          setIsProcess(false)
          if (course_id) {
            toast.success("Online course edit successfully!")
            if (
              media_id !== "" &&
              media_id !== undefined &&
              formik.values.selectedImage !== "Thubnails" &&
              formik.values.selectedImage !== undefined
            ) {
              updateUploadMedia(
                media_id,
                prepareFormDataOnline(formik, course_id, media_id),
              )
            } else {
              if (
                formik.values.selectedImage !== undefined &&
                formik.values.selectedImage !== "Thubnails"
              ) {
                uploadMedia(prepareFormDataOnline(formik, course_id, media_id))
              }
            }
            if (
              media_idPdf !== "" &&
              media_idPdf !== undefined &&
              formik.values.selectPdf !== "pdf_online" &&
              formik.values.selectPdf !== undefined
            ) {
              updateUploadMedia(
                media_idPdf,
                prepareFormDataPdfOnline(formik, course_id, media_idPdf),
              )
            } else {
              if (
                formik.values.selectPdf !== undefined &&
                formik.values.selectPdf !== "pdf_online"
              ) {
                uploadMedia(
                  prepareFormDataPdfOnline(formik, course_id, media_idPdf),
                )
              }
            }
            if (contentImg !== undefined) {
              contentImg?.file &&
                contentImg?.file.map((url: any, ids: any) => {
                  if (imageCourseContent) {
                    imageCourseContent &&
                      imageCourseContent.map((id: any, index: React.Key) => {
                        if (ids === index) {
                          return updateUploadMedia(
                            id?.media_id,
                            prepareFormContent(
                              url,
                              id?.course_id,
                              id?.media_id,
                            ),
                          )
                        }
                      })
                  } else {
                    return uploadMedia(
                      prepareFormContent(url, course_id, url.media_id),
                    )
                  }
                })
            }
            setEditShow(true)
            setShowNextSection(true)
            EmptyAllField()
          }
        }
      },
    }).then((r) => console.log(r))
  }

  //onClick save button
  const submitSaveButton = (values: any) => {
    if (newArray !== "") {
      editOnlineCourse(values)
    } else {
      addOnlineCourse(values)
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationOnline,
    onSubmit: (values) => {
      submitSaveButton(values)
    },
  })

  useEffect(() => {
    const data = {
      course_name: formik.values.course_name,
      course_desc: formik.values.course_desc,
      course_details: formik.values.course_details,
      selectedImage: formik.values.selectedImage,
      selectPdf: formik.values.selectPdf,
      editPdfCourse: pdfCourse,
      editImage: thumbnail?.media_url,
      contentImg: contentImg,
      editContentImg: imageCourseContent,
      PromoUploadVideo:
        uploadVideo === undefined ? courseVideo[0] : uploadVideo,
      showNextSection: showNextSection,
      durationValue:
        uploadVideo === undefined
          ? courseVideo[0] && courseVideo[0]?.media_fk?.media_duration
          : durationValue,
    }
    dispatch(MakeStore(data))
  }, [initialValues])

  const [mdeState, setMdeState] = useState(
    formik.values.course_desc
      ? RichTextEditor.createValueFromString(formik.values.course_desc, "html")
      : RichTextEditor.createEmptyValue(),
  )

  const handleMdeChange = (value: React.SetStateAction<EditorValue>) => {
    formik.setFieldValue("course_desc", value.toString("html"))
    setMdeState(value)
  }

  const [mdeStateContent, setMdeStateContent] = useState(
    formik.values.course_content
      ? RichTextEditor.createValueFromString(
          formik.values.course_content,
          "html",
        )
      : RichTextEditor.createEmptyValue(),
  )

  const handleMdeChangeContent = (value: React.SetStateAction<EditorValue>) => {
    formik.setFieldValue("course_content", value.toString("html"))
    setMdeStateContent(value)
  }

  const [mdeStateUsage_steps, setMdeStateUsage_steps] = useState(
    formik.values.course_usage_steps
      ? RichTextEditor.createValueFromString(
          formik.values.course_usage_steps,
          "html",
        )
      : RichTextEditor.createEmptyValue(),
  )

  const handleMdeChangeUsage_steps = (
    value: React.SetStateAction<EditorValue>,
  ) => {
    formik.setFieldValue("course_usage_steps", value.toString("html"))
    setMdeStateUsage_steps(value)
  }
  let fileObj: never[] = []
  let fileArray: never[] = []

  const updatedStatus = () => {
    // if (inProgress && !isError) {
    //   return errorMessage(`wait while video is uploading for ${media_name}`)
    // }

    // if (isError) {

    //   console.log(isError,"iserr")
    //   return errorMessage(
    //     `${"Please Reupload the video"
    //       .split(",")
    //       .join("\n")} ${"Error is occured while uploading video for"
    //       .split(",")
    //       .join("\n")}, ${media_name_array.toString().split(",").join("\n")}`,
    //   )
    // }

    const fiveHourvideos: any = []
    const errorVideos: any = []
    const inProgressVideos: any = []

    apiConfig.get(`/encripted-video/${course_id}`).then((res) => {
      if (res.data.FiveHrVideo.length > 0) {
        setIsisError(true)
        res.data.FiveHrVideo.map((item: any) => {
          fiveHourvideos.push(item.media_name)
          // return errorMessage(item.media_name)
        })
        return errorMessage(
          `${"Please Reupload the video"
            .split(",")
            .join("\n")} ${"Error is occured while uploading video for"
            .split(",")
            .join("\n")}, ${fiveHourvideos.toString().split(",").join("\n")}`,
        )
      } else {
        setIsisError(false)
      }

      if (res.data.errorVideos.length > 0) {
        setIsisError(true)

        res.data.errorVideos.map((item: any) => {
          errorVideos.push(item.media_name)
          // return errorMessage(item.media_name)
        })
        return errorMessage(
          `${"Please Reupload the video"
            .split(",")
            .join("\n")} ${"Error is occured while uploading video for"
            .split(",")
            .join("\n")}${errorVideos.toString().split(",").join("\n")}`,
        )
      } else {
        setIsisError(false)
      }

      if (res.data.inprogress.length > 0) {
        setinProgress(true)
        res.data.inprogress.map((item: any) => {
          inProgressVideos.push(item.media_name)
          // return errorMessage(item.media_name)
        })
        return errorMessage(
          `wait while video is uploading for ${inProgressVideos}`,
        )
      } else {
        setinProgress(false)
      }
    })
    if (course_id !== "" && !isError && !inProgress) {
      updatedCourseStatus({
        variables: {
          course_id: course_id,
          course_status: !formik.values.course_status,
        },
        onCompleted: async (data) => {
          if (data.update_courses.returning[0].course_status === false) {
            toast.success("Course status deactivate successfully!")
          } else {
            toast.success("Course status active successfully!")
          }
        },
        onError: (error) => {
          if (error.message.includes("Foreign key violation.")) {
            return errorMessage("Something went wrong!")
          } else {
            return errorMessageShow(error)
          }
        },
      })
    } else {
      // if (!formik.values.course_status) {
      //   toast.success("Course status active successfully!")
      // } else {
      //   toast.success("Course status deactivate successfully!")
      // }
    }
  }
  return (
    <>
      {showNextSection === false ? (
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-5'>
          <form onSubmit={formik.handleSubmit}>
            <div className='add-category-details'>
              <Titles
                label={
                  newArray !== "" ? "EDIT ONLINE CLASS" : "ADD ONLINE CLASS"
                }
                formik={formik}
                updatedStatus={updatedStatus}
                course_id={course_id}
                isActiveCourse={true}
                isError={isError}
                isProgress={inProgress}
              />
              <div className='only-padding'>
                <Inputs
                  MaxLength={100}
                  type={"text"}
                  placeHolder={"Enter Course name"}
                  name='course_name'
                  onChange={formik.handleChange}
                  value={formik.values.course_name}
                  label={"Course Name"}
                  touched={formik.touched.course_name}
                  errors={formik.errors.course_name}
                />

                {/*<!------- select dropdown menu section start-----!>*/}
                <SelectDropdown
                  name={"category_id"}
                  touched={formik.touched.category_id}
                  errors={formik.errors.category_id}
                  title={"Category"}
                  value={formik.values.category_id}
                  data={categoryArray?.course}
                  onChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />

                <Inputs
                  MaxLength={200}
                  touched={formik.touched.course_details}
                  label={"Details"}
                  type={"text"}
                  placeHolder={"Enter Details"}
                  name='course_details'
                  onChange={formik.handleChange}
                  value={formik.values.course_details}
                  errors={formik.errors.course_details}
                />

                <div className='two-side-input-filed'>
                  <div className='row'>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                      <Inputs
                        type={"number"}
                        placeHolder={"Enter Price"}
                        name='course_amount'
                        onChange={formik.handleChange}
                        label={"Price"}
                        value={formik.values.course_amount}
                        errors={formik.errors.course_amount}
                        touched={formik.touched.course_amount}
                      />
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                      <Inputs
                        type={"number"}
                        placeHolder={"Enter Price"}
                        name='course_discount_amount'
                        onChange={formik.handleChange}
                        label={"Discounted Price"}
                        value={formik.values.course_discount_amount}
                        errors={formik.errors.course_discount_amount}
                        touched={formik.touched.course_discount_amount}
                      />
                    </div>
                  </div>
                </div>

                {/*<!------- upload image section start-----!>*/}
                <UploadImage
                  selectedImage={formik.values.selectedImage}
                  title={"Upload Image (Thubnails)"}
                  setSelectedImage={(selectedImage: any) => {
                    formik.setFieldValue("selectedImage", selectedImage)
                  }}
                  errors={formik.errors.selectedImage}
                  touched={formik.touched.selectedImage}
                  isEdit={formik.values.selectedImage !== "" ? true : false}
                />

                <UploadPdf
                  selectedImage={formik.values.selectPdf}
                  setSelectedImage={(selectPdf: any) => {
                    formik.setFieldValue("selectPdf", selectPdf)
                  }}
                  errors={formik.errors.selectPdf}
                  touched={formik.touched.selectPdf}
                  isEdit={formik.values.selectPdf !== "" ? true : false}
                />

                <div className='two-side-input-filed'>
                  <div className='row'>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                      <Inputs
                        type={"number"}
                        placeHolder={"00"}
                        name='preparation_time'
                        onChange={formik.handleChange}
                        label={"Prep Time"}
                        value={formik.values.preparation_time}
                        errors={formik.errors.preparation_time}
                        touched={formik.touched.preparation_time}
                      />
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                      <Inputs
                        type={"number"}
                        placeHolder={"00"}
                        name='cook_time'
                        onChange={formik.handleChange}
                        label={"Cook Time"}
                        value={formik.values.cook_time}
                        errors={formik.errors.cook_time}
                        touched={formik.touched.cook_time}
                      />
                    </div>
                  </div>
                </div>

                <div className='two-side-input-filed'>
                  <div className='row'>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                      <Inputs
                        type={"number"}
                        placeHolder={"00"}
                        name='total_time'
                        onChange={formik.handleChange}
                        label={"Total Time"}
                        value={formik.values.total_time}
                        errors={formik.errors.total_time}
                        touched={formik.touched.total_time}
                      />
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                      <Inputs
                        type={"text"}
                        placeHolder={"Enter Course"}
                        name='course_type'
                        onChange={formik.handleChange}
                        label={"Course"}
                        value={formik.values.course_type}
                        errors={formik.errors.course_type}
                        touched={formik.touched.course_type}
                      />
                    </div>
                  </div>
                </div>
                <div className='two-side-input-filed'>
                  <div className='row'>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                      <Inputs
                        type={"text"}
                        placeHolder={"Enter Cuisine type"}
                        name='cuisine'
                        onChange={formik.handleChange}
                        label={"Cuisine"}
                        value={formik.values.cuisine}
                        errors={formik.errors.cuisine}
                        touched={formik.touched.cuisine}
                      />
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                      <Inputs
                        type={"text"}
                        placeHolder={"Enter Servings Details"}
                        name='serving'
                        onChange={formik.handleChange}
                        label={"Servings"}
                        value={formik.values.serving}
                        errors={formik.errors.serving}
                        touched={formik.touched.serving}
                      />
                    </div>
                  </div>
                </div>

                <RichTextEditors
                  mdeState={mdeState}
                  onChange={handleMdeChange}
                  errors={formik.errors.course_desc}
                  touched={formik.touched.course_desc}
                  placeholder={"Enter Here"}
                  label={"Description"}
                />

                <RichTextEditors
                  mdeState={mdeStateContent}
                  onChange={handleMdeChangeContent}
                  touched={formik.touched.course_content}
                  errors={formik.errors.course_content}
                  placeholder={"Enter Here"}
                  label={"Course Content"}
                />

                <RichTextEditors
                  mdeState={mdeStateUsage_steps}
                  label={"How to Use"}
                  placeholder={"Enter Here"}
                  touched={formik.touched.course_usage_steps}
                  errors={formik.errors.course_usage_steps}
                  onChange={handleMdeChangeUsage_steps}
                />

                {/*<!------- upload image section start-----!>*/}
                <MultipleImage
                  isEdit={imageCourseContent !== "" ? true : false}
                  multiple={true}
                  fileArray={fileArray}
                  fileObj={fileObj}
                  title={"Upload images for (Course Content)"}
                  setSelectedImage={setContentImg}
                  selectedImage={contentImg}
                />

                {/*<!------- button section start-----!>*/}
                <div className='button-div'>
                  <ResetDoneButton
                    disableFirst={!formik.dirty}
                    isProcess={isProcess}
                    firstButton={"Reset"}
                    secondButton={"Next"}
                    onClickFirst={() => {
                      formik.resetForm()
                      setMdeState(
                        RichTextEditor.createValueFromString("", "html"),
                      )
                      setMdeStateContent(
                        RichTextEditor.createValueFromString("", "html"),
                      )
                      setMdeStateUsage_steps(
                        RichTextEditor.createValueFromString("", "html"),
                      )
                      return resetMessageShow()
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <PromoVideoSection
          setDurationValue={setDurationValue}
          durationValue={durationValue}
          setUploadVideo={setUploadVideo}
          editShow={editShow}
          uploadVideo={uploadVideo}
          newArray={newArray}
          course_id={course_id}
          media_id2={media_id2}
          courseMultipleArray={courseMultipleArray}
        />
      )}
    </>
  )
}

export default AddOnlineForm
