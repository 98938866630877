import React from "react"
import OfflineCourse from "../../components/addOfflineCourse/offlineCourse"

const OfflineCourses = () => {
  return (
    <>
      <OfflineCourse />
    </>
  )
}

export default OfflineCourses
