import React from "react"
import EditDeleteButton from "../Buttons/editDeleteButton"
import { SingleCardProps } from "../../../config/commonType"

const SingleOurProduct = (props: SingleCardProps) => {
  const { item, onClick, product_id, editClick, isIds, isProcess } = props

  return (
    <>
      <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4'>
        <div className='couses-list-details'>
          <div className='couses-images'>
            {item?.product_media[0]?.product_media?.media_url ? (
              <img
                src={
                  item?.product_media[0] &&
                  item?.product_media[0]?.product_media?.media_url
                }
                alt=''
              />
            ) : (
              <img src='/images/Chyavanaprash.png' alt='' />
            )}
          </div>
          <div className='recommended-block'>
            <div className='recommended-block-title'>
              <h3>{item?.product_name}</h3>
            </div>
            <div className='recommended-block-list'>
              <ul className={"scroll-list"}>
                <li>{item?.product_desc}</li>
              </ul>
            </div>
            <div className='course-price-details'>
              <div className='real-and-discounted-price'>
                <div className='real-price'>
                  <p>
                    {" "}
                    {item?.discount_price ? " ₹ " + item?.discount_price : ""}
                  </p>
                </div>
                <div className='discounted-price'>
                  <p>
                    {" "}
                    {item?.product_price ? " ₹ " + item?.product_price : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <EditDeleteButton
            matchId={isIds}
            isProcess={isProcess}
            onClickDelete={onClick}
            course_id={product_id}
            editClick={editClick}
          />
        </div>
      </div>
    </>
  )
}

export default SingleOurProduct
