import { configureStore } from "@reduxjs/toolkit"
import UserDetailSlice from "./slices/user-slice"
import uiSlice from "./ui/uiSlices"
import courseSlice from "./coursestore/courseSlice"
import singleCourseSlice from "./singleCourse/courseSlice"

const store = configureStore({
  reducer: {
    userDetails: UserDetailSlice,
    uiReducers: uiSlice,
    courseReducers: courseSlice,
    singleCourse: singleCourseSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export default store
