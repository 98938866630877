import gql from "graphql-tag"
import apiConfigWithFormData from "../../config/apiConfigWithFormData"
import { uploadError } from "../common/Function/commonFunction"

export const ADD_OFFLINE_COURSE = gql`
  mutation (
    $category_id: Int
    $course_amount: numeric
    $course_content: String
    $course_usage_steps: String
    $course_type: String
    $course_desc: String
    $course_details: String
    $cuisine: String
    $serving: String
    $total_time: numeric
    $preparation_time: numeric
    $course_discount_amount: numeric
    $course_name: String
    $cook_time: numeric
    $course_status: Boolean
  ) {
    insert_courses(
      objects: {
        category_id: $category_id
        course_amount: $course_amount
        course_details: $course_details
        course_discount_amount: $course_discount_amount
        course_name: $course_name
        course_status: $course_status
        course_currency: "INR"
        course_type: "offline"
      }
    ) {
      affected_rows
      returning {
        category_id
        cook_time
        course_amount
        course_desc
        course_details
        course_discount_amount
        course_id
        course_name
        course_status
        course_type
        created_dt
        cuisine
        preparation_time
        serving
        time_unit
        total_time
        updated_dt
        course_content
        course_currency
        course_usage_steps
      }
    }
  }
`

export const UPDATE_OFFLINE_COURSE = gql`
  mutation (
    $course_id: bigint
    $category_id: Int
    $course_amount: numeric
    $course_type: String
    $course_desc: String
    $course_details: String
    $course_discount_amount: numeric
    $course_name: String
    $course_status: Boolean
  ) {
    update_courses(
      where: { course_id: { _eq: $course_id } }
      _set: {
        category_id: $category_id
        course_amount: $course_amount
        course_details: $course_details
        course_discount_amount: $course_discount_amount
        course_name: $course_name
        course_status: $course_status
        course_type: "offline"
      }
    ) {
      returning {
        category_id
        cook_time
        course_amount
        course_content
        course_currency
        course_desc
        course_details
        course_discount_amount
        course_id
        course_name
        course_status
        course_type
        course_usage_steps
        created_dt
        cuisine
        preparation_time
        serving
        time_unit
        total_time
        updated_dt
      }
    }
  }
`

export const SINGLE_RECORD_FETCH = `query GetCourses($course_id: bigint) {\n  courses(where: {course_id: {_eq: $course_id}}) {\n    course_id\n    course_status\n    course_currency\n    course_name\n    course_type\n    cuisine\n    serving\n    time_unit\n    category_id\n    cook_time\n    course_amount\n    course_discount_amount\n    preparation_time\n    total_time\n    course_content\n    course_desc\n    course_details\n    course_usage_steps\n    created_dt\n    updated_dt\n    course_media {\n      course_id\n      media_fk {\n        media_url\n        media_type\n        media_status\n        media_id\n        media_desc\n        media_name\n        media_photo_type\n        media_video_type\n      }\n      media_id\n      course_media_id\n    }\n  }\n}`

export const COURSE_STATUS_UPDATED = gql`
  mutation UpdatedStatusCourses($course_id: bigint, $course_status: Boolean) {
    update_courses(
      where: { course_id: { _eq: $course_id } }
      _set: { course_status: $course_status }
    ) {
      affected_rows
      returning {
        course_status
      }
    }
  }
`

//upload image api call
export function uploadMedia(data: any, navigate?: any) {
  apiConfigWithFormData
    .post("upload-media", data)
    .then(() => {
      navigate(`/offline-course-list`)
    })
    .catch((response: any) => {
      uploadError(response)
    })
}

//update upload image api call
export function updateUploadMedia(media_id: any, data: any) {
  if (media_id !== undefined) {
    apiConfigWithFormData
      .put(`/update-media/${media_id}`, data)
      .then(() => {})
      .catch((response: any) => {
        uploadError(response)
      })
  }
}
