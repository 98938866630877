import LoadMoreButton from "../common/Buttons/loadMoreButton"
import { useNavigate } from "react-router-dom"
import { useMutation } from "@apollo/client"
import React, { useEffect, useState } from "react"
import {
  errorMessage,
  errorMessageShow,
  groupBy,
  requestOptions,
  successMessageShow,
} from "../common/Function/commonFunction"
import { DELETE_OUR_PRODUCT, GET_PRODUCT } from "./queries"
import SingleOurProduct from "../common/Cards/singleOurProduct"
import { urls } from "../../constants"
import TabTitle from "../../pages/tab-title/TabTitle"

const OurProductslist = () => {
  let navigate = useNavigate()
  const [deleteOurProduct] = useMutation(DELETE_OUR_PRODUCT)
  const [index, setIndex] = useState(6)
  const [users, setUsers] = useState([])
  const onlineArray = groupBy(users, "product_type")
  const [isProcess, setIsProcess] = useState<any>(false)
  const [isIds, setIsIds] = useState<any>()

  const initialData =
    onlineArray?.ourproduct && onlineArray?.ourproduct.slice(0, index)

  useEffect(() => {
    fetchData().then((r) => console.log(r))
  }, [])

  const fetchData = async () => {
    const graphql = JSON.stringify({ query: GET_PRODUCT })

    const Url = `${urls.api_url}`
    // @ts-ignore
    const res = await fetch(Url, requestOptions(graphql))
    const usersData = await res.json()
    setUsers(usersData?.data?.products)
  }
  if (initialData === undefined) {
    return <div className='loading_message mt-200'>Loading...</div>
  }

  //load more function
  const loadMore = () => {
    setIndex(index + 6)
  }

  // onClick delete function
  const deleteOurProducts = (product_id: any) => {
    setIsIds(product_id)
    setIsProcess(true)
    const product_ids = product_id
    deleteOurProduct({
      variables: { product_id, product_ids },
      onCompleted: (data) => {
        if (data.delete_products.__typename) {
          fetchData().then((r) => console.log(r))
          return successMessageShow("Our product delete successfully!")
        }
      },
      onError: (error) => {
        if (error.message.includes("Foreign key violation.")) {
          return errorMessage("Something went wrong!")
        } else {
          return errorMessageShow(error)
        }
      },
    }).then((r) => console.log(r))
  }

  //onClick edit
  const editOurProducts = (product_id: any) => {
    navigate(`/edit-our-product/${product_id}`)
  }

  return (
    <>
      <TabTitle title={"Our Products"} />
      <div className='common-page-scroll'>
        <section className='amazon-products-list'>
          <div className='common-page-padding'>
            <div className='couses-list-inner'>
              <div className='row'>
                {initialData &&
                  initialData.map(
                    (item: any, index: React.Key | null | undefined) => {
                      return (
                        <SingleOurProduct
                          isIds={isIds}
                          isProcess={isProcess}
                          onClick={deleteOurProducts}
                          editClick={editOurProducts}
                          product_id={item.product_id}
                          key={index}
                          item={item}
                        />
                      )
                    },
                  )}
              </div>
            </div>

            {/*<!------- Load more button section start-----!>*/}
            {onlineArray?.ourproduct &&
              onlineArray?.ourproduct.length !== initialData.length &&
              onlineArray?.ourproduct.length > 6 && (
                <LoadMoreButton onClick={loadMore} />
              )}
          </div>
        </section>
      </div>
    </>
  )
}

export default OurProductslist
