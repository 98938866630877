import React from "react"
import { TitleProps } from "../../../config/commonType"
import Form from "react-bootstrap/Form"

const Titles = (props: TitleProps) => {
  const {
    label,
    closeIcon,
    onClick,
    isSearch,
    formik,
    isActiveCourse,
    updatedStatus,
    isError,
    isProgress,
  } = props

  return (
    <>
      <div className='add-category-and-close-button'>
        <div className='add-category-title'>
          <h1>{label}</h1>
        </div>
        {closeIcon && (
          <div className='close-button'>
            <button type='button' onClick={onClick}>
              <svg
                width='10'
                height='10'
                viewBox='0 0 10 10'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M5.70831 5L9.29718 0.72207C9.35734 0.650977 9.30675 0.542969 9.21378 0.542969H8.12277C8.05851 0.542969 7.99698 0.57168 7.9546 0.620898L4.99464 4.14961L2.03468 0.620898C1.99366 0.57168 1.93214 0.542969 1.86652 0.542969H0.7755C0.682531 0.542969 0.631945 0.650977 0.692102 0.72207L4.28097 5L0.692102 9.27793C0.678626 9.29378 0.669981 9.31316 0.667193 9.33377C0.664404 9.35438 0.667589 9.37537 0.67637 9.39422C0.685151 9.41308 0.699159 9.42902 0.71673 9.44016C0.734302 9.45129 0.754699 9.45715 0.7755 9.45703H1.86652C1.93077 9.45703 1.9923 9.42832 2.03468 9.3791L4.99464 5.85039L7.9546 9.3791C7.99562 9.42832 8.05714 9.45703 8.12277 9.45703H9.21378C9.30675 9.45703 9.35734 9.34902 9.29718 9.27793L5.70831 5Z'
                  fill='#8C8C8C'
                />
              </svg>
            </button>
          </div>
        )}
        {isSearch && (
          <div>
            <input
              placeholder='Search Name ...'
              className='myClassSearch'
              required
              onChange={(e) => {
                isSearch(e.target.value)
              }}
            />
          </div>
        )}
        {isActiveCourse && (
          <div className={"d-flex"}>
            <div>Active Course : </div>
            <div className={"active-inactive-radio-button ms-1"}>
              <Form.Check
                name='course_status'
                onChange={(e) => {
                  formik.handleChange(e)
                  updatedStatus()
                }}
                checked={
                  !isError && !isProgress ? formik.values.course_status : false
                }
                type='switch'
                id='course_status'
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Titles
