//prepare media form data

//register
import { RegisterType } from "../../../config/commonType"
import * as EmailValidator from "email-validator"

export const prepareFormDataRegister = (values: RegisterType) => {
  const formData = new FormData()
  formData.append("name", values.name)
  formData.append("password", values.password)
  formData.append("channel", "web")
  if (!EmailValidator.validate(values.email)) {
    formData.append("signup_method", "mobile_number")
  } else {
    formData.append("signup_method", "email")
  }
  formData.append("email", values.email)
  formData.append("role_id", "1")
  formData.append("code", "+91")
  formData.append("mobile_number", values.mobile_number)
  return formData
}

//offline form
export const prepareFormData = (
  formik: { values: { selectedImage: any } },
  course_id: any,
  media_id: any,
) => {
  const formData = new FormData()
  formData.append("file", formik.values.selectedImage)
  formData.append("media_name", formik.values.selectedImage?.name)
  formData.append("media_type", "photo")
  if (media_id !== "" && media_id !== undefined) {
    formData.append("media_id", media_id?.toString())
  } else {
    formData.append("id", course_id?.toString())
  }
  formData.append("type", "10")
  formData.append("media_video_type", "normal")
  formData.append("media_desc", "offline_image")
  formData.append("is_thumbnail", "true")
  return formData
}

// online form
export const prepareFormDataOnline = (
  formik: { values: { selectedImage: any } },
  course_id: any,
  media_id: string | undefined,
) => {
  const formData = new FormData()
  formData.append("file", formik.values.selectedImage)
  formData.append("media_name", formik.values.selectedImage?.name)
  if (media_id !== "" && media_id !== undefined) {
    formData.append("media_id", media_id?.toString())
  } else {
    formData.append("id", course_id?.toString())
  }
  formData.append("media_type", "photo")
  formData.append("type", "10")
  formData.append("media_video_type", "normal")
  formData.append("media_desc", "Thubnails")
  formData.append("is_thumbnail", "true")
  return formData
}

export const prepareFormDataPdfOnline = (
  formik: { values: { selectPdf: any } },
  course_id: any,
  media_idPdf: string | undefined,
) => {
  const formData = new FormData()
  formData.append("file", formik.values.selectPdf)
  formData.append("media_name", formik.values.selectPdf?.name)
  if (media_idPdf !== "" && media_idPdf !== undefined) {
    formData.append("media_id", media_idPdf?.toString())
  } else {
    formData.append("id", course_id?.toString())
  }
  formData.append("media_type", "pdf")
  formData.append("type", "10")
  formData.append("media_video_type", "normal")
  formData.append("media_desc", "pdf_online")
  formData.append("is_thumbnail", "false")
  return formData
}

export const prepareFormContent = (
  url: any,
  course_id: any,
  media_id1: string | undefined,
) => {
  const formData = new FormData()
  formData.append("file", url)
  formData.append("media_name", url?.name)
  formData.append("media_type", "photo")
  if (media_id1 !== "" && media_id1 !== undefined) {
    formData.append("media_id", media_id1?.toString())
  } else {
    formData.append("id", course_id?.toString())
  }
  formData.append("media_video_type", "normal")
  formData.append("type", "10")
  formData.append("media_desc", "Course Content")
  formData.append("is_thumbnail", "false")
  return formData
}

export const prepareFormPromoVideo = (
  uploadVideo: any,
  course_id: any,
  media_id2: string | undefined,
  durationValue?: any,
) => {
  console.log("abc durationValue", durationValue)
  const formData = new FormData()
  formData.append("file", uploadVideo)
  formData.append("media_name", uploadVideo?.name)
  formData.append("media_type", "video")
  if (media_id2 !== "" && media_id2 !== undefined) {
    formData.append("media_id", media_id2?.toString())
  } else {
    formData.append("id", course_id.toString())
  }
  formData.append("type", "10")
  // formData.append("media_duration", Math.abs(durationValue))
  formData.append("media_video_type", "normal")
  formData.append("media_desc", "promo_video")
  formData.append("is_thumbnail", "false")
  return formData
}

//recipe form
export const prepareFormDataRecipe = (
  storeReducer: { selectedImage: any },
  recipe_id: any,
  media_id: string | undefined,
) => {
  const formData = new FormData()
  formData.append("file", storeReducer?.selectedImage)
  formData.append("media_name", storeReducer?.selectedImage?.name)
  formData.append("media_type", "photo")
  formData.append("type", "9")
  formData.append("media_desc", "recipe image")
  formData.append("media_video_type", "normal")
  if (media_id !== "" && media_id !== undefined) {
    formData.append("media_id", media_id?.toString())
  } else {
    formData.append("id", recipe_id?.toString())
  }
  formData.append("is_thumbnail", "true")
  return formData
}

export const prepareFormVideo = (
  storeReducer: { uploadVideo: any },
  recipe_id: any,
  media_id1: string | undefined,
  durationValue: any,
) => {
  console.log("abc durationValue", durationValue)
  const formData = new FormData()
  formData.append("file", storeReducer?.uploadVideo)
  formData.append("media_name", storeReducer?.uploadVideo?.name)
  formData.append("media_type", "video")
  if (media_id1 !== "" && media_id1 !== undefined) {
    formData.append("media_id", media_id1?.toString())
  } else {
    formData.append("id", recipe_id?.toString())
  }
  formData.append("media_video_type", "normal")
  formData.append("type", "9")
  formData.append("media_desc", "recipe video upload")
  formData.append("is_thumbnail", "false")
  return formData
}

export const prepareFormPasteLink = (
  storeReducer: { pasteLink: any },
  recipe_id: any,
  media_id2: string | undefined,
) => {
  const formData = new FormData()
  formData.append("media_url", storeReducer?.pasteLink)
  formData.append("media_name", "youtube_video")
  formData.append("media_type", "video")
  if (media_id2 !== "" && media_id2 !== undefined) {
    formData.append("media_id", media_id2?.toString())
  } else {
    formData.append("id", recipe_id?.toString())
  }
  formData.append("media_video_type", "youtube")
  formData.append("type", "9")
  formData.append("media_desc", "recipe youtube link")
  formData.append("is_thumbnail", "false")
  return formData
}

// our product form
export const prepareFormDataOurProduct = (
  product_id: any,
  url: any,
  media_id: string | undefined,
) => {
  const formData = new FormData()
  formData.append("file", url)
  formData.append("media_name", url?.name)
  if (media_id !== "" && media_id !== undefined) {
    formData.append("media_id", media_id?.toString())
  } else {
    formData.append("id", product_id?.toString())
  }
  formData.append("type", "8")
  formData.append("media_type", "photo")
  formData.append("media_video_type", "normal")
  formData.append("media_desc", "our product image")
  formData.append("is_thumbnail", "false")
  return formData
}

//amazon form
export const prepareFormDataAmazon = (
  formik: { values: { selectedImage: any } },
  product_id: any,
  media_id: string | undefined,
) => {
  const formData = new FormData()
  formData.append("file", formik.values.selectedImage)
  formData.append("media_name", formik.values.selectedImage?.name)
  if (media_id !== "" && media_id !== undefined) {
    formData.append("media_id", media_id?.toString())
  } else {
    formData.append("id", product_id?.toString())
  }
  formData.append("media_type", "photo")
  formData.append("type", "8")
  formData.append("media_video_type", "normal")
  formData.append("media_desc", "amazon product")
  formData.append("is_thumbnail", "true")
  return formData
}

//catering form
export const prepareFormDataCatering = (
  formik: { values: { selectedImage: any } },
  catering_id: any,
  media_id: string | undefined,
) => {
  const formData = new FormData()
  formData.append("file", formik.values.selectedImage)
  formData.append("media_name", formik.values.selectedImage?.name)
  formData.append("media_type", "photo")
  formData.append("type", "6")
  formData.append("media_desc", "Thumbnail image")
  formData.append("media_video_type", "normal")
  if (media_id !== "" && media_id !== undefined) {
    formData.append("media_id", media_id?.toString())
  } else {
    formData.append("id", catering_id?.toString())
  }
  formData.append("is_thumbnail", "true")
  return formData
}

export const prepareFormClientImage = (
  clientImage: any,
  catering_id: any,
  media_id1: string | undefined,
) => {
  const formData = new FormData()
  formData.append("file", clientImage)
  formData.append("media_name", clientImage?.name)
  if (media_id1 !== "" && media_id1 !== undefined) {
    formData.append("media_id", media_id1?.toString())
  } else {
    formData.append("id", catering_id?.toString())
  }
  formData.append("media_type", "photo")
  formData.append("type", "6")
  formData.append("media_video_type", "normal")
  formData.append("media_desc", "clientImage")
  formData.append("is_thumbnail", "false")
  return formData
}

export const prepareFormCateringVideo = (
  cateringVideo: any,
  catering_id: any,
  media_id2: string | undefined,
  durationValue: any,
) => {
  console.log("abc durationValue", durationValue)
  const formData = new FormData()
  formData.append("file", cateringVideo)
  formData.append("media_name", cateringVideo?.name)
  formData.append("media_type", "video")
  if (media_id2 !== "" && media_id2 !== undefined) {
    formData.append("media_id", media_id2?.toString())
  } else {
    formData.append("id", catering_id?.toString())
  }
  formData.append("media_video_type", "normal")
  formData.append("type", "6")
  formData.append("media_desc", "cateringVideo")
  formData.append("is_thumbnail", "false")
  return formData
}

export const prepareFormVideoCatering = (
  uploadVideo: any,
  catering_id: any,
  media_id3: string | undefined,
  durationValue?: any,
) => {
  console.log("abc durationValue", durationValue)
  const formData = new FormData()
  formData.append("file", uploadVideo)
  formData.append("media_name", uploadVideo?.name)
  formData.append("media_type", "video")
  if (media_id3 !== "" && media_id3 !== undefined) {
    formData.append("media_id", media_id3?.toString())
  } else {
    formData.append("id", catering_id?.toString())
  }
  formData.append("type", "6")
  formData.append("media_video_type", "normal")
  formData.append("media_desc", "catering client video")
  formData.append("is_thumbnail", "false")
  return formData
}
