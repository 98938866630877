import React from "react"
import { UploadVideoProps } from "../../../config/commonType"
import { toast } from "react-toastify"
// import { getVideoDuration } from "../Function/commonFunction"

const UploadVideo = (props: UploadVideoProps) => {
  const {
    title,
    setSelectedImage,
    toastIdRef,
    name,
    handleInputChange,
    setDurationValue,
    durationMultiple,
    setDurationMultiple,
    // isEdit,
    // selectedImage,
    // disable
  } = props

  const uploadRef = React.useRef<any>(null)
  // const [isProcess, setIsProcess] = useState<any>(false)
  // const [titleFile, setTitleFile] = React.useState<any>(
  //   isEdit === true ? 100 : 0,
  // )
  // const [progress, setProgress] = useState<any>(isEdit === true ? 100 : 0)
  // const statusRef = React.useRef<any>(null)
  // const loadTotalRef = React.useRef<any>(null)
  // const progressRef = React.useRef<any>(null)
  // This function will be triggered when the file field change
  const onChangeVideo = async (e: any) => {
    setSelectedImage(undefined)
    // setIsProcess(true)
    if (e.target.files && e.target.files.length > 0) {
      const duration = null
      // const duration = await getVideoDuration(e.target.files[0])
      if (setDurationValue) {
        setDurationValue(duration)
      }
      if (durationMultiple) {
        setDurationMultiple([...durationMultiple, duration])
      }
      const fileName = e.target.files[0].name
      const extension = fileName.split(".").pop()
      const isSupported = ["mp4", "mov", "mp3"].includes(extension)
      if (!isSupported) {
        toast.success("Do not supported")
        setSelectedImage(undefined)
      } else {
        setSelectedImage(e.target.files[0])
      }

      if (handleInputChange) {
        handleInputChange(e)
      }
      const file = uploadRef.current.files[0]
      // setIsProcess(false)
      // const fileReader = new FileReader()
      // fileReader.onload = function () {
      //   if (fileReader.result) {
      //     setIsProcess(false)
      //   }
      // }
      // fileReader.readAsDataURL(file)
      const formData = new FormData()
      formData.append("video", file)
      // const xhr = new XMLHttpRequest()
      // xhr.upload.addEventListener("progress", ProgressHandler, false)
      // // @ts-ignore
      // xhr.addEventListener("load", SuccessHandler, false)
      // xhr.addEventListener("error", ErrorHandler, false)
      // xhr.addEventListener("abort", AbortHandler, false)
      // xhr.open("POST", urls.api_url)
      // xhr.send(formData)
    }
  }

  // const ProgressHandler = (e: { loaded: number; total: number }) => {
  //   loadTotalRef.current.innerHTML = `uploaded ${e.loaded} bytes of ${e.total}`
  //   const percent = (e.loaded / e.total) * 100
  //   progressRef.current.value = Math.round(percent)
  //   setProgress(progressRef.current.value)
  //   statusRef.current.innerHTML = Math.round(percent)
  //   setTitleFile(statusRef.current.innerHTML)
  // }
  //
  // const SuccessHandler = (e: { target: { responseText: any } }) => {
  //   statusRef.current.innerHTML = e.target.responseText
  //   progressRef.current.value = 0
  // }
  // const ErrorHandler = () => {
  //   statusRef.current.innerHTML = "upload failed!!"
  // }
  // const AbortHandler = () => {
  //   statusRef.current.innerHTML = "upload aborted!!"
  // }

  return (
    <>
      <div className='only-positon-new'>
        <div className='category-input-feild'>
          <div className='category-input-feild-inner'>
            <label>{`${title ? title : "Upload Video"}`}</label>
            <input
              // disabled={disable}
              name={name}
              type='file'
              accept={"video/*"}
              className='upload-video'
              placeholder='Image'
              ref={uploadRef || toastIdRef}
              onChange={onChangeVideo}
            />
          </div>
        </div>
        <div className='upload-video-div-design'>
          <div className='upload-image'>
            <img src='/images/video-icon.png' alt='' />
          </div>
          <div className='click-or-drag-to-upload-title'>
            <p>Click or drag file to this area to upload</p>
          </div>
          <div className='bulk-upload-title'>
            <p>Support for a single or bulk upload.</p>
          </div>
          {/*{selectedImage !== undefined && selectedImage !== "" && (*/}
          {/*  <div className={"progress-bar-upload opacity-50"}>*/}
          {/*    <ProgressBar*/}
          {/*      label={`${titleFile}%`}*/}
          {/*      ref={progressRef}*/}
          {/*      now={progress}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*)}*/}
          {/*<p className='d-none' ref={statusRef} />*/}
          {/*<p className='d-none' ref={loadTotalRef} />*/}
          {/*<div className='text-danger fw-bold'>*/}
          {/*  {isProcess ? "Loading..." : ""}*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  )
}

export default UploadVideo
