import React, { useEffect, useState } from "react"
import EditDeleteButton from "../common/Buttons/editDeleteButton"
import SingleOfflineCard from "../common/Cards/singleOfflineCard"
import { DELETE_COURSE, GET_COURSE } from "../onlineCoursesList/queries"
import { useNavigate } from "react-router-dom"
import { useMutation } from "@apollo/client"
import {
  errorMessage,
  errorMessageShow,
  groupBys,
  requestOptions,
  successMessageShow,
} from "../common/Function/commonFunction"
import LoadMoreButton from "../common/Buttons/loadMoreButton"
import { urls } from "../../constants"
import TabTitle from "../../pages/tab-title/TabTitle"

const OfflineCourseList = () => {
  let navigate = useNavigate()
  const [deleteCourse] = useMutation(DELETE_COURSE)
  const [users, setUsers] = useState([])
  const [index, setIndex] = useState(6)
  const [isProcess, setIsProcess] = useState<any>(false)
  const [isIds, setIsIds] = useState<any>()
  const onlineArray = groupBys(users)
  const initialData =
    onlineArray?.offline && onlineArray?.offline.slice(0, index)
  useEffect(() => {
    fetchData().then((r) => console.log(r))
  }, [])

  const fetchData = async () => {
    const graphql = JSON.stringify({ query: GET_COURSE })

    const Url = `${urls.api_url}`
    // @ts-ignore
    const res = await fetch(Url, requestOptions(graphql))
    const usersData = await res.json()
    setUsers(usersData?.data?.courses)
  }

  if (initialData === undefined) {
    return <div className='loading_message mt-200'>Loading...</div>
  }

  //load more function
  const loadMore = () => {
    setIndex(index + 6)
  }

  // onClick delete function
  const deleteCourses = (course_id: string | number) => {
    setIsIds(course_id)
    setIsProcess(true)
    let course_ids = course_id
    deleteCourse({
      variables: { course_id, course_ids },
      onCompleted: (data) => {
        if (data.delete_courses.__typename) {
          setIsProcess(false)
          fetchData().then((r) => console.log(r))
          return successMessageShow("Offline course delete successfully!")
        }
      },
      onError: (error) => {
        if (error.message.includes("Foreign key violation.")) {
          setIsProcess(false)
          return errorMessage(
            "Course is not delete because user purchased this course",
          )
        } else {
          return errorMessageShow(error)
        }
      },
    }).then((r) => console.log(r))
  }

  //onClick edit
  const editOfflineCourse = (course_id: any) => {
    navigate(`/edit-offline-course/${course_id}`)
  }

  return (
    <>
      <TabTitle title={"Offline Course"} />
      <div className='common-page-scroll'>
        <section className='offline-class-list'>
          <div className='common-page-padding'>
            <div className='row'>
              {initialData !== undefined ? (
                initialData &&
                initialData.map(
                  (item: any, index: React.Key | null | undefined) => {
                    return (
                      <>
                        <SingleOfflineCard
                          isIds={isIds}
                          isProcess={isProcess}
                          image={item?.course_media[0]?.media_fk?.media_url}
                          item={item}
                          key={index}
                          onClick={deleteCourses}
                          editClick={editOfflineCourse}
                          course_id={item.course_id}
                        />
                      </>
                    )
                  },
                )
              ) : (
                <div className='col-12 col-sm-12 col-md-4 col-lg-4'>
                  <div className='offline-class-list-details'>
                    <div className='offline-class-image'>
                      <img src='/images/comming-soon.png' alt='' />
                    </div>
                    <div className='offline-class-list-details-inner'>
                      <div className='offline-class-title'>
                        <h1>Coming Soon</h1>
                      </div>
                    </div>
                    <EditDeleteButton />
                  </div>
                </div>
              )}
            </div>
            {/*<!------- Load more button section start-----!>*/}
            {onlineArray?.offline.length !== initialData.length &&
              onlineArray?.offline &&
              onlineArray?.offline.length > 6 && (
                <LoadMoreButton onClick={loadMore} />
              )}
          </div>
        </section>
      </div>
    </>
  )
}

export default OfflineCourseList
