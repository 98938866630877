import gql from "graphql-tag"

export const FETCH_COURSE = gql`
  query GetCourses {
    courses {
      course_id
      course_status
      course_currency
      course_name
      course_type
      cuisine
      serving
      time_unit
      category_id
      cook_time
      course_amount
      course_discount_amount
      preparation_time
      total_time
      course_content
      course_desc
      course_details
      course_usage_steps
      created_dt
      updated_dt
      course_media {
        course_id
        media_fk {
          media_url
          media_type
          media_status
          media_duration
          media_id
          media_desc
          media_name
          media_photo_type
          media_video_type
        }
        media_id
        course_media_id
      }
    }
  }
`

export const GET_COURSE = `
 query GetCourses {
    courses (order_by: {course_id: desc}){
      course_id
      course_status
      course_currency
      course_name
      course_type
      cuisine
      serving
      time_unit
      category_id
      cook_time
      course_amount
      course_discount_amount
      preparation_time
      total_time
      course_content
      course_desc
      course_details
      course_usage_steps
      created_dt
      updated_dt
      course_media {
        course_id
        media_fk {
          media_url
          media_type
          media_status
          media_id
          media_duration
          media_desc
          media_name
          media_photo_type
          media_video_type
        }
        media_id
        course_media_id
      }
    }
  }
`

export const DELETE_COURSE = gql`
  mutation ($course_id: bigint!, $course_ids: Int!) {
    delete_course_media(where: { course_id: { _eq: $course_ids } }) {
      affected_rows
      returning {
        course_id
        course_media_id
      }
    }
    delete_course_enrollments(where: { course_id: { _eq: $course_ids } }) {
      affected_rows
      returning {
        course_id
      }
    }
    delete_favorite_courses(where: { course_id: { _eq: $course_ids } }) {
      affected_rows
      returning {
        course_id
      }
    }
    delete_courses(where: { course_id: { _eq: $course_id } }) {
      affected_rows
      returning {
        category_id
        cook_time
        course_amount
        course_content
        course_currency
        course_desc
        course_details
        course_discount_amount
        course_id
        course_name
        course_status
        course_type
        course_usage_steps
        created_dt
        cuisine
        preparation_time
        serving
        time_unit
        total_time
        updated_dt
      }
    }
  }
`

export const ONLY_COURSE_NAME = `query GetCourses ($course_id: bigint) {
  courses(where: {course_id: {_eq: $course_id}}) {
    course_name
  }
}`

export const FETCH_ENROLLMENT_COURSE = gql`
  query course_enrollments {
    course_enrollments {
      course_channel
      course_enrollment_status
      created_by
      currency
      payment_method
      razorpay_payment_id
      transfer_amount
      updated_by
      course_fk {
        category_id
        course_name
      }
      user_master_fk {
        full_name
        user_id
      }
    }
  }
`
