import React from "react"
import RichTextEditor from "react-rte"
import { RichTextFieldProps } from "../../../config/commonType"

function RichTextEditors(props: RichTextFieldProps) {
  const { placeholder, label, errors, onChange, mdeState, touched } = props

  const toolbarConfig = {
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "BLOCK_TYPE_DROPDOWN",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
      { label: "Strikethrough", style: "STRIKETHROUGH" },
      { label: "Monospace", style: "CODE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
      { label: "Code Block", style: "code-block" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
      { label: "Blockquote", style: "blockquote" },
    ],
  }

  return (
    <>
      <div className='category-input-feild'>
        <div className='category-input-feild-inner'>
          <div>
            <label>{label}</label>
            <br></br>
            <RichTextEditor
              // @ts-ignore
              toolbarConfig={toolbarConfig}
              value={mdeState}
              onChange={onChange}
              editorStyle={{ minHeight: "100px" }}
              placeholder={placeholder}
            />
            <span className='text-danger'>
              {errors && touched ? errors : ""}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default RichTextEditors
