import gql from "graphql-tag"

export const GET_PRODUCT = `query GetProducts {
  products {
    product_id
    product_name
    product_type
    discount_price
    product_price
    product_desc
    created_dt
    updated_dt
    product_link
    product_media {
      media_id
      product_media {
        media_id
        media_name
        media_photo_type
        media_status
        media_type
        media_url
        media_video_type
        thumbnail_url
        upload_status
        media_desc
        is_thumbnail
      }
      product_media_id
    }
  }
}
`

export const DELETE_OUR_PRODUCT = gql`
  mutation DeleteProducts($product_id: Int, $product_ids: bigint) {
    delete_product_media(where: { product_id: { _eq: $product_id } }) {
      affected_rows
      returning {
        media_id
        product_id
      }
    }
    delete_products(where: { product_id: { _eq: $product_ids } }) {
      affected_rows
      returning {
        discount_price
        product_desc
        product_link
        product_name
        product_price
        product_type
      }
    }
  }
`
