import React from "react"
import ProductPrev from "./product-prev"
import AddOurProductForm from "./addOurProductForm"
import TabTitle from "../../pages/tab-title/TabTitle"

const AddOurProducts = (props: { singleArray?: string[] }) => {
  const { singleArray } = props
  return (
    <>
      <TabTitle title={"Add Our Product"} />
      <section className='online-classes add-recipes'>
        <div className='common-page-padding'>
          <div className='row'>
            {/*<!------ add offline class section -----!>*/}
            <AddOurProductForm singleArray={singleArray} />

            {/*<!------ offline class section -----!>*/}
            <ProductPrev />
          </div>
        </div>
      </section>
    </>
  )
}

export default AddOurProducts
