import React from "react"
import OnlineCourses from "./onlineCourses"
import AddOnlineForm from "./addOnlineForm"
import TabTitle from "../../pages/tab-title/TabTitle"

const OnlineCourse = (props: { singleArray?: any }) => {
  const { singleArray } = props
  return (
    <>
      <section className='online-classes add-recipes'>
        <TabTitle title={"Add Online Course"} />
        <div className='common-page-padding'>
          <div className='row'>
            {/*<!------ add online class section -----!>*/}
            <AddOnlineForm singleArray={singleArray} />
            {/*<!------ online class section -----!>*/}
            <OnlineCourses />
          </div>
        </div>
      </section>
    </>
  )
}

export default OnlineCourse
