import { Form } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { successMessageShow } from "../../components/common/Function/commonFunction"
import apiConfig from "../../config/apiConfig"
import * as EmailValidator from "email-validator"

const ForgotPassword = () => {
  let res: any
  const [mobile_number, setMobile_number] = useState<any>()
  const [error, setError] = useState("")
  let code: any = "+91"

  //on change input
  const onChangeMobile_number = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMobile_number(e.target.value)
  }

  useEffect(() => {
    localStorage.setItem("mobile_number", mobile_number)
  }, [mobile_number, code])

  const handleValidation = () => {
    let isValid = false
    if (!mobile_number) {
      setError("Please enter email!")
    } else if (!EmailValidator.validate(mobile_number)) {
      setError("Please enter valid email!")
    } else {
      isValid = true
    }
    return isValid
  }

  //forgot api function
  function onClickForgot() {
    const valid = handleValidation()
    if (valid) {
      apiConfig
        .get(`user/${mobile_number}`)
        .then((response) => {
          res = response.data.message
          successMessageShow(res).then((r) => console.log(r))
          // window.location.href = "/verify-otp"
        })
        .catch((error) => {
          setError(error.response.data.message)
        })
    }
  }

  return (
    <>
      <section className='login'>
        <div className='row m-0'>
          <div className='col-sm-12 col-md-7 col-lg-7 col-12 p-0'>
            <div className='login-image'>
              <img src='/images/login.png' alt={""} />
            </div>
          </div>
          <div className='col-sm-12 col-md-5 col-lg-5 col-12 p-0'>
            <div className='login-details'>
              <div className='login-details-inner'>
                <div className='login-logo'>
                  <img src='/images/logo.svg' alt={""} />
                </div>
                <div className='login-title'>
                  <h1>Forgot password</h1>
                  <p>Enter your email below, we’ll send you a verify code.</p>
                </div>
                <div className='login-form'>
                  <Form>
                    <Form.Group className='form-group'>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        // onKeyPress={(e: any) => onKeyPressFunction(e, 10)}
                        type='email'
                        placeholder='Input your email here'
                        name='email_id'
                        onChange={onChangeMobile_number}
                        value={mobile_number}
                      />
                    </Form.Group>
                    <div className='login-button'>
                      <span className='text-danger'>{error ? error : ""}</span>
                      <button type='button' onClick={onClickForgot}>
                        Login
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ForgotPassword
