import gql from "graphql-tag"
import apiConfigWithFormData from "../../config/apiConfigWithFormData"
import { uploadError } from "../common/Function/commonFunction"

export const FETCH_RECIPES = gql`
  query GetRecipeMedia {
    recipe_media {
      recipe_media_id
      is_default
      media_id
      recipe_id
      created_dt
      updated_dt
      media_fk {
        media_url
        media_name
        media_id
        media_photo_type
        media_duration
        media_status
        media_desc
        media_type
      }
      recipe_fk {
        preparation_time
        cuisine
        created_dt
        recipe_desc
        recipe_details
        recipe_id
        recipe_name
        recipe_status
        recipe_type
        serving
        time_unit
        total_time
      }
    }
  }
`

export const INSERT_RECIPES = gql`
  mutation InsertRecipes(
    $category_id: Int
    $recipe_name: String
    $cook_time: numeric
    $preparation_time: numeric
    $total_time: numeric
    $recipe_desc: String
    $recipe_details: String
    $serving: String
    $cuisine: String
    $recipe_type: String
  ) {
    insert_recipes(
      objects: {
        category_id: $category_id
        recipe_name: $recipe_name
        cook_time: $cook_time
        preparation_time: $preparation_time
        total_time: $total_time
        recipe_desc: $recipe_desc
        recipe_details: $recipe_details
        serving: $serving
        cuisine: $cuisine
        recipe_type: $recipe_type
        time_unit: "minutes"
      }
    ) {
      affected_rows
      returning {
        recipe_id
        cuisine
        recipe_name
        recipe_status
        recipe_type
        serving
        time_unit
        category_id
        cook_time
        preparation_time
        total_time
        recipe_desc
        recipe_details
        created_dt
        updated_dt
      }
    }
  }
`

export const UPDATE_RECIPES = gql`
  mutation (
    $recipe_id: bigint
    $category_id: Int
    $recipe_name: String
    $cook_time: numeric
    $preparation_time: numeric
    $total_time: numeric
    $recipe_desc: String
    $recipe_details: String
    $serving: String
    $cuisine: String
    $recipe_type: String
  ) {
    update_recipes(
      where: { recipe_id: { _eq: $recipe_id } }
      _set: {
        category_id: $category_id
        recipe_name: $recipe_name
        cook_time: $cook_time
        preparation_time: $preparation_time
        total_time: $total_time
        recipe_desc: $recipe_desc
        recipe_details: $recipe_details
        serving: $serving
        time_unit: "minutes"
        cuisine: $cuisine
        recipe_type: $recipe_type
      }
    ) {
      affected_rows
      returning {
        recipe_id
        cuisine
        recipe_name
        recipe_status
        recipe_type
        serving
        time_unit
        category_id
        cook_time
        preparation_time
        total_time
        recipe_desc
        recipe_details
        created_dt
        updated_dt
      }
    }
  }
`

export const SINGLE_RECORD_FETCH = `query GetRecipes ($recipe_id: bigint){
  recipes(where: {recipe_id: {_eq:  $recipe_id}}) {
    recipe_id
    cuisine
    recipe_name
    recipe_status
    recipe_type
    serving
    time_unit
    category_id
    cook_time
    preparation_time
    total_time
    recipe_desc
    recipe_details
    created_dt
    updated_dt
    recipe_media {
      recipe_id
      media_id
      media_fk {
        media_url
        media_type
        media_status
        media_photo_type
        media_name
        media_duration
        media_id
        media_desc
        is_thumbnail
        media_video_type
      }
    }
  }
}`

//upload image api call
export function uploadMedia(data: any) {
  apiConfigWithFormData
    .post("upload-media", data)
    .then(() => {})
    .catch((response: any) => {
      uploadError(response)
    })
}

//update upload image api call
export function updateUploadMedia(media_id: any, data: any) {
  if (media_id !== undefined) {
    apiConfigWithFormData
      .put(`/update-media/${media_id}`, data)
      .then(() => {})
      .catch((response: any) => {
        uploadError(response)
      })
  }
}
