import React from "react"

const LoadMoreButton = (props: { onClick?: any }) => {
  const { onClick } = props
  return (
    <>
      <div className='load-more-button'>
        <button type='button' onClick={onClick}>
          Load more
        </button>
      </div>
    </>
  )
}

export default LoadMoreButton
