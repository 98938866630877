import Sidebar from "./sidebar/sidebar"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import Header from "./header/header"
import { MakeHeader } from "../store/ui/uiSlices"
import { useDispatch } from "react-redux"
import { ToastContainer } from "react-toastify"
import Router from "../router"
import { ONLY_COURSE_NAME } from "../components/onlineCoursesList/queries"
import { urls } from "../constants"
import { requestOptions } from "../components/common/Function/commonFunction"

const last = window.location.pathname.split("/")
const lastUrl = last[last.length - 2]

const Common = () => {
  let location = useLocation()
  const dispatch = useDispatch()
  const courseId = location.pathname.split("/")
  const [courseName, setCourseName] = useState("Online Course List")

  const authToken = localStorage.getItem("token")
  if (!authToken) {
    window.location.href = "/"
  }

  useEffect(() => {
    if (
      location.pathname === `/edit-online-course/${courseId[2]}` ||
      location.pathname === `/edit-offline-course/${courseId[2]}`
    ) {
      fetchData(courseId[2]).then((r) => console.log(r))
    } else {
      setCourseName("")
    }
  }, [courseId, location, courseName])

  const fetchData = async (id: string) => {
    const graphql = JSON.stringify({
      query: ONLY_COURSE_NAME,
      variables: { course_id: id },
    })
    const Url = `${urls.api_url}`

    // @ts-ignore
    const res = await fetch(Url, requestOptions(graphql))
    const usersData = await res.json()
    setCourseName(usersData?.data?.courses[0]?.course_name)
  }

  let testData = [
    {
      title: "Dashboard",
      mainLink: "",
      middleLink: "",
      subLink: "Dashboard",
      pathName: "/dashboard",
    },
    {
      title: "Home",
      mainLink: "",
      middleLink: "",
      subLink: "Home",
      pathName: "/add-banner",
    },
    {
      title: "Home",
      mainLink: "",
      middleLink: "",
      subLink: "Home",
      pathName: "edit-banner",
    },
    {
      title: "Home",
      mainLink: "",
      middleLink: "",
      subLink: "Home",
      pathName: "/banner-list",
    },
    {
      title: "Add Categories",
      mainLink: "Courses",
      middleLink: "",
      subLink: "Add Categories",
      pathName: "/add-category",
    },
    {
      title: "Add Online Course",
      mainLink: "Courses",
      middleLink: "",
      subLink: "Add Online Course",
      pathName: "/add-course",
    },
    {
      title: courseName,
      mainLink: "Courses",
      middleLink: "",
      subLink: courseName,
      pathName: "edit-online-course",
    },
    {
      title: "Recipes List",
      mainLink: "Recipes",
      middleLink: "",
      subLink: "Recipes List",
      pathName: "edit-recipe",
    },
    {
      title: "Add Offline Course",
      mainLink: "Courses",
      middleLink: "",
      subLink: "Add Offline Course",
      pathName: "/add-offline-course",
    },
    {
      title: "Online Course List",
      mainLink: "Courses",
      middleLink: "",
      subLink: "Online Course List",
      pathName: "/courses-list",
    },
    {
      title: "Offline Course List",
      mainLink: "Courses",
      middleLink: "",
      subLink: "Offline Course List",
      pathName: "/offline-course-list",
    },
    {
      title: courseName,
      mainLink: "Courses",
      middleLink: "",
      subLink: courseName,
      pathName: "edit-offline-course",
    },
    {
      title: "Add Categories",
      mainLink: "Recipes",
      middleLink: "",
      subLink: "Add Categories",
      pathName: "/add-recipes-category",
    },
    {
      title: "Add Recipes",
      mainLink: "Recipes",
      middleLink: "",
      subLink: "Add Recipes",
      pathName: "/add-recipes",
    },
    {
      title: "Recipes List",
      mainLink: "Recipes",
      middleLink: "",
      subLink: "Recipes List",
      pathName: "/recipe-list",
    },
    {
      title: "Add Our Product",
      mainLink: "Products",
      middleLink: "",
      subLink: "Add Our Product",
      pathName: "/add-our-product",
    },
    {
      title: "Our Products List",
      mainLink: "Products",
      middleLink: "",
      subLink: "Our Products List",
      pathName: "/our-products-list",
    },
    {
      title: "Amazon Products",
      mainLink: "",
      middleLink: "",
      subLink: "Amazon Products",
      pathName: "/amazon-products",
    },
    {
      title: "Amazon Products",
      mainLink: "",
      middleLink: "",
      subLink: "Amazon Products",
      pathName: "edit-amazon-product",
    },
    {
      title: "Our Products",
      mainLink: "Products ",
      middleLink: "",
      subLink: "Our Products",
      pathName: "edit-our-product",
    },
    {
      title: "Catering Videos",
      mainLink: "Catering ",
      middleLink: "",
      subLink: "Catering Videos",
      pathName: "edit-catering",
    },
    {
      title: "Amazon Products List",
      mainLink: "Amazon Products",
      middleLink: "",
      subLink: "Amazon Products List",
      pathName: "/amazon-products-list",
    },
    {
      title: "Add Catering Videos",
      mainLink: "Catering",
      middleLink: "",
      subLink: "Add Catering Videos",
      pathName: "/add-catering-video",
    },
    {
      title: "Catering Videos List",
      mainLink: "Catering",
      middleLink: "",
      subLink: "Catering Videos List",
      pathName: "/catering-list",
    },
    {
      title: "User List",
      mainLink: "",
      middleLink: "",
      subLink: "User List",
      pathName: "/user-list",
    },
    {
      title: "User Courses",
      mainLink: "",
      middleLink: "",
      subLink: "User List",
      pathName: "user-active",
    },
  ]

  useEffect(() => {
    if (location.pathname) {
      testData &&
        testData.map((item) => {
          if (location.pathname === item.pathName || lastUrl === item.pathName)
            dispatch(MakeHeader(item))
        })
    }
  }, [location, testData, dispatch, courseId, courseName])

  return (
    <>
      <section className='common-page'>
        <Sidebar testData={testData} />
        <div className='header-and-main-content'>
          <Header />
          <Router />
        </div>
      </section>
      <ToastContainer />
    </>
  )
}
export default Common
