import React from "react"
import {
  RecipeCategoryProps,
  SelectDropdownProps,
} from "../../../config/commonType"

const SelectDropdown = (props: SelectDropdownProps) => {
  const { title, data, onChange, handleBlur, value, errors, name, touched } =
    props
  return (
    <>
      <div className='category-input-feild'>
        <div className='category-input-feild-inner'>
          <label htmlFor={name}>{title}</label>
          <select
            aria-label='Default select example'
            className='form-select'
            name={name}
            value={value}
            onChange={onChange}
            onBlur={handleBlur}
          >
            <option>Select Category</option>
            {data &&
              data.map((item: RecipeCategoryProps, index: React.Key) => {
                return (
                  <option value={item.category_id} key={index}>
                    {item.category_name}
                  </option>
                )
              })}
          </select>
          <span className='text-danger'>{errors && touched ? errors : ""}</span>
        </div>
      </div>
    </>
  )
}

export default SelectDropdown
