/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { headerDetails, HeaderType } from "./types"

const initialState: HeaderType = {
  headerDetails: {},
}

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    MakeHeader: (state, action: PayloadAction<Partial<headerDetails>>) => {
      state.headerDetails = action.payload
    },
  },
})

export const { MakeHeader } = uiSlice.actions

export default uiSlice.reducer
