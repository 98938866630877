import gql from "graphql-tag"

export const FETCH_DASHBOARD = gql`
  query MyQuery {
    course_enrollments(order_by: { created_dt: desc }) {
      course_channel
      course_enrollment_id
      course_enrollment_status
      course_id
      created_dt
      currency
      user_id
      updated_dt
      transfer_amount
      payment_method
      course_fk {
        category_id
        course_name
      }
      user_master_fk {
        full_name
        user_id
      }
    }
  }
`

export const FETCH_USER = gql`
  query GetUserMaster {
    user_master {
      user_id
      email_verified
      is_deleted
      mobile_verified
      user_status
      channel
      email_id
      full_name
      mobile_no
      media_id
      role_id
      user_password
      created_dt
      updated_dt
    }
  }
`

export const TODAYTOTALCOUNTS = `query GetCourses ($created_dt: timestamp, $tomorrow: timestamp) {\n  course_enrollments_aggregate(where: {_and: {created_dt: {_gt: $created_dt, _lt: $tomorrow}}, course_enrollment_status: {_eq: "successful"}}) {\n    aggregate {\n      sum {\n        transfer_amount\n      }\n    }\n  }\n}`

export const weekTotalCounts = `query GetCourses ($created_dt: timestamp, $tomorrow: timestamp) {\n  course_enrollments_aggregate(where: {_and: {created_dt: {_gt: $created_dt, _lt: $tomorrow}}, course_enrollment_status: {_eq: "successful"}}) {\n    aggregate {\n      sum {\n        transfer_amount\n      }\n    }\n  }\n}`

export const monthTotalCounts = `query GetCourses ($created_dt: timestamp, $tomorrow: timestamp) {\n  course_enrollments_aggregate(where: {_and: {created_dt: {_gt: $created_dt, _lt: $tomorrow}}, course_enrollment_status: {_eq: "successful"}}) {\n    aggregate {\n      sum {\n        transfer_amount\n      }\n    }\n  }\n}`

export const firstWeekTotalCounts = `query GetCourses ($created_dt: timestamp, $tomorrow: timestamp) {\n  course_enrollments_aggregate(where: {_and: {created_dt: {_gt: $created_dt, _lt: $tomorrow}}, course_enrollment_status: {_eq: "successful"}}) {\n    aggregate {\n      sum {\n        transfer_amount\n      }\n    }\n  }\n}`

export const secondWeekTotalCounts = `query GetCourses ($created_dt: timestamp, $tomorrow: timestamp) {\n  course_enrollments_aggregate(where: {_and: {created_dt: {_gt: $created_dt, _lt: $tomorrow}}, course_enrollment_status: {_eq: "successful"}}) {\n    aggregate {\n      sum {\n        transfer_amount\n      }\n    }\n  }\n}`
export const threeWeekTotalCounts = `query GetCourses ($created_dt: timestamp, $tomorrow: timestamp) {\n  course_enrollments_aggregate(where: {_and: {created_dt: {_gt: $created_dt, _lt: $tomorrow}}, course_enrollment_status: {_eq: "successful"}}) {\n    aggregate {\n      sum {\n        transfer_amount\n      }\n    }\n  }\n}`
export const fourWeekTotalCounts = `query GetCourses ($created_dt: timestamp, $tomorrow: timestamp) {\n  course_enrollments_aggregate(where: {_and: {created_dt: {_gt: $created_dt, _lt: $tomorrow}}, course_enrollment_status: {_eq: "successful"}}) {\n    aggregate {\n      sum {\n        transfer_amount\n      }\n    }\n  }\n}`
