import AddCateringVideoPrev from "./add-catering-video-prev"
import AddCateringForm from "./addCateringForm"
import TabTitle from "../../pages/tab-title/TabTitle"
import React from "react"

const Addcateringvideo = (props: { singleArray?: any }) => {
  const { singleArray } = props
  return (
    <>
      <TabTitle title={"Add Catering Videos"} />
      <section className='online-classes add-recipes'>
        <div className='common-page-padding'>
          <div className='row'>
            {/*<!------ add offline class section -----!>*/}
            <AddCateringForm singleArray={singleArray} />

            {/*<!------ offline class section -----!>*/}
            <AddCateringVideoPrev />
          </div>
        </div>
      </section>
    </>
  )
}

export default Addcateringvideo
