import React from "react"
import { OnlineAddVideoButtonProps } from "../../../config/commonType"
import { override } from "../Function/commonFunction"
import ClipLoader from "react-spinners/ClipLoader"

const OnlineAddVideoButton = (props: OnlineAddVideoButtonProps) => {
  const {
    handleAddClick,
    onClickFirst,
    onClickSecond,
    disable,
    isProcess,
    disableFirst,
  } = props

  return (
    <>
      <div className='add-videos-reset-and-done-button'>
        <div className='add-video-button'>
          <button type='button' onClick={handleAddClick} disabled={isProcess}>
            Add Videos
          </button>
        </div>
        <div className='right-button'>
          <div className='reset-button'>
            <button
              type='button'
              onClick={onClickFirst}
              disabled={disableFirst}
            >
              Reset
            </button>
          </div>
          <div className='done-button'>
            <button
              type='submit'
              className={"btn"}
              onClick={onClickSecond}
              disabled={disable || isProcess}
            >
              {isProcess ? (
                <ClipLoader color='#ffffff' size={17} cssOverride={override} />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default OnlineAddVideoButton
