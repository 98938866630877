import React from "react"
import { editDeleteButtonProps } from "../../../config/commonType"
import ClipLoader from "react-spinners/ClipLoader"
import { override } from "../Function/commonFunction"

const EditDeleteButton = (props: editDeleteButtonProps) => {
  const { onClickDelete, course_id, editClick, isProcess, matchId } = props
  return (
    <>
      <div className='edit-and-delete-button'>
        <div className='edit-button'>
          <button type='button' onClick={() => editClick(course_id)}>
            Edit
          </button>
        </div>
        <div className='delete-button'>
          <button type='button' onClick={() => onClickDelete(course_id)}>
            {matchId === course_id ? (
              isProcess ? (
                <ClipLoader color='#f5222d' size={17} cssOverride={override} />
              ) : (
                "Delete"
              )
            ) : (
              "Delete"
            )}
          </button>
        </div>
      </div>
    </>
  )
}

export default EditDeleteButton
