import gql from "graphql-tag"
import apiConfigWithFormData from "../../config/apiConfigWithFormData"
import { uploadError } from "../common/Function/commonFunction"

export const ADD_COURSE = gql`
  mutation (
    $category_id: Int
    $course_amount: numeric
    $course_content: String
    $course_usage_steps: String
    $course_type: String
    $course_desc: String
    $course_details: String
    $cuisine: String
    $serving: String
    $total_time: numeric
    $preparation_time: numeric
    $course_discount_amount: numeric
    $course_name: String
    $cook_time: numeric
    $course_status: Boolean
  ) {
    insert_courses(
      objects: {
        category_id: $category_id
        course_amount: $course_amount
        course_usage_steps: $course_usage_steps
        course_type: "online"
        time_unit: "minutes"
        course_currency: "INR"
        course_content: $course_content
        cuisine: $cuisine
        serving: $serving
        total_time: $total_time
        preparation_time: $preparation_time
        course_desc: $course_desc
        course_details: $course_details
        course_discount_amount: $course_discount_amount
        course_name: $course_name
        cook_time: $cook_time
        course_status: $course_status
      }
    ) {
      affected_rows
      returning {
        category_id
        cook_time
        course_amount
        course_desc
        course_details
        course_discount_amount
        course_id
        course_name
        course_status
        course_type
        created_dt
        cuisine
        preparation_time
        serving
        time_unit
        total_time
        updated_dt
        course_content
        course_currency
        course_usage_steps
      }
    }
  }
`

export const UPDATE_COURSE = gql`
  mutation (
    $course_id: bigint
    $category_id: Int
    $course_amount: numeric
    $course_content: String
    $course_usage_steps: String
    $course_type: String
    $course_desc: String
    $course_details: String
    $cuisine: String
    $serving: String
    $total_time: numeric
    $preparation_time: numeric
    $course_discount_amount: numeric
    $course_name: String
    $cook_time: numeric
    $course_status: Boolean
  ) {
    update_courses(
      where: { course_id: { _eq: $course_id } }
      _set: {
        category_id: $category_id
        course_amount: $course_amount
        course_usage_steps: $course_usage_steps
        course_type: "online"
        time_unit: "minutes"
        course_content: $course_content
        cuisine: $cuisine
        serving: $serving
        course_currency: "INR"
        total_time: $total_time
        preparation_time: $preparation_time
        course_desc: $course_desc
        course_details: $course_details
        course_discount_amount: $course_discount_amount
        course_name: $course_name
        cook_time: $cook_time
        course_status: $course_status
      }
    ) {
      returning {
        category_id
        cook_time
        course_amount
        course_content
        course_currency
        course_desc
        course_details
        course_discount_amount
        course_id
        course_name
        course_status
        course_type
        course_usage_steps
        created_dt
        cuisine
        preparation_time
        serving
        time_unit
        total_time
        updated_dt
      }
    }
  }
`

export const SINGLE_RECORD_FETCH = `
query GetCourses($course_id: bigint){
  courses(where: {course_id: {_eq: $course_id}}) {
    course_id
    course_status
    course_currency
    course_name
    course_type
    cuisine
    serving
    time_unit
    category_id
    cook_time
    course_amount
    course_discount_amount
    preparation_time
    total_time
    course_content
    course_desc
    course_details
    course_usage_steps
    created_dt
    updated_dt
    course_media {
      media_fk {
        media_desc
        media_duration
        is_thumbnail
        created_dt
        media_id
        media_name
        media_photo_type
        media_status
        media_type
        media_url
        media_video_type
        thumbnail_url
        updated_dt
        upload_status
      }
      course_id
      media_id
      updated_dt
      course_media_id
    }
  }
}
`

export const DELETE_COURSE_MEDIA = gql`
  mutation DeleteCourseMedia($media_id: Int) {
    delete_course_media(where: { media_id: { _eq: $media_id } }) {
      affected_rows
      returning {
        course_id
        media_id
        course_fk {
          course_name
          course_details
          course_desc
          course_amount
        }
      }
    }
  }
`

export const COURSE_STATUS_UPDATED = gql`
  mutation UpdatedStatusCourses($course_id: bigint, $course_status: Boolean) {
    update_courses(
      where: { course_id: { _eq: $course_id } }
      _set: { course_status: $course_status }
    ) {
      affected_rows
      returning {
        course_status
      }
    }
  }
`

//upload image api call
export function uploadMedia(data: any) {
  apiConfigWithFormData
    .post("upload-media", data)
    .then(() => {})
    .catch((response: any) => {
      uploadError(response)
    })
}

//update upload image api call
export function updateUploadMedia(media_id: any, data: any) {
  if (media_id !== undefined) {
    apiConfigWithFormData
      .put(`/update-media/${media_id}`, data)
      .then(() => {})
      .catch((response: any) => {
        uploadError(response)
      })
  }
}
