import gql from "graphql-tag"
import apiConfigWithFormData from "../../config/apiConfigWithFormData"
import { uploadError } from "../common/Function/commonFunction"

export const FETCH_OUR_PRODUCT = gql`
  query GetProducts {
    products {
      product_id
      product_name
      product_type
      discount_price
      product_price
      product_desc
      created_dt
      updated_dt
      product_link
      product_media {
        media_id
        product_media {
          media_id
          media_name
          media_photo_type
          media_status
          media_type
          media_url
          media_video_type
          thumbnail_url
          upload_status
          media_desc
          is_thumbnail
        }
        product_media_id
      }
    }
  }
`

export const ADD_OUR_PRODUCT = gql`
  mutation InsertProducts(
    $product_name: String
    $product_link: String
    $product_desc: String
    $product_price: numeric
    $discount_price: numeric
  ) {
    insert_products(
      objects: {
        product_name: $product_name
        product_link: $product_link
        product_desc: $product_desc
        discount_price: $discount_price
        product_price: $product_price
        product_type: "ourproduct"
      }
    ) {
      affected_rows
      returning {
        product_id
        product_link
        product_name
        product_type
        discount_price
        product_price
        product_desc
        created_dt
        updated_dt
      }
    }
  }
`

export const UPDATE_OUR_PRODUCT = gql`
  mutation (
    $product_id: bigint
    $product_name: String
    $product_link: String
    $product_desc: String
    $product_price: numeric
    $discount_price: numeric
  ) {
    update_products(
      where: { product_id: { _eq: $product_id } }
      _set: {
        product_name: $product_name
        product_link: $product_link
        product_desc: $product_desc
        discount_price: $discount_price
        product_price: $product_price
        product_type: "ourproduct"
      }
    ) {
      returning {
        product_id
        product_link
        product_name
        product_type
        created_dt
        updated_dt
        discount_price
        product_price
        product_desc
      }
    }
  }
`

export const SINGLE_RECORD_FETCH = `query GetProducts ($product_id: bigint){
  products(where: {product_id: {_eq: $product_id}}) {
    product_id
    product_name
    product_type
    discount_price
    product_price
    product_desc
    created_dt
    updated_dt
    product_link
    product_media {
      media_id
      product_media {
        media_id
        media_name
        media_photo_type
        media_status
        media_type
        media_url
        media_video_type
        thumbnail_url
        upload_status
        media_desc
        is_thumbnail
      }
      product_media_id
    }
  }
}`

//upload image api call
export function uploadMedia(data: any, navigate?: any) {
  apiConfigWithFormData
    .post("upload-media", data)
    .then(() => {
      navigate(`/our-products-list`)
    })
    .catch((response: any) => {
      uploadError(response)
    })
}

//update upload image api call
export function updateUploadMedia(media_id: any, data: any, navigate?: any) {
  if (media_id !== undefined) {
    apiConfigWithFormData
      .put(`/update-media/${media_id}`, data)
      .then(() => {
        navigate(`/our-products-list`)
      })
      .catch((response: any) => {
        uploadError(response)
      })
  }
}
