import React from "react"
import UserListPage from "../../components/user-lists/user-list"

const UserList = () => {
  return (
    <>
      <UserListPage />
    </>
  )
}
export default UserList
