import React from "react"
import AddRecipes from "../../components/addRecipes/addRecipes"

const AddRecipe = () => {
  return (
    <>
      <AddRecipes />
    </>
  )
}

export default AddRecipe
