import React from "react"
import EditDeleteButton from "../Buttons/editDeleteButton"
import { RecipesCardProps } from "../../../config/commonType"

const SingleAmazonProductCard = (props: RecipesCardProps) => {
  const { title, onClick, course_id, editClick, image, isIds, isProcess } =
    props

  return (
    <>
      <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-2'>
        <div className='couses-list-details'>
          <div className='couses-images'>
            {image ? (
              <img src={image} alt='' />
            ) : (
              <img src='/images/products-img.png' alt='' />
            )}
          </div>
          <div className='recommended-block min-height'>
            <div className='recommended-block-title'>
              <h3>{title}</h3>
            </div>
          </div>
          {/*<!-------edit-delete button section start-----!>*/}
          <EditDeleteButton
            matchId={isIds}
            isProcess={isProcess}
            onClickDelete={onClick}
            course_id={course_id}
            editClick={editClick}
          />
        </div>
      </div>
    </>
  )
}
export default SingleAmazonProductCard
