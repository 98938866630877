import React, { useState } from "react"
import { UploadVideoProps } from "../../../config/commonType"
// import { getVideoDuration } from "../Function/commonFunction"

const MultipleUploadVideo = (props: UploadVideoProps) => {
  const {
    title,
    setSelectedImage,
    toastIdRef,
    name,
    handleInputChange,
    multiple,
    setDurationValue,
    fileObj,
    fileArray,
  } = props

  const uploadRef = React.useRef<any>(null)
  const [isProcess, setIsProcess] = useState<any>(false)

  // This function will be triggered when the file field change
  const onChangeVideo = async (e: any) => {
    setSelectedImage(undefined)
    // setIsProcess(true)
    if (e.target.files && e.target.files.length > 0) {
      fileObj.push(e.target.files)
      // const duration = await getVideoDuration(e.target.files[0])
      const duration = null
      setDurationValue(duration)
      for (let i = 0; i < fileObj[0].length; i++) {
        fileArray.push(fileObj[0][i])
      }
      setSelectedImage({ file: fileArray })
      if (handleInputChange) {
        handleInputChange(e)
      }
      const file = uploadRef.current.files[0]
      setIsProcess(false)
      // const fileReader = new FileReader()
      // fileReader.onload = function () {
      //   if (fileReader.result) {
      //     setIsProcess(false)
      //   }
      // }
      // fileReader.readAsDataURL(file)
      const formData = new FormData()
      formData.append("video", file)
    }
  }

  return (
    <>
      <div className='only-positon-new'>
        <div className='category-input-feild'>
          <div className='category-input-feild-inner'>
            <label>{`${title ? title : "Upload Video"}`}</label>
            <input
              multiple={multiple}
              name={name}
              type='file'
              accept={"video/*"}
              className='upload-video'
              placeholder='Image'
              ref={uploadRef || toastIdRef}
              onChange={onChangeVideo}
            />
          </div>
        </div>
        <div className='upload-video-div-design'>
          <div className='upload-image'>
            <img src='/images/video-icon.png' alt='' />
          </div>
          <div className='click-or-drag-to-upload-title'>
            <p>Click or drag file to this area to upload</p>
          </div>
          <div className='bulk-upload-title'>
            <p>Support for a single or bulk upload.</p>
          </div>
          <div className='text-danger fw-bold'>
            {isProcess ? "Loading..." : ""}{" "}
          </div>
        </div>
      </div>
    </>
  )
}

export default MultipleUploadVideo
