import React from "react"
import { useMutation, useQuery } from "@apollo/client"
import { DELETE_CATEGORY, FETCH_CATEGORY } from "./queries"
import {
  errorMessageShow,
  groupBy,
  successMessageShow,
} from "../common/Function/commonFunction"
import { categoryProps } from "../../config/commonType"

const Categories = () => {
  const { loading, error, data } = useQuery(FETCH_CATEGORY)
  const [deleteCategory] = useMutation(DELETE_CATEGORY)

  const categoryArray = groupBy(data?.category_master, "category_type")

  if (loading) {
    return <div className='loading_message'>Loading...</div>
  }
  if (error) {
    return <div className='loading_message'>Error!</div>
  }

  // onClick delete catering function
  const onClickDeleteCatering = (category_id: any) => {
    deleteCategory({
      variables: { category_id },
      refetchQueries: [{ query: FETCH_CATEGORY }],
      onCompleted: (data) => {
        if (data.delete_category_master.__typename) {
          return successMessageShow("Category delete successfully!")
        }
      },
      onError: (error) => {
        if (error.message) {
          return errorMessageShow(error)
        }
      },
    })
  }

  return (
    <>
      <div className='category-list'>
        <div className='add-category-details'>
          <div className='add-category-and-close-button'>
            <div className='add-category-title'>
              <h1>CATEGORIES</h1>
            </div>
          </div>
          <div className='category-list-details'>
            <div className='row'>
              {categoryArray?.course &&
                categoryArray.course.map(
                  (item: categoryProps, index: React.Key) => {
                    return (
                      <div
                        className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3'
                        key={index}
                      >
                        <div className='category-list-wise-button'>
                          <button type='button'>
                            {item.category_name}
                            <img
                              src='/images/close-icon.svg'
                              alt='#'
                              onClick={() =>
                                onClickDeleteCatering(item.category_id)
                              }
                            />
                          </button>
                        </div>
                      </div>
                    )
                  },
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Categories
