import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import {
  errorMessage,
  successMessageShow,
} from "../../components/common/Function/commonFunction"
import apiConfig from "../../config/apiConfig"
import { useFormik } from "formik"
import { validationLogin } from "../../components/common/Function/validationFunction"

const Login = () => {
  let res: any
  const navigate = useNavigate()
  const [passwordShown, setPasswordShown] = useState(false)
  const [error, setError] = useState("")
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    const authToken = localStorage.getItem("token")
    if (authToken) {
      window.location.href = "/dashboard"
    }
  }, [])

  const initialValues = {
    email: "",
    password: "",
    mobile: "",
    code: "+91",
    is_verify: "",
  }

  //on login
  function onClickLogin(values: any) {
    setDisabled(true)
    apiConfig
      .post("admin/login", values)
      .then((response) => {
        res = response.data.message
        if (response.data.success === true) {
          localStorage.setItem("token", response.headers.auth)
          successMessageShow(res).then((r) => console.log(r))
          window.location.href = "/dashboard"
          setDisabled(false)
        } else {
          errorMessage(res).then((r) => console.log(r))
          setDisabled(false)
        }
      })
      .catch((error) => {
        if (error.message) {
          setError(error.message)
          setDisabled(false)
        }
        if (error.response.data.message) {
          setError(error.response.data.message)
          setDisabled(false)
        }
      })
  }

  // const onRedirectRegister = () => {
  //   navigate("/register")
  // }
  const onRedirectForgot = () => {
    navigate("/forgot-password")
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationLogin,
    onSubmit: (values) => {
      onClickLogin(values)
    },
  })

  return (
    <>
      <section className='login'>
        <div className='row m-0'>
          <div className='col-sm-12 col-md-7 col-lg-7 col-12 p-0'>
            <div className='login-image'>
              <img src='/images/login.png' alt={""} />
            </div>
          </div>
          {/* ---------------Login page-------- */}

          <div className='col-sm-12 col-md-5 col-lg-5 col-12 p-0'>
            <div className='login-details'>
              <div className='login-details-inner'>
                <div className='login-logo'>
                  <img src='/images/logo.svg' alt={""} />
                </div>
                <div className='login-title'>
                  <h1>Welcome Back!</h1>
                  <p>
                    Please sign in your account to get full access our website
                  </p>
                </div>
                <div className='login-form'>
                  <form onSubmit={formik.handleSubmit}>
                    <Form.Group className='form-group'>
                      <Form.Label>Email or Phone number</Form.Label>
                      <Form.Control
                        type={"text"}
                        placeholder={"Input your email here"}
                        name='email'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      <span className={"text-danger"}>
                        {formik.errors.email && formik.touched.email
                          ? formik.errors.email
                          : ""}
                      </span>
                    </Form.Group>
                    <Form.Group className='form-group mb-0'>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type={passwordShown ? "text" : "password"}
                        placeholder='Type your password here'
                        name='password'
                        onChange={formik.handleChange}
                        value={formik.values.password}
                      />
                      <button
                        className={"eyes-button"}
                        type='button'
                        onClick={() => setPasswordShown(!passwordShown)}
                      >
                        {passwordShown === true ? (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            fill='currentColor'
                            className='bi bi-eye'
                            viewBox='0 0 16 16'
                          >
                            <path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z' />
                            <path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z' />
                          </svg>
                        ) : (
                          <img src='/images/eye.svg' alt={""} />
                        )}
                      </button>
                      <span className={"text-danger"}>
                        {formik.errors.password && formik.touched.password
                          ? formik.errors.password
                          : ""}
                      </span>
                    </Form.Group>
                    <div className='forgot-pass'>
                      <Form.Check type='checkbox' label={`Remember me`} />
                      <button type='button' onClick={onRedirectForgot}>
                        Forgot password?
                      </button>
                    </div>
                    <div className='login-button'>
                      <span className={"text-danger"}>
                        {error ? error : ""}
                      </span>
                      <button type='submit' disabled={disabled}>
                        Login
                      </button>
                    </div>
                    {/*<div className='create-account'>*/}
                    {/*  <p>Not registered yet?</p>*/}
                    {/*  <button type='button' onClick={onRedirectRegister}>*/}
                    {/*    Create an Account*/}
                    {/*  </button>*/}
                    {/*</div>*/}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Login
