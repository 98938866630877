import ShowBannerList from "./show-banner-list"
import TabTitle from "../../pages/tab-title/TabTitle"
import React from "react"

const Bannerlist = () => {
  return (
    <>
      <TabTitle title={"Banners"} />
      <section className='add-recipes'>
        <div className='common-page-padding'>
          <div className='row'>
            {/*<!------ add BannerList section start -----!>*/}
            <ShowBannerList />
          </div>
        </div>
      </section>
    </>
  )
}

export default Bannerlist
