import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import store from "../../store"
import { Dropdown, DropdownButton } from "react-bootstrap"

const Header = () => {
  const [headers, setHeaders] = useState<any>({
    title: "Add Categories",
    mainLink: "Courses",
    middleLink: "",
    subLink: "Add Categories",
    pathName: "/add-category",
  })
  const headerReducer = useSelector(
    (state: ReturnType<typeof store.getState>) =>
      state.uiReducers.headerDetails,
  )
  const storeReducer: any = useSelector(
    (state: ReturnType<typeof store.getState>) => state?.userDetails?.user,
  )
  useEffect(() => {
    setHeaders(headerReducer)
  }, [headerReducer])

  const onClickLogout = () => {
    localStorage.removeItem("token")
    window.location.href = "/"
  }

  return (
    <>
      <header>
        <section className='admin-header'>
          <div className='admin-title'>
            <DropdownButton
              title={storeReducer?.full_name}
              className='logout-button'
            >
              <Dropdown.Item eventKey='1' onClick={onClickLogout}>
                <svg
                  width='18'
                  height='18'
                  viewBox='0 0 200 200'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M169.525 142.946H155.794C154.857 142.946 153.978 143.356 153.392 144.078C152.025 145.739 150.56 147.34 149.017 148.864C142.706 155.181 135.231 160.215 127.005 163.688C118.483 167.287 109.323 169.134 100.072 169.117C90.7163 169.117 81.6538 167.282 73.1381 163.688C64.9121 160.215 57.4371 155.181 51.1264 148.864C44.8044 142.568 39.7635 135.106 36.2827 126.891C32.6694 118.375 30.853 109.332 30.853 99.9769C30.853 90.6214 32.6889 81.5784 36.2827 73.0628C39.7592 64.8401 44.7592 57.4378 51.1264 51.0901C57.4936 44.7425 64.8959 39.7425 73.1381 36.2659C81.6538 32.6722 90.7163 30.8362 100.072 30.8362C109.427 30.8362 118.49 32.6526 127.005 36.2659C135.248 39.7425 142.65 44.7425 149.017 51.0901C150.56 52.6331 152.005 54.2347 153.392 55.8753C153.978 56.598 154.876 57.0081 155.794 57.0081H169.525C170.755 57.0081 171.517 55.6409 170.833 54.6058C155.853 31.3245 129.642 15.9144 99.8569 15.9925C53.06 16.1097 15.5405 54.098 16.0092 100.836C16.478 146.832 53.9389 183.961 100.072 183.961C129.779 183.961 155.873 168.571 170.833 145.348C171.498 144.313 170.755 142.946 169.525 142.946ZM186.888 98.7464L159.173 76.8714C158.138 76.0511 156.634 76.7933 156.634 78.1019V92.9456H95.3061C94.4467 92.9456 93.7436 93.6487 93.7436 94.5081V105.446C93.7436 106.305 94.4467 107.008 95.3061 107.008H156.634V121.852C156.634 123.16 158.158 123.903 159.173 123.082L186.888 101.207C187.075 101.061 187.226 100.874 187.33 100.661C187.434 100.448 187.488 100.214 187.488 99.9769C187.488 99.7397 187.434 99.5057 187.33 99.2925C187.226 99.0793 187.075 98.8926 186.888 98.7464V98.7464Z'
                    fill='#1D1D1D'
                  />
                </svg>
                {/*<img src={"/images/logout.svg"} alt={""} /> */}
                Logout
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <div className='courses-list-section'>
            <div className='courses-list-title'>
              {headers.mainLink && <p>{headers.mainLink}</p>}
              {headers.mainLink && <p>/ </p>}
              <span>{headers.subLink}</span>
            </div>

            <div className='courses-list-title-two'>
              <p>{headers.title}</p>
            </div>
          </div>
        </section>
      </header>
    </>
  )
}
export default Header
