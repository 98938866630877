import React, { useState } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import "./App.css"
import Common from "./pages/common-sidebar"
import "./styles/base/base.scss"
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
} from "@apollo/client"
import Login from "./pages/authLogin/login"
// import Register from "./pages/authLogin/register"
import ForgotPassword from "./pages/authLogin/forgot-password"
import VerifyOtp from "./pages/authLogin/verify-otp"
import { urls } from "./constants"
const authToken = localStorage.getItem("token")

const createApolloClient = () => {
  return new ApolloClient({
    link: new HttpLink({
      uri: urls.api_url,
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    }),
    cache: new InMemoryCache(),
  })
}

export const App = () => {
  const [client] = useState(createApolloClient())

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        {window.location.pathname === "/" ||
        // window.location.pathname === "/register" ||
        window.location.pathname === "/verify-otp" ||
        window.location.pathname === "/forgot-password" ? (
          <Routes>
            <Route path='/' element={<Login />} />
            {/*<Route path='/register' element={<Register />} />*/}
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/verify-otp' element={<VerifyOtp />} />
          </Routes>
        ) : (
          <Common />
        )}
      </BrowserRouter>
    </ApolloProvider>
  )
}

export default App
