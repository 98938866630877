import ResetDoneButton from "../common/Buttons/resetDoneButton"
import Inputs from "../common/Inputs/InputField"
import UploadImage from "../common/UploadImage/uploadImage"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useMutation } from "@apollo/client"
import React, { useEffect, useState } from "react"
import { MakeCateringStore } from "../../store/coursestore/courseSlice"
import {
  errorMessageShow,
  resetMessageShow,
  successMessageShow,
  uploadImageId,
  uploadImageThumbnailId,
  uploadVideoCatering,
  uploadVideoClient,
} from "../common/Function/commonFunction"
import {
  FETCH_CATERING,
  ADD_CATERING,
  UPDATE_CATERING,
  uploadMedia,
  updateUploadMedia,
} from "./queries"
import UploadVideo from "../common/uploadVideo/uploadVideo"
import { useFormik } from "formik"
import { validationCatering } from "../common/Function/validationFunction"
import Titles from "../common/Title/Title"
import RichTextEditor, { EditorValue } from "react-rte"
import RichTextEditors from "../common/Inputs/RichTextEditors"
import {
  prepareFormCateringVideo,
  prepareFormClientImage,
  prepareFormDataCatering,
  prepareFormVideoCatering,
} from "../common/Function/prepareFormData"
import MultipleUploadVideo from "../common/uploadVideo/multipleVideo"

const AddCateringForm = (props: { singleArray?: any }) => {
  const { singleArray } = props
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const [addCatering] = useMutation(ADD_CATERING)
  const [updateCatering] = useMutation(UPDATE_CATERING)

  const uploadThumbnailImage = uploadImageThumbnailId(singleArray)
  const uploadImage = uploadImageId(singleArray)
  const uploadVideosCatering = uploadVideoCatering(singleArray)
  const uploadVideosClient = uploadVideoClient(singleArray)

  let newArray: any
  newArray =
    singleArray !== undefined && singleArray.length ? singleArray[0] : ""

  const [selectedImage, setSelectedImage] = useState<any>()
  const [cateringVideo, setCateringVideo] = useState<any>()
  const [uploadVideo, setUploadVideo] = useState<any>()
  const [clientImage, setClientImage] = useState<any>()
  const [media_id, setMedia_id] = useState("")
  const [media_id1, setMedia_id1] = useState("")
  const [media_id2, setMedia_id2] = useState("")
  const [isProcess, setIsProcess] = useState<any>(false)
  const [durationValue, setDurationValue] = useState<any>(null)
  const [durationUploadVideo, setDurationUploadVideo] = useState<any>(null)
  const [disabled, setDisabled] = useState(false)

  const thumbnail =
    uploadThumbnailImage &&
    uploadThumbnailImage[0] &&
    uploadThumbnailImage[0].media_fk

  const initialValues = {
    catering_id: newArray ? newArray?.catering_id : "",
    catering_name: newArray ? newArray?.catering_name : "",
    catering_desc: newArray ? newArray?.catering_desc : "",
    selectedImage: uploadThumbnailImage ? thumbnail?.media_desc : "",
  }

  useEffect(() => {
    if (newArray) {
      editAllField()
    }
  }, [newArray])

  useEffect(() => {
    const data = {
      selectedImage: formik.values.selectedImage,
      editImage: thumbnail?.media_url,
      cateringVideo:
        cateringVideo === undefined ? uploadVideosCatering[0] : cateringVideo,
      clientImage: clientImage === undefined ? uploadImage[0] : clientImage,
      uploadVideo: uploadVideo === undefined ? uploadVideosClient : uploadVideo,
      durationValue:
        cateringVideo === undefined
          ? uploadVideosCatering[0] &&
            uploadVideosCatering[0]?.media_fk?.media_duration
          : durationValue,
      durationUploadVideo: uploadVideo === undefined ? 0 : durationUploadVideo,
    }
    dispatch(MakeCateringStore(data))
  }, [selectedImage, newArray, initialValues])

  // empty field function
  const EmptyAllField = () => {
    setSelectedImage(undefined)
    setCateringVideo(undefined)
    setUploadVideo(undefined)
    setClientImage(undefined)
  }

  const editAllField = () => {
    setMedia_id(thumbnail?.media_id)
    setMedia_id1(uploadImage && uploadImage[0] && uploadImage[0].media_id)
    setMedia_id2(
      uploadVideosCatering &&
        uploadVideosCatering[0] &&
        uploadVideosCatering[0].media_id,
    )
    setClientImage(newArray.clientImage)
  }

  const addCaterings = (values: any) => {
    setIsProcess(true)
    addCatering({
      variables: values,
      refetchQueries: [{ query: FETCH_CATERING }],
      onError: (error) => {
        if (error.message) {
          return errorMessageShow(error)
        }
      },
      onCompleted: (data) => {
        if (data.insert_caterings.__typename) {
          setIsProcess(false)
          const catering_id = data.insert_caterings.returning[0].catering_id
          if (formik.values.selectedImage !== "") {
            uploadMedia(
              prepareFormDataCatering(formik, catering_id, ""),
              navigate,
            )
          }
          if (clientImage !== undefined) {
            uploadMedia(prepareFormClientImage(clientImage, catering_id, ""))
          }
          if (cateringVideo !== undefined) {
            uploadMedia(
              prepareFormCateringVideo(
                cateringVideo,
                catering_id,
                "",
                durationValue,
              ),
            )
          }
          if (catering_id !== "" && uploadVideo !== undefined) {
            uploadVideo &&
              uploadVideo.file.map((url: any) => {
                return uploadMedia(
                  prepareFormVideoCatering(
                    url,
                    catering_id,
                    "",
                    durationUploadVideo,
                  ),
                )
              })
          }
          EmptyAllField()
          return successMessageShow("Catering add successfully!")
        }
      },
    }).then((r) => console.log(r))
  }

  const editCatering = (values: any) => {
    setIsProcess(true)
    updateCatering({
      variables: values,
      refetchQueries: [{ query: FETCH_CATERING }],
      onError: (error) => {
        if (error.message) {
          return errorMessageShow(error)
        }
      },
      onCompleted: (data) => {
        if (data.update_caterings.__typename) {
          setIsProcess(false)
          const catering_id = data.update_caterings.returning[0].catering_id
          if (
            media_id !== "" &&
            formik.values.selectedImage !== "Thumbnail image" &&
            media_id !== undefined &&
            formik.values.selectedImage !== ""
          ) {
            updateUploadMedia(
              media_id,
              prepareFormDataCatering(formik, catering_id, media_id),
              navigate,
            )
          } else {
            if (
              formik.values.selectedImage !== "" &&
              formik.values.selectedImage !== "Thumbnail image"
            ) {
              uploadMedia(prepareFormDataCatering(formik, catering_id, ""))
            }
            navigate(`/catering-list`)
          }
          if (
            media_id1 !== "" &&
            media_id1 !== undefined &&
            clientImage !== undefined
          ) {
            updateUploadMedia(
              media_id1,
              prepareFormClientImage(clientImage, catering_id, media_id1),
              navigate,
            )
          } else {
            if (clientImage !== undefined) {
              uploadMedia(
                prepareFormClientImage(clientImage, catering_id, ""),
                navigate,
              )
            }
          }
          if (
            media_id2 !== "" &&
            media_id2 !== undefined &&
            cateringVideo !== undefined
          ) {
            updateUploadMedia(
              media_id2,
              prepareFormCateringVideo(
                cateringVideo,
                catering_id,
                media_id2,
                durationValue,
              ),
            )
          } else {
            if (cateringVideo !== undefined) {
              uploadMedia(
                prepareFormCateringVideo(
                  cateringVideo,
                  catering_id,
                  "",
                  durationValue,
                ),
              )
            }
          }
          if (uploadVideo !== undefined) {
            uploadVideo?.file &&
              uploadVideo?.file.map((url: any, ids: any) => {
                if (uploadVideosClient) {
                  uploadVideosClient &&
                    uploadVideosClient.map((id: any, index: React.Key) => {
                      if (ids === index) {
                        return updateUploadMedia(
                          id?.media_id,
                          prepareFormVideoCatering(
                            url,
                            id?.catering_id,
                            id?.media_id,
                            durationUploadVideo,
                          ),
                        )
                      }
                    })
                } else {
                  return uploadMedia(
                    prepareFormVideoCatering(
                      url,
                      catering_id,
                      url.media_id,
                      durationUploadVideo,
                    ),
                  )
                }
              })
          }
          return successMessageShow("Catering edit successfully!")
        }
      },
    })
  }

  //onClick save button
  const submitDoneButton = (values: any) => {
    if (newArray !== "") {
      setDisabled(true)
      editCatering(values)
    } else {
      setDisabled(true)
      addCaterings(values)
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationCatering,
    onSubmit: (values) => {
      submitDoneButton(values)
    },
  })

  const [mdeState, setMdeState] = useState(
    formik.values.catering_desc
      ? RichTextEditor.createValueFromString(
          formik.values.catering_desc,
          "html",
        )
      : RichTextEditor.createEmptyValue(),
  )

  const handleMdeChange = (value: React.SetStateAction<EditorValue>) => {
    formik.setFieldValue("catering_desc", value.toString("html"))
    setMdeState(value)
  }
  let fileObj: never[] = []
  let fileArray: never[] = []

  return (
    <>
      <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-5'>
        <form onSubmit={formik.handleSubmit}>
          <div className='add-category-details'>
            <Titles
              label={`${newArray !== "" ? "EDIT" : "ADD"} CATERING VIDEO`}
            />
            <div className='only-padding'>
              <Inputs
                MaxLength={30}
                label={"Service Name"}
                type={"text"}
                placeHolder={"Enter Service Name"}
                name='catering_name'
                onChange={formik.handleChange}
                value={formik.values.catering_name}
                errors={formik.errors.catering_name}
                touched={formik.touched.catering_name}
              />

              {/*<!------- upload image section start-----!>*/}
              <UploadImage
                selectedImage={formik.values.selectedImage}
                title={"Upload Image (Thumbnail)"}
                setSelectedImage={(selectedImage: any) => {
                  formik.setFieldValue("selectedImage", selectedImage)
                }}
                errors={formik.errors.selectedImage}
                touched={formik.touched.selectedImage}
                isEdit={formik.values.selectedImage !== "" ? true : false}
              />

              {/*<!------- upload video section start-----!>*/}
              <UploadVideo
                selectedImage={cateringVideo}
                title={"Upload Catering Video"}
                setSelectedImage={setCateringVideo}
                setDurationValue={setDurationValue}
              />

              <RichTextEditors
                mdeState={mdeState}
                onChange={handleMdeChange}
                errors={formik.errors.catering_desc}
                touched={formik.touched.catering_desc}
                label={"Testimonials"}
                placeholder={"Enter Testimonials Details"}
              />

              {/*<!------- upload video section start-----!>*/}
              <MultipleUploadVideo
                multiple={true}
                fileArray={fileArray}
                fileObj={fileObj}
                selectedImage={uploadVideo}
                title={"Upload Video (Client)"}
                setSelectedImage={setUploadVideo}
                setDurationValue={setDurationUploadVideo}
              />

              {/*<!------- upload image section start-----!>*/}
              <UploadImage
                selectedImage={clientImage}
                title={"Upload images for (Client)"}
                setSelectedImage={setClientImage}
                isEdit={uploadImage[0] !== "" ? true : false}
              />

              {/*<!------- button section start-----!>*/}
              <div className='button-div'>
                <ResetDoneButton
                  disableFirst={!formik.dirty}
                  disable={disabled}
                  isProcess={isProcess}
                  firstButton={"Reset"}
                  secondButton={"Save"}
                  onClickFirst={() => {
                    formik.resetForm()
                    setMdeState(
                      RichTextEditor.createValueFromString("", "html"),
                    )
                    resetMessageShow().then((r) => console.log(r))
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddCateringForm
