import React from "react"
import { useSelector } from "react-redux"
import store from "../../store"
import Titles from "../common/Title/Title"

const ProductPrev = () => {
  const storeReducer = useSelector(
    (state: ReturnType<typeof store.getState>) =>
      state.courseReducers.amazonProduct,
  )

  return (
    <>
      <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6'>
        <div className='add-category-details'>
          <Titles label={`PRODUCT`} />
          <div className='recipe-uploaded-video-details'>
            <div className='recipe-uploaded-video-image'>
              {storeReducer.selectedImage?.file !== undefined ? (
                <img
                  src={URL.createObjectURL(storeReducer.selectedImage.file[0])}
                  alt='Thumb'
                />
              ) : storeReducer?.editImage ? (
                <img src={storeReducer?.editImage} alt='' />
              ) : (
                <img src='/images/Chyavanaprash.png' alt='' />
              )}
            </div>
            <div className='uploaded-video-title'>
              <p>{storeReducer.product_name}</p>
            </div>
            <div className='uploaded-video-discription'>
              <p>{storeReducer.product_desc}</p>
            </div>
            <div className='online-preview-lable-and-name'>
              <p>
                {storeReducer.discount_price
                  ? "₹ " + storeReducer.discount_price
                  : ""}
                <span>
                  {storeReducer.product_price
                    ? "₹ " + storeReducer.product_price
                    : ""}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductPrev
