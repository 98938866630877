import { Modal } from "react-bootstrap"
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactPlayer from "react-player"

const VideoModal = ({ type, show, handleClose, videoUrl, titleName }: any) => {
  return (
    <>
      <Modal show={show} size='lg' onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{titleName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {type === "paid" ? (
            <ReactPlayer
              className='camera_video_discover'
              url={videoUrl}
              width='100%'
              // height='686px'
              height='500px'
              controls={true}
              playing
              config={{
                file: {
                  attributes: { controlsList: "nodownload" },
                  forceHLS: true,
                  hlsOptions: {
                    xhrSetup: (xhr: any) => {
                      const authToken = localStorage.getItem("token")
                      xhr.setRequestHeader("Authorization", `${authToken}`)
                    },
                  },
                },
              }}
            />
          ) : (
            <ReactPlayer
              className='camera_video_discover'
              url={videoUrl}
              width='100%'
              // height='686px'
              height='500px'
              controls={true}
              playing
            />
          )}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  )
}

export default VideoModal
