import React from "react"
import EditDeleteButton from "../Buttons/editDeleteButton"
import { OfflineCardProps } from "../../../config/commonType"

const SingleOfflineCard = (props: OfflineCardProps) => {
  const { item, onClick, course_id, editClick, image, isProcess, isIds } = props

  return (
    <>
      <div className='col-12 col-sm-12 col-md-4 col-lg-4'>
        <div className='offline-class-list-details'>
          <div className='offline-class-image'>
            {image ? (
              <img src={image} alt='' />
            ) : (
              <img src='/images/offline-class-img.png' alt='' />
            )}
          </div>
          <div className='offline-class-list-details-inner'>
            <div className='offline-class-title'>
              <h1> {item?.course_name ? item.course_name : ""}</h1>
            </div>
            <div className='offline-course-title-discription'>
              <p className={"scroll-list"}>
                {item?.course_details ? item?.course_details : ""}
              </p>
            </div>
            <div className='discounted-and-original-price'>
              <p>
                {item?.course_discount_amount
                  ? " ₹ " + item?.course_discount_amount
                  : ""}
                <span>
                  {item?.course_amount ? " ₹ " + item?.course_amount : ""}
                </span>
              </p>
            </div>
          </div>
          <EditDeleteButton
            matchId={isIds}
            isProcess={isProcess}
            onClickDelete={onClick}
            course_id={course_id}
            editClick={editClick}
          />
        </div>
      </div>
    </>
  )
}

export default SingleOfflineCard
