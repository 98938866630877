import React from "react"
import UserActivePage from "../../components/user-lists/user-active"

const UserActive = () => {
  return (
    <>
      <UserActivePage />
    </>
  )
}
export default UserActive
