import React, { useState, useEffect } from "react"
import { successMessageShow } from "../../components/common/Function/commonFunction"
import apiConfig from "../../config/apiConfig"

const VerifyOtp = () => {
  let res: any
  const mobile_number = localStorage.getItem("mobile_number")

  const [otp1, setOtp1] = useState<any>("")
  const [otp2, setOtp2] = useState<any>("")
  const [otp3, setOtp3] = useState<any>("")
  const [otp4, setOtp4] = useState<any>("")
  const [otp5, setOtp5] = useState<any>("")
  const [otp6, setOtp6] = useState<any>("")
  const [minutes, setMinutes] = useState(1)
  const [seconds, setSeconds] = useState(30)
  const [error, setError] = useState("")
  let code: any = "+91"
  let otp: any = otp1 + otp2 + otp3 + otp4 + otp5 + otp6

  //handle validation function
  const handleValidation = () => {
    let isValid = false
    if (!otp) {
      setError("Please enter otp!")
    } else {
      isValid = true
    }
    return isValid
  }

  //verify otp api function
  function onClickVerifyOpt() {
    const valid = handleValidation()
    if (valid) {
      apiConfig
        .get(`verifyOtp/${code}/${mobile_number}/${otp}`)
        .then((response) => {
          res = response.data.message
          successMessageShow(res).then((r) => console.log(r))
          window.location.href = "/"
        })
        .catch((error) => {
          setError(error.response.data.message)
        })
    }
  }

  let digitValidate: any = function (element: any) {
    const ele = { ...element }
    ele.value = ele.value.replace(/[^0-9]/g, "")
  }

  let tabChange = function (val: number) {
    let ele = document.querySelectorAll("input")
    if (ele[val - 1].value !== "") {
      ele[val].focus()
    } else if (ele[val - 1].value === "") {
      ele[val - 2].focus()
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval)
        } else {
          setSeconds(59)
          setMinutes(minutes - 1)
        }
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [seconds])

  // resend otp function
  const resendOTP = () => {
    setMinutes(1)
    setSeconds(30)
  }
  return (
    <>
      <section className='login'>
        <div className='row m-0'>
          <div className='col-sm-12 col-md-7 col-lg-7 col-12 p-0'>
            <div className='login-image'>
              <img src='/images/login.png' alt={""} />
            </div>
          </div>
          <div className='col-sm-12 col-md-5 col-lg-5 col-12 p-0'>
            <div className='login-details'>
              <div className='login-details-inner'>
                <div className='login-logo'>
                  <img src='/images/logo.svg' alt={""} />
                </div>
                <div className='login-title'>
                  <h1>Verify</h1>
                  <p>
                    We sent a verification code to
                    <br />
                    +91 {mobile_number}
                  </p>
                </div>
                <div className='login-form'>
                  <form className='verify-otp'>
                    <input
                      className='otp'
                      type='text'
                      min={1}
                      maxLength={1}
                      value={otp1}
                      onChange={({ target }) => {
                        setOtp1(target.value.replace(/[^0-9]/g, ""))
                      }}
                      onInput={(e) => digitValidate(e.target)}
                      onKeyUp={() => tabChange(1)}
                    />
                    <input
                      className='otp'
                      type='text'
                      maxLength={1}
                      value={otp2}
                      onChange={({ target }) => {
                        setOtp2(target.value.replace(/[^0-9]/g, ""))
                      }}
                      onInput={(e) => digitValidate(e.target)}
                      onKeyUp={() => tabChange(2)}
                    />
                    <input
                      className='otp'
                      type='text'
                      maxLength={1}
                      value={otp3}
                      onChange={({ target }) => {
                        setOtp3(target.value.replace(/[^0-9]/g, ""))
                      }}
                      onInput={(e) => digitValidate(e.target)}
                      onKeyUp={() => tabChange(3)}
                    />
                    <input
                      className='otp'
                      type='text'
                      maxLength={1}
                      value={otp4}
                      onChange={({ target }) => {
                        setOtp4(target.value.replace(/[^0-9]/g, ""))
                      }}
                      onInput={(e) => digitValidate(e.target)}
                      onKeyUp={() => tabChange(4)}
                    />
                    <input
                      className='otp'
                      type='text'
                      maxLength={1}
                      value={otp5}
                      onChange={({ target }) => {
                        setOtp5(target.value.replace(/[^0-9]/g, ""))
                      }}
                      onInput={(e) => digitValidate(e.target)}
                      onKeyUp={() => tabChange(5)}
                    />
                    <input
                      className='otp'
                      type='text'
                      maxLength={1}
                      value={otp6}
                      onChange={({ target }) => {
                        setOtp6(target.value.replace(/[^0-9]/g, ""))
                      }}
                      onInput={(e) => digitValidate(e.target)}
                      onKeyUp={() => tabChange(6)}
                    />
                  </form>
                  <div className='verify-code'>
                    {seconds > 0 || minutes > 0 ? (
                      <p>
                        The verify code will be expire in :{" "}
                        <span>
                          {minutes < 10 ? `0${minutes}` : minutes}:
                          {seconds < 10 ? `0${seconds}` : seconds}
                        </span>
                      </p>
                    ) : (
                      <p>Didn't recieve code?</p>
                    )}
                  </div>
                  <div className='resend-button'>
                    <button
                      type='button'
                      disabled={seconds > 0 || minutes > 0}
                      style={{
                        color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "",
                      }}
                      onClick={resendOTP}
                    >
                      <img src='/images/resend.svg' alt={""} />
                      Resend code{" "}
                    </button>
                  </div>
                  <div className='login-button'>
                    <span className='text-danger'>{error ? error : ""}</span>
                    <button type='button' onClick={onClickVerifyOpt}>
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default VerifyOtp
