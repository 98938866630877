import React from "react"
import OfflineCourseList from "../../components/offlineCourseList/offlineCourseList"

const OfflinClassesLits = () => {
  return (
    <>
      <OfflineCourseList />
    </>
  )
}

export default OfflinClassesLits
