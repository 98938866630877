import React from "react"
import Recipes from "./recipe"
import AddRecipeForm from "./addRecipeForm"
import TabTitle from "../../pages/tab-title/TabTitle"

const AddRecipes = (props: { singleArray?: any }) => {
  const { singleArray } = props
  return (
    <>
      <TabTitle title={"Add Recipes"} />
      <section className='add-recipes'>
        <div className='common-page-padding'>
          <div className='row'>
            {/*<!-----add Recipe section start-----!>*/}
            <AddRecipeForm singleArray={singleArray} />

            {/*<!-----Recipe add section start-----!>*/}
            <Recipes />
          </div>
        </div>
      </section>
    </>
  )
}

export default AddRecipes
