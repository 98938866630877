// validation function
import * as Yup from "yup"

export const validationSchemaCategory = Yup.object().shape({
  category_name: Yup.string()
    .max(30, "Category name must be at most 30 characters")
    .required("Please enter category name!")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
})

export const validationSchema = Yup.object().shape({
  course_name: Yup.string()
    .max(30, "Course name must be at most 30 characters")
    .required("Please enter product name!")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  course_details: Yup.string()
    .max(200, "Details must be at most 200 characters")
    .required("Please enter course details!")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  category_id: Yup.number().required("Please select category!"),
  course_amount: Yup.number().required("Please enter price!"),
  course_discount_amount: Yup.number()
    .required("Please enter discount price!")
    .when(
      "course_amount",
      (course_amount, schema) =>
        course_amount &&
        schema.test(
          "course_discount_amount",
          "Please enter lower amount than Price!",
          (value: any) => {
            return course_amount[0] > value // condition
          },
        ),
    ),
  selectedImage: Yup.string().required("Please select image!"),
})

export const validationOnline = Yup.object().shape({
  course_name: Yup.string()
    .max(30, "Course name must be at most 30 characters")
    .required("Please enter course name!")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  category_id: Yup.number().required("Please select category!"),
  course_details: Yup.string()
    .max(200, "Details must be at most 200 characters")
    .required("Please enter course details!")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  course_desc: Yup.string()
    .required("Please enter course description!")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  course_content: Yup.string()
    .required("Please enter course content!")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  course_usage_steps: Yup.string()
    .required("Please enter how to use!")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  course_amount: Yup.number()
    .required("Please enter price!")
    .positive("Must be a positive number."),
  course_discount_amount: Yup.number()
    .required("Please enter discount price!")
    .positive("Must be a positive number.")
    .when(
      "course_amount",
      (course_amount, schema) =>
        course_amount &&
        schema.test(
          "course_discount_amount",
          "Please enter lower amount than Price!",
          (value: any) => {
            return course_amount[0] > value // condition
          },
        ),
    ),
  preparation_time: Yup.number().required("Please enter prep time!"),
  cook_time: Yup.number().required("Please enter cook time!"),
  total_time: Yup.number().required("Please enter total time!"),
  course_type: Yup.string()
    .matches(/^[A-Za-z]*$/, {
      message: "Please enter only alphabet!",
      excludeEmptyString: false,
    })
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces")
    .required("Please enter course!"),
  cuisine: Yup.string()
    .matches(/^[A-Za-z]*$/, {
      message: "Please enter only alphabet!",
      excludeEmptyString: false,
    })
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces")
    .required("Please enter cuisine!"),
  serving: Yup.string()
    .required("Please enter serving!")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  selectedImage: Yup.string().required("Please select image!"),
  selectPdf: Yup.string().required("Please select pdf!"),
})

export const validationRecipe = Yup.object().shape({
  recipe_name: Yup.string()
    .max(30, "recipe name must be at most 30 characters!")
    .required("Please enter recipe name!")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  category_id: Yup.number().required("Please select category!"),
  recipe_details: Yup.string()
    .max(200, "details must be at most 200 characters!")
    .required("Please enter details!")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  recipe_desc: Yup.string()
    .required("Please enter description!")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  preparation_time: Yup.number().required("Please enter prep time!"),
  cook_time: Yup.number().required("Please enter cook time!"),
  total_time: Yup.number().required("Please enter total time!"),
  recipe_type: Yup.string()
    .matches(/^[A-Za-z ]*$/, {
      message: "Please enter only alphabet!",
      excludeEmptyString: false,
    })
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces")
    .required("Please enter course!"),
  cuisine: Yup.string()
    .matches(/^[A-Za-z ]*$/, {
      message: "Please enter only alphabet!",
      excludeEmptyString: false,
    })
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces")
    .required("Please enter cuisine!"),
  serving: Yup.string()
    .required("Please enter serving!")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  selectedImage: Yup.string().required("Please select image!"),
})

export const validationOurProduct = Yup.object().shape({
  product_name: Yup.string()
    .max(30, "Product name must be at most 30 characters!")
    .required("Please enter product name!")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  product_desc: Yup.string()
    .max(200, "Details must be at most 200 characters!")
    .required("Please enter details!")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  product_price: Yup.number().required("Please enter price!"),
  discount_price: Yup.number()
    .required("Please enter discount price!")
    .when(
      "product_price",
      (product_price, schema) =>
        product_price &&
        schema.test(
          "course_discount_amount",
          "Please enter lower amount than Price",
          (value: any) => {
            return product_price[0] > value // condition
          },
        ),
    ),
})

export const validationAmazon = Yup.object().shape({
  product_name: Yup.string()
    .max(30, "Product name must be at most 30 characters!")
    .required("Please enter product name!")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  product_link: Yup.string()
    .max(200, "Product link must be at most 200 characters!")
    .required("Please enter product link!")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  selectedImage: Yup.string().required("Please select image!"),
})

export const validationCatering = Yup.object().shape({
  catering_name: Yup.string()
    .max(30, "Service name must be at most 30 characters")
    .required("Please enter service name!")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  catering_desc: Yup.string()
    .required("Please enter testimonials details!")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  selectedImage: Yup.string().required("Please select image!"),
})

export const validationLogin = Yup.object().shape({
  email: Yup.string()
    .email("Please enter valid email")
    .required("Enter your email")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  password: Yup.string()
    .concat(Yup.string().required("Please enter password"))
    .min(6, "Password must be at least 6 characters")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
})

export const validationRegister = Yup.object().shape({
  name: Yup.string()
    .required("Enter your name")
    .matches(/^[A-Za-z ]*$/, {
      message: "Please enter valid name",
      excludeEmptyString: false,
    }),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Enter your email"),
  mobile_number: Yup.string()
    .matches(/^[0-9]\d{9}$/, {
      message: "Please enter valid number",
      excludeEmptyString: false,
    })
    .required("Enter your phone number"),
  password: Yup.string()
    .concat(Yup.string().required("Please enter password"))
    .min(6, "Password must be at least 6 characters"),
})
