import React, { useState } from "react"
import { toast } from "react-toastify"
import { UploadImageProps } from "../../../config/commonType"
import { ProgressBar } from "react-bootstrap"
import { urls } from "../../../constants"

const UploadPdf = (props: UploadImageProps) => {
  const { selectedImage, setSelectedImage, isEdit, errors, touched } = props

  const [titleFile, setTitleFile] = React.useState<any>(
    isEdit === true ? 100 : 0,
  )
  const [progress, setProgress] = useState<any>(isEdit === true ? 100 : 0)
  const uploadRef = React.useRef<any>(null)
  const statusRef = React.useRef<any>(null)
  const loadTotalRef = React.useRef<any>(null)
  const progressRef = React.useRef<any>(null)

  // This function will be triggered when the file field change
  const onClickImage = (event: any) => {
    const e = { ...event }
    if (e.target.files && e.target.files.length > 0) {
      const fileName = e.target.files[0].name
      const extension = fileName.split(".").pop()
      const isSupported = ["pdf"].includes(extension)
      if (!isSupported) {
        toast.success("Do not supported")
        e.target.value = null
        setSelectedImage(undefined)
      } else {
        setSelectedImage(e.target.files[0])
        toast.success("Pdf upload successfully!")
      }
      setProgress(0)
      const file = uploadRef.current.files[0]
      const fileReader = new FileReader()
      fileReader.onloadend = () => {}
      fileReader.readAsDataURL(file)
      const formData = new FormData()
      formData.append("pdf", file)
      const xhr = new XMLHttpRequest()
      xhr.upload.addEventListener("progress", ProgressHandler, false)
      // @ts-ignore
      xhr.addEventListener("load", SuccessHandler, false)
      xhr.addEventListener("error", ErrorHandler, false)
      xhr.addEventListener("abort", AbortHandler, false)
      xhr.open("POST", urls.api_url)
      xhr.send(formData)
    }
  }

  const ProgressHandler = (e: { loaded: number; total: number }) => {
    loadTotalRef.current.innerHTML = `uploaded ${e.loaded} bytes of ${e.total}`
    const percent = (e.loaded / e.total) * 100
    progressRef.current.value = Math.round(percent)
    setProgress(progressRef.current.value)
    statusRef.current.innerHTML = Math.round(percent)
    setTitleFile(statusRef.current.innerHTML)
  }

  const SuccessHandler = (e: { target: { responseText: any } }) => {
    statusRef.current.innerHTML = e.target.responseText
    progressRef.current.value = 0
  }
  const ErrorHandler = () => {
    statusRef.current.innerHTML = "upload failed!!"
  }
  const AbortHandler = () => {
    statusRef.current.innerHTML = "upload aborted!!"
  }

  return (
    <>
      <div className='only-positon-new'>
        <div className='category-input-feild'>
          <div className='category-input-feild-inner'>
            <label>{"Upload Pdf"}</label>
            <input
              type='file'
              name='file'
              ref={uploadRef}
              onChange={onClickImage}
              className='upload-video'
              accept={"application/pdf"}
            />
          </div>
        </div>
        <div className='upload-video-div-design'>
          <div className='upload-image'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='38'
              height='38'
              fill='currentColor'
              className='bi bi-filetype-pdf'
              viewBox='0 0 16 16'
              fontWeight={900}
            >
              <path
                fillRule='evenodd'
                d='M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z'
              />
            </svg>
          </div>
          <div className='click-or-drag-to-upload-title'>
            <p>Click or drag file to this area to upload</p>
          </div>

          <div className='bulk-upload-title'>
            <p>Support for a single or bulk upload.</p>
          </div>
          {selectedImage !== undefined && selectedImage !== "" && (
            <div className={"progress-bar-upload"}>
              <ProgressBar
                label={`${titleFile}%`}
                ref={progressRef}
                now={progress}
              />
            </div>
          )}
          <p className='d-none' ref={statusRef} />
          <p className='d-none' ref={loadTotalRef} />
        </div>
      </div>
      <span className='text-danger'>{errors && touched ? errors : ""}</span>
    </>
  )
}

export default UploadPdf
