import React, { useState } from "react"
import ResetDoneButton from "../common/Buttons/resetDoneButton"
import UploadVideo from "../common/uploadVideo/uploadVideo"
import {
  errorMessage,
  resetMessageShow,
  uploadError,
} from "../common/Function/commonFunction"
import { PromoVideoProps } from "../../config/commonType"
import { toast } from "react-toastify"
import apiConfigWithFormData from "../../config/apiConfigWithFormData"
import OnlineCourseVideos from "./onlineCourseVideo"
import { prepareFormPromoVideo } from "../common/Function/prepareFormData"

const PromoVideoSection = (props: PromoVideoProps) => {
  const {
    newArray,
    uploadVideo,
    setUploadVideo,
    course_id,
    setDurationValue,
    durationValue,
    media_id2,
    courseMultipleArray,
  } = props
  const toastId = React.useRef<any>(null)
  const [isProcess, setIsProcess] = useState<boolean>(false)
  const [disabled, setDisabled] = useState(false)

  //upload image api call
  function uploadMedia(data: any) {
    setIsProcess(true)
    apiConfigWithFormData
      .post("upload-media", data, {
        onUploadProgress: (p: any) => {
          const progress = (p.loaded / p.total) * 100
          const pro: any = Math.round(progress)

          // check if we already displayed a toast
          if (toastId.current === null) {
            toastId.current = toast(`Upload video in Progress ${pro} %`, {
              // @ts-ignore
              pro,
            })
          } else {
            toast.update(toastId.current, {
              // @ts-ignore
              pro,
              render: `Upload video in ${pro} % successfully`,
              type: "success",
            })
          }
        },
      })
      .then(() => {
        setIsProcess(false)
        // toast.done(toastId.current)
        // setUploadVideo(undefined)
        // navigate(`/courses-list`)
      })
      .catch((response: any) => {
        setDisabled(false)
        if (response.message) {
          return errorMessage(response.message)
        } else {
          uploadError(response)
        }
      })
  }

  //on save button click event
  const submitSaveButton = () => {
    if (newArray !== "" && media_id2 !== "" && media_id2 !== undefined) {
      setDisabled(true)
      updateUploadMedia(
        media_id2,
        prepareFormPromoVideo(uploadVideo, course_id, media_id2, durationValue),
      )
    } else {
      setDisabled(true)
      uploadMedia(
        prepareFormPromoVideo(uploadVideo, course_id, "", durationValue),
      )
    }
  }

  //update upload image api call
  function updateUploadMedia(media_id: any, data: any) {
    setIsProcess(true)
    apiConfigWithFormData
      .put(`/update-media/${media_id}`, data, {
        onUploadProgress: (p: any) => {
          const progress = (p.loaded / p.total) * 100
          const pro: any = Math.round(progress)

          // check if we already displayed a toast
          if (toastId.current === null) {
            toastId.current = toast(`Upload video in Progress ${pro} %`, {
              // @ts-ignore
              pro,
              autoClose: 5000,
            })
          } else {
            toast.update(toastId.current, {
              // @ts-ignore
              pro,
              render: `Upload video in ${pro} % successfully`,
              type: "success",
              autoClose: 5000,
            })
          }
        },
      })
      .then(() => {
        setIsProcess(false)
        // toast.done(toastId.current)
        // setUploadVideo(undefined)
        // if (editShow === true) {
        //   // navigate(`/courses-list`)
        // }
      })
      .catch((response: any) => {
        setDisabled(false)
        if (response.message) {
          return errorMessage(response.message)
        } else {
          uploadError(response)
        }
      })
  }

  //on reset function
  const resetClick = () => {
    if (newArray !== "") {
      setUploadVideo(newArray.uploadVideo)
      resetMessageShow().then((r) => console.log(r))
    } else {
      resetMessageShow().then((r) => console.log(r))
      setUploadVideo(uploadVideo)
    }
  }

  return (
    <>
      <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-5'>
        <div className='add-category-details mb-5'>
          <div className='add-category-and-close-button'>
            <div className='add-category-title'>
              <h1>{`${
                newArray !== "" ? "EDIT" : "ADD"
              } ONLINE COURSE PROMO VIDEO`}</h1>
            </div>
          </div>
          <div className='only-padding'>
            {/*<!------- upload video section start-----!>*/}
            <UploadVideo
              isEdit={media_id2 !== undefined ? true : false}
              title={"Upload Promo Video"}
              toastIdRef={toastId}
              setSelectedImage={setUploadVideo}
              selectedImage={uploadVideo}
              setDurationValue={setDurationValue}
            />

            {/*<!------- button section start-----!>*/}
            <div className='button-div'>
              <ResetDoneButton
                disableFirst={!uploadVideo || disabled}
                isProcess={isProcess}
                disable={!uploadVideo || disabled}
                firstButton={"Reset"}
                secondButton={"Save"}
                onClickSecond={submitSaveButton}
                onClickFirst={resetClick}
              />
            </div>
          </div>
        </div>

        <OnlineCourseVideos
          newArray={newArray}
          course_id={course_id}
          courseMultipleArray={courseMultipleArray}
        />
      </div>
    </>
  )
}

export default PromoVideoSection
