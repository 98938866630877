import React from "react"
import OfflineClasses from "./OfflineCourses"
import AddOfflineForm from "./addOfflineForm"
import TabTitle from "../../pages/tab-title/TabTitle"

const OfflineCourse = (props: { singleArray?: any }) => {
  const { singleArray } = props
  return (
    <>
      <section className='online-classes add-recipes'>
        <TabTitle title={"Add Offline Course"} />
        <div className='common-page-padding'>
          <div className='row'>
            {/*<!------ add offline class section -----!>*/}
            <AddOfflineForm singleArray={singleArray} />

            {/*<!------ offline class section -----!>*/}
            <OfflineClasses />
          </div>
        </div>
      </section>
    </>
  )
}

export default OfflineCourse
