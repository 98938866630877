import React, { useEffect, useState } from "react"
import ResetDoneButton from "../common/Buttons/resetDoneButton"
import UploadImage from "../common/UploadImage/uploadImage"
import { MakeHomeStore } from "../../store/coursestore/courseSlice"
import { useDispatch } from "react-redux"
import { useMutation } from "@apollo/client"
import {
  EDIT_BANNER,
  INSERT_BANNER,
  updateUploadMedia,
  uploadMedia,
} from "./queries"
import {
  errorMessageShow,
  resetMessageShow,
} from "../common/Function/commonFunction"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"
import Titles from "../common/Title/Title"

const AddBannerDetails = (props: { id?: any; singleArray?: any }) => {
  const { id, singleArray } = props
  const media_id: any = singleArray?.banner_media[0]?.media_id

  const [selectedImage, setSelectedImage] = useState<any>()
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const [addBanners] = useMutation(INSERT_BANNER)
  const [editBanners] = useMutation(EDIT_BANNER)

  useEffect(() => {
    const data = {
      selectedImage: selectedImage,
      editImage: singleArray?.banner_media[0]?.media_fk?.media_url,
    }
    dispatch(MakeHomeStore(data))
  }, [selectedImage])

  // empty field function
  const EmptyAllField = () => {
    setSelectedImage(undefined)
  }

  //on reset function
  const resetClick = () => {
    if (selectedImage !== undefined) {
      EmptyAllField()
      return resetMessageShow()
    } else {
      return Swal.fire({ title: "Please select banner photo!" })
    }
  }

  //onClick save button
  const submitSaveButton = () => {
    if (id) {
      editBanner()
    } else {
      addBanner()
    }
  }

  const addBanner = () => {
    addBanners({
      onError: (error) => {
        if (error.message) {
          return errorMessageShow(error)
        }
      },
      onCompleted: (data) => {
        if (data.insert_banners.__typename) {
          const banner_id = data.insert_banners.returning[0].banner_id
          uploadMedia(prepareFormData(banner_id), navigate)
          EmptyAllField()
        }
      },
    }).then((r) => console.log(r))
  }

  const editBanner = () => {
    editBanners({
      variables: { banner_id: id },
      onError: (error) => {
        if (error.message) {
          return errorMessageShow(error)
        }
      },
      onCompleted: (data) => {
        if (data.update_banners.__typename) {
          updateUploadMedia(media_id, prepareFormData(media_id), navigate)
          EmptyAllField()
        }
      },
    }).then((r) => console.log(r))
  }

  //prepare media form data
  const prepareFormData = (banner_id: any) => {
    const formData = new FormData()
    formData.append("file", selectedImage)
    formData.append("media_name", selectedImage?.name)
    formData.append("media_type", "photo")
    if (media_id) {
      formData.append("media_id", media_id?.toString())
    } else {
      formData.append("id", banner_id?.toString())
    }
    formData.append("type", "7")
    formData.append("media_desc", "banner image")
    formData.append("media_video_type", "normal")
    formData.append("is_thumbnail", "false")
    return formData
  }

  return (
    <>
      <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-5'>
        <div className='add-category-details'>
          <Titles label={`${id !== undefined ? "EDIT" : "ADD"} BANNERS`} />
          <div className='only-padding'>
            {/*<!------- upload image section start-----!>*/}
            <UploadImage
              isEdit={media_id !== undefined ? true : false}
              selectedImage={selectedImage}
              title={"Upload Photos"}
              setSelectedImage={setSelectedImage}
            />

            {/*<!------- button section start-----!>*/}
            <div className='button-div'>
              <ResetDoneButton
                disable={!selectedImage}
                firstButton={"Reset"}
                secondButton={"Save"}
                onClickFirst={resetClick}
                onClickSecond={submitSaveButton}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddBannerDetails
