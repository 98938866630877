import React, { useState } from "react"
import Inputs from "../common/Inputs/InputField"
import ResetDoneButton from "../common/Buttons/resetDoneButton"
import { useMutation } from "@apollo/client"
import { ADD_RECIPE_CATEGORY, FETCH_RECIPE_CATEGORY } from "./queries"
import {
  errorMessageShow,
  resetMessageShow,
  successMessageShow,
} from "../common/Function/commonFunction"
import Titles from "../common/Title/Title"
import { useFormik } from "formik"
import { validationSchemaCategory } from "../common/Function/validationFunction"

const AddCategory = () => {
  const [isProcess, setIsProcess] = useState<any>(false)
  const [addCategory] = useMutation(ADD_RECIPE_CATEGORY)
  const initialValues = { category_name: "" }

  // onCLick done button
  const submitDoneButton = (values: any) => {
    setIsProcess(true)
    addCategory({
      variables: values,
      refetchQueries: [{ query: FETCH_RECIPE_CATEGORY }],
      onError: (error) => {
        if (error.message) {
          return errorMessageShow(error)
        }
      },
      onCompleted: (data) => {
        if (data.insert_category_master.__typename) {
          setIsProcess(false)
          return successMessageShow("Recipe category add successfully!")
        }
      },
    })
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchemaCategory,
    onSubmit: (values, { resetForm }) => {
      submitDoneButton(values)
      resetForm()
    },
  })

  return (
    <>
      <div className='add-category-details'>
        <form onSubmit={formik.handleSubmit}>
          <Titles label={"ADD CATEGORY"} />
          <Inputs
            MaxLength={30}
            label={"Category Name"}
            type={"text"}
            placeHolder={"Enter Category name"}
            name='category_name'
            onChange={formik.handleChange}
            value={formik.values.category_name}
            errors={formik.errors.category_name}
            touched={formik.touched.category_name}
          />

          {/*<!------- button section start-----!>*/}
          <ResetDoneButton
            disableFirst={!formik.dirty}
            isProcess={isProcess}
            firstButton={"Reset"}
            secondButton={"Add"}
            onClickFirst={() => {
              formik.resetForm()
              resetMessageShow().then((r) => console.log(r))
            }}
          />
        </form>
      </div>
    </>
  )
}

export default AddCategory
