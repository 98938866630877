import OurProductslist from "../../components/ourProductsList/our-products-list"

const OurProductsList = () => {
  return (
    <>
      <OurProductslist />
    </>
  )
}

export default OurProductsList
