import axios from "axios"
import axiosInterceptor from "./axiosInterceptsxtors"
import { environment } from "./constant"

const wampServer = environment.URL
const axiosApi = axios.create({
  baseURL: wampServer,
})
axiosInterceptor.setupInterceptors(axiosApi, true)
export default axiosApi
