import React, { useState } from "react"
import { toast } from "react-toastify"
import { ProgressBar } from "react-bootstrap"
import { UploadImageProps } from "../../../config/commonType"
import { urls } from "../../../constants"

const UploadImage = (props: UploadImageProps) => {
  const {
    title,
    selectedImage,
    setSelectedImage,
    isEdit,
    multiple,
    fileObj,
    fileArray,
  } = props

  const [titleFile, setTitleFile] = React.useState<any>(
    isEdit === true ? 100 : 0,
  )
  const [progress, setProgress] = useState<any>(isEdit === true ? 100 : 0)
  const uploadRef = React.useRef<any>(null)
  const statusRef = React.useRef<any>(null)
  const loadTotalRef = React.useRef<any>(null)
  const progressRef = React.useRef<any>(null)

  // This function will be triggered when the file field change
  const onClickImage = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      fileObj.push(e.target.files)
      for (let i = 0; i < fileObj[0].length; i++) {
        fileArray.push(fileObj[0][i])
      }
      setSelectedImage({ file: fileArray })
      setProgress(0)
      const file = uploadRef.current.files[0]
      const fileReader = new FileReader()
      fileReader.onloadend = () => {}
      fileReader.readAsDataURL(file)
      const formData = new FormData()
      formData.append("image", file)
      const xhr = new XMLHttpRequest()
      xhr.upload.addEventListener("progress", ProgressHandler, false)
      // @ts-ignore
      xhr.addEventListener("load", SuccessHandler, false)
      xhr.addEventListener("error", ErrorHandler, false)
      xhr.addEventListener("abort", AbortHandler, false)
      xhr.open("POST", urls.api_url)
      xhr.send(formData)
      toast.success("Image upload successfully!")
    }
  }

  const ProgressHandler = (e: { loaded: number; total: number }) => {
    loadTotalRef.current.innerHTML = `uploaded ${e.loaded} bytes of ${e.total}`
    const percent = (e.loaded / e.total) * 100
    progressRef.current.value = Math.round(percent)
    setProgress(progressRef.current.value)
    statusRef.current.innerHTML = Math.round(percent)
    setTitleFile(statusRef.current.innerHTML)
  }

  const SuccessHandler = (e: { target: { responseText: any } }) => {
    statusRef.current.innerHTML = e.target.responseText
    progressRef.current.value = 0
  }
  const ErrorHandler = () => {
    statusRef.current.innerHTML = "upload failed!!"
  }
  const AbortHandler = () => {
    statusRef.current.innerHTML = "upload aborted!!"
  }

  return (
    <>
      <div className='only-positon-new'>
        <div className='category-input-feild'>
          <div className='category-input-feild-inner'>
            <label>{`${title ? title : "Upload Image (Thumbnail)"}`}</label>
            <input
              multiple={multiple}
              type='file'
              name='file'
              ref={uploadRef}
              onChange={onClickImage}
              className='upload-video'
              accept={"image/*"}
            />
          </div>
        </div>
        <div className='upload-video-div-design'>
          <div className='upload-image'>
            <img src='/images/image-icon.png' alt='' />
          </div>
          <div className='click-or-drag-to-upload-title'>
            <p>Click or drag file to this area to upload</p>
          </div>

          <div className='bulk-upload-title'>
            <p>Support for a single or bulk upload.</p>
          </div>
          {selectedImage !== undefined && (
            <div className={"progress-bar-upload"}>
              <ProgressBar
                label={`${titleFile}%`}
                ref={progressRef}
                now={progress}
              />
            </div>
          )}
          <p className='d-none' ref={statusRef} />
          <p className='d-none' ref={loadTotalRef} />
        </div>
      </div>
    </>
  )
}

export default UploadImage
