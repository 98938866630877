import React from "react"
import AddCategorys from "../../components/addCategoryies/addCategory"

const AddCategory = () => {
  return (
    <>
      <AddCategorys />
    </>
  )
}

export default AddCategory
