import { useFormik } from "formik"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useMutation, useQuery } from "@apollo/client"
import {
  ADD_OFFLINE_COURSE,
  COURSE_STATUS_UPDATED,
  UPDATE_OFFLINE_COURSE,
  uploadMedia,
} from "./queries"
import {
  errorMessage,
  errorMessageShow,
  groupBy,
  resetMessageShow,
  successMessageShow,
} from "../common/Function/commonFunction"
import React, { useEffect, useState } from "react"
import { MakeStore } from "../../store/coursestore/courseSlice"
import { FETCH_COURSE } from "../onlineCoursesList/queries"
import apiConfigWithFormData from "../../config/apiConfigWithFormData"
import SelectDropdown from "../common/Inputs/selectField"
import UploadImage from "../common/UploadImage/uploadImage"
import ResetDoneButton from "../common/Buttons/resetDoneButton"
import { FETCH_CATEGORY } from "../addCategoryies/queries"
import Inputs from "../common/Inputs/InputField"
import { OfflineDataStore } from "../../config/commonType"
import { validationSchema } from "../common/Function/validationFunction"
import Titles from "../common/Title/Title"
import { prepareFormData } from "../common/Function/prepareFormData"
import { toast } from "react-toastify"

export default function AddOfflineForm(props: { singleArray?: string[] }) {
  const { singleArray } = props
  const dispatch = useDispatch()
  let navigate = useNavigate()
  let newArray: any
  newArray =
    singleArray !== undefined && singleArray.length ? singleArray[0] : ""

  const { data } = useQuery(FETCH_CATEGORY)
  const [updateOfflineCourses] = useMutation(UPDATE_OFFLINE_COURSE)
  const [addOfflineCourses] = useMutation(ADD_OFFLINE_COURSE)
  const [updatedCourseStatus] = useMutation(COURSE_STATUS_UPDATED)
  const categoryArray = groupBy(data?.category_master, "category_type")

  const [isProcess, setIsProcess] = useState<boolean>(false)
  const [course_id, setCourse_id] = useState<string>("")
  const [media_id, setMedia_id] = useState<string>("")
  let thumbnail =
    newArray?.course_media &&
    newArray?.course_media[0] &&
    newArray?.course_media[0]?.media_fk

  const initialValues = {
    course_id: newArray ? newArray?.course_id : "",
    category_id: newArray ? newArray.category_id : "",
    course_name: newArray ? newArray?.course_name : "",
    course_details: newArray ? newArray?.course_details : "",
    course_amount: newArray ? newArray?.course_amount : "",
    course_status: newArray ? newArray?.course_status : true,
    course_discount_amount: newArray ? newArray?.course_discount_amount : "",
    selectedImage: thumbnail ? thumbnail.media_desc : "",
  }

  useEffect(() => {
    if (newArray) {
      editAllField()
    }
  }, [newArray])

  // all edit field function
  const editAllField = () => {
    setCourse_id(newArray?.course_id)
    setMedia_id(thumbnail?.media_id)
  }

  const addOfflineCourse = (values: any) => {
    setIsProcess(true)
    addOfflineCourses({
      variables: values,
      refetchQueries: [{ query: FETCH_COURSE }],
      onError: (error) => {
        if (error.message) {
          return errorMessageShow(error)
        }
      },
      onCompleted: (data) => {
        if (data.insert_courses.__typename) {
          setIsProcess(false)
          const course_id = data.insert_courses.returning[0].course_id
          if (
            formik.values.selectedImage !== undefined &&
            formik.values.selectedImage !== "offline_image"
          ) {
            uploadMedia(prepareFormData(formik, course_id, media_id), navigate)
          } else {
            navigate(`/offline-course-list`)
          }
          return successMessageShow("Offline course add successfully!")
        }
      },
    }).then((r) => console.log(r))
  }

  const editOfflineCourse = (values: any) => {
    setIsProcess(true)
    updateOfflineCourses({
      variables: values,
      refetchQueries: [{ query: FETCH_COURSE }],
      onError: (error) => {
        if (error.message) {
          return errorMessageShow(error)
        }
      },
      onCompleted: (data) => {
        if (data.update_courses.__typename) {
          setIsProcess(false)
          if (
            media_id !== undefined &&
            formik.values.selectedImage !== "offline_image" &&
            formik.values.selectedImage !== undefined
          ) {
            apiConfigWithFormData
              .put(
                `/update-media/${media_id}`,
                prepareFormData(formik, course_id, media_id),
              )
              .then(() => {
                navigate(`/offline-course-list`)
              })
          } else {
            if (
              formik.values.selectedImage !== undefined &&
              formik.values.selectedImage !== "offline_image"
            ) {
              uploadMedia(
                prepareFormData(formik, course_id, media_id),
                navigate,
              )
            }
            navigate(`/offline-course-list`)
          }
          return successMessageShow("Offline course edit successfully!")
        }
      },
    }).then((r) => console.log(r))
  }

  //onClick save button
  const submitSaveButton = (values: any) => {
    if (newArray !== "") {
      editOfflineCourse(values)
    } else {
      addOfflineCourse(values)
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submitSaveButton(values)
    },
  })

  useEffect(() => {
    let data: OfflineDataStore
    data = {
      course_name: formik.values.course_name,
      course_details: formik.values.course_details,
      course_amount: formik.values.course_amount,
      course_discount_amount: formik.values.course_discount_amount,
      selectedImage: formik.values.selectedImage,
      editImage: thumbnail?.media_url,
    }
    dispatch(MakeStore(data))
  }, [initialValues])

  const updatedStatus = () => {
    if (course_id !== "") {
      updatedCourseStatus({
        variables: {
          course_id: course_id,
          course_status: !formik.values.course_status,
        },
        onCompleted: async (data) => {
          if (data.update_courses.returning[0].course_status === false) {
            toast.success("Course status deactivate successfully!")
          } else {
            toast.success("Course status active successfully!")
          }
        },
        onError: (error) => {
          if (error.message.includes("Foreign key violation.")) {
            return errorMessage("Something went wrong!")
          } else {
            return errorMessageShow(error)
          }
        },
      })
    } else {
      if (!formik.values.course_status) {
        toast.success("Course status active successfully!")
      } else {
        toast.success("Course status deactivate successfully!")
      }
    }
  }

  return (
    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-5'>
      <form onSubmit={formik.handleSubmit}>
        <div className='add-category-details'>
          <Titles
            label={newArray !== "" ? "EDIT OFFLINE CLASS" : "ADD OFFLINE CLASS"}
            formik={formik}
            updatedStatus={updatedStatus}
            course_id={course_id}
            isActiveCourse={true}
          />
          <div className='only-padding'>
            <Inputs
              MaxLength={30}
              type={"text"}
              placeHolder={"Enter Product Name"}
              name='course_name'
              onChange={formik.handleChange}
              value={formik.values.course_name}
              label={"Product Name"}
              errors={formik.errors.course_name}
              touched={formik.touched.course_name}
            />

            {/*<!------- select dropdown menu section start-----!>*/}
            <SelectDropdown
              name={"category_id"}
              touched={formik.touched.category_id}
              errors={formik.errors.category_id}
              title={"Category"}
              value={formik.values.category_id}
              data={categoryArray?.course}
              onChange={formik.handleChange}
              handleBlur={formik.handleBlur}
            />

            <Inputs
              MaxLength={200}
              label={"Details"}
              type={"text"}
              placeHolder={"Enter Details"}
              name='course_details'
              onChange={formik.handleChange}
              value={formik.values.course_details}
              errors={formik.errors.course_details}
              touched={formik.touched.course_details}
            />

            <div className='two-side-input-filed'>
              <div className='row'>
                <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                  <Inputs
                    type={"number"}
                    placeHolder={"Enter Price"}
                    name='course_amount'
                    onChange={formik.handleChange}
                    label={"Price"}
                    value={formik.values.course_amount}
                    errors={formik.errors.course_amount}
                    touched={formik.touched.course_amount}
                  />
                </div>

                <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                  <Inputs
                    type={"number"}
                    placeHolder={"Enter Price"}
                    name='course_discount_amount'
                    onChange={formik.handleChange}
                    label={"Discounted Price"}
                    value={formik.values.course_discount_amount}
                    errors={formik.errors.course_discount_amount}
                    touched={formik.touched.course_discount_amount}
                  />
                </div>
              </div>
            </div>

            {/*<!------- upload image section start-----!>*/}
            <UploadImage
              title={"Upload Image"}
              setSelectedImage={(selectedImage: any) => {
                formik.setFieldValue("selectedImage", selectedImage)
              }}
              errors={formik.errors.selectedImage}
              touched={formik.touched.selectedImage}
              selectedImage={formik.values.selectedImage}
              isEdit={formik.values.selectedImage !== "" ? true : false}
            />

            {/*<!------- button section start-----!>*/}
            <div className='button-div'>
              <ResetDoneButton
                isProcess={isProcess}
                firstButton={"Reset"}
                secondButton={"Save"}
                disableFirst={!formik.dirty}
                onClickFirst={() => {
                  formik.resetForm()
                  resetMessageShow().then((r) => console.log(r))
                }}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
