import React from "react"
import { ResetDoneButtonProps } from "../../../config/commonType"
import ClipLoader from "react-spinners/ClipLoader"
import { override } from "../Function/commonFunction"

const ResetDoneButton = (props: ResetDoneButtonProps) => {
  const {
    firstButton,
    secondButton,
    onClickFirst,
    onClickSecond,
    disable,
    isProcess,
    disableFirst,
  } = props
  return (
    <>
      <div className='reset-and-done-button'>
        <div className='reset-button'>
          <button type='button' onClick={onClickFirst} disabled={disableFirst}>
            {firstButton}
          </button>
        </div>
        <div className='done-button'>
          <button
            type='submit'
            className={"btn"}
            onClick={onClickSecond}
            disabled={disable || isProcess}
          >
            {isProcess ? (
              <ClipLoader color='#ffffff' size={17} cssOverride={override} />
            ) : (
              secondButton
            )}
          </button>
        </div>
      </div>
    </>
  )
}

export default ResetDoneButton
