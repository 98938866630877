import Addbanner from "../../components/Home/add-banner"

const AddBanner = () => {
  return (
    <>
      <Addbanner />
    </>
  )
}

export default AddBanner
