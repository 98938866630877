/* eslint-disable no-nested-ternary */
import * as React from "react"
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"

import { Stack } from "react-bootstrap"
import { DOTS, usePagination } from "./usePaginaton"
import { PagiProps } from "../../../config/commonType"

const Pagi: React.FC<PagiProps> = (props) => {
  const {
    totalCount,
    onChange,
    newPageCount,
    onClickPage,
    limit,
    onSelectOption,
    // optionValue,
  } = props

  let totalCounts = (totalCount / limit) * 10
  let currentPage = newPageCount
  let siblingCount = 1
  let pageSize = 10

  const paginationRange = usePagination({
    currentPage,
    totalCounts,
    siblingCount,
    pageSize,
  })

  if (currentPage === -1 || (paginationRange && paginationRange.length < 2)) {
    return null
  }

  let lastPage = paginationRange && paginationRange[paginationRange.length - 1]

  const onNext = () => {
    onChange(currentPage + 1)
  }

  const onPrevious = () => {
    onChange(currentPage - 1)
  }

  // const onSelectOption = (e:any) => {

  //   onSelectOption(e.target.value)
  //   setlimit(e.target.value)
  //    console.log(e.target.value,"players")
  // }

  return (
    <Stack
      className='align-content-center justify-content-between'
      style={{ height: "100px" }}
      direction='horizontal'
      id='pagination'
    >
      <div className='px-3 mb-2'></div>

      <div className='listing-pagination'>
        <nav
          aria-label='Page navigation'
          style={{ display: "flex", alignItems: "center" }}
        >
          <div style={{ margin: " 0 15px 15px 0" }}>Limit</div>
          <select
            className='limit-section'
            name='optionValue'
            id='cars'
            onChange={(e) => onSelectOption(e)}
            value={limit}
            style={{ marginBottom: "15px", height: "35px" }}
          >
            <option value={10} key={10}>
              10
            </option>
            <option value={20} key={20}>
              20
            </option>
            <option value={50} key={50}>
              50
            </option>
            <option value={100} key={100}>
              100
            </option>
          </select>
          <ul className='pagination'>
            <li className='page-item'>
              <a
                className={
                  currentPage === 0 ? "page-link disabled" : "page-link"
                }
                onClick={onPrevious}
                aria-label='Previous'
              >
                <span aria-hidden='true'>
                  <BsChevronLeft size={10} />
                </span>
              </a>
            </li>

            {paginationRange?.map((pageNumber, unique) => {
              if (pageNumber === DOTS) {
                return (
                  <li key={unique} className='page-item'>
                    <span className='page-link-dot'>&#8230;</span>
                  </li>
                )
              }
              return (
                <li key={unique} className='page-item'>
                  <a
                    className={
                      currentPage + 1 === pageNumber
                        ? "page-link active"
                        : "page-link"
                    }
                    onClick={() => {
                      if (currentPage + 1 !== pageNumber) {
                        // @ts-ignore
                        onChange(pageNumber - 1)
                        // @ts-ignore
                        onClickPage(pageNumber - 1)
                      }
                    }}
                  >
                    {pageNumber}
                  </a>
                </li>
              )
            })}
            <li className='page-item'>
              <a
                className={
                  currentPage + 1 === lastPage
                    ? "page-link disabled"
                    : "page-link"
                }
                aria-label='Next'
                onClick={onNext}
              >
                <span aria-hidden='true'>
                  <BsChevronRight size={10} />
                </span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </Stack>
  )
}

export default Pagi
