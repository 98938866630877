/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  amazonProduct,
  course,
  multipleVideo,
  courseType,
  recipe,
  caterings,
  home,
} from "./types"

const initialState: courseType = {
  home: {},
  course: {},
  multipleVideo: {},
  // @ts-ignore
  recipe: {},
  amazonProduct: {},
  caterings: {},
}

export const courseSlice = createSlice({
  name: "courseSlice",
  initialState,
  reducers: {
    MakeHomeStore: (state, action: PayloadAction<Partial<home>>) => {
      state.home = action.payload
    },
    MakeStore: (state, action: PayloadAction<Partial<course>>) => {
      state.course = action.payload
    },
    MakeStoreMultipleVideo: (
      state,
      action: PayloadAction<Partial<multipleVideo>>,
    ) => {
      state.multipleVideo = action.payload
    },
    MakeRecipeStore: (state, action: PayloadAction<Partial<recipe>>) => {
      // @ts-ignore
      state.recipe = action.payload
    },
    MakeAmazonProductStore: (
      state,
      action: PayloadAction<Partial<amazonProduct>>,
    ) => {
      state.amazonProduct = action.payload
    },
    MakeCateringStore: (state, action: PayloadAction<Partial<caterings>>) => {
      state.caterings = action.payload
    },
  },
})

export const {
  MakeStore,
  MakeRecipeStore,
  MakeAmazonProductStore,
  MakeCateringStore,
  MakeHomeStore,
  MakeStoreMultipleVideo,
} = courseSlice.actions

export default courseSlice.reducer
