import gql from "graphql-tag"
import apiConfigWithFormData from "../../config/apiConfigWithFormData"
import { uploadError } from "../common/Function/commonFunction"

export const FETCH_CATERING = gql`
  query GetBanners {
    caterings {
      catering_media {
        catering_media_id
        catering_id
        media_id
        media_fk {
          media_url
          media_type
          media_status
          media_photo_type
          media_duration
          media_name
          media_id
          media_desc
          media_video_type
        }
      }
      catering_id
      catering_desc
      catering_name
    }
  }
`

export const ADD_CATERING = gql`
  mutation InsertCaterings($catering_name: String, $catering_desc: String) {
    insert_caterings(
      objects: { catering_name: $catering_name, catering_desc: $catering_desc }
    ) {
      affected_rows
      returning {
        catering_id
        catering_name
        catering_desc
        created_dt
        updated_dt
      }
    }
  }
`

export const UPDATE_CATERING = gql`
  mutation (
    $catering_id: bigint
    $catering_desc: String
    $catering_name: String
  ) {
    update_caterings(
      where: { catering_id: { _eq: $catering_id } }
      _set: { catering_desc: $catering_desc, catering_name: $catering_name }
    ) {
      affected_rows
      returning {
        catering_id
        catering_name
        catering_desc
        created_dt
        updated_dt
      }
    }
  }
`

export const SINGLE_RECORD_FETCH = `query MyQuery ($catering_id: bigint) {
  caterings(where: {catering_id: {_eq: $catering_id}}) {
    catering_id
    catering_desc
    catering_name
    catering_media {
      catering_id
      catering_media_id
      media_fk {
        media_id
        media_desc
        media_name
        media_photo_type
        media_status
        media_duration
        media_type
        media_video_type
        media_url
      }
      media_id
    }
  }
}`

//upload image api call
export function uploadMedia(data: any, navigate?: any) {
  apiConfigWithFormData
    .post("upload-media", data)
    .then(() => {
      navigate("/catering-list")
    })
    .catch((response: any) => {
      uploadError(response)
    })
}

//update upload image api call
export function updateUploadMedia(media_id: any, data: any, navigate?: any) {
  apiConfigWithFormData
    .put(`/update-media/${media_id}`, data)
    .then(() => {
      navigate("/catering-list")
    })
    .catch((response: any) => {
      uploadError(response)
    })
}
