import gql from "graphql-tag"

export const FETCH_CATERING = `
  query GetBanners {
    caterings {
      catering_media {
        catering_media_id
        catering_id
        media_id
        media_fk {
          media_url
          media_type
          media_status
          media_photo_type
          media_name
          media_id
          media_desc
          media_video_type
        }
      }
      catering_id
      catering_desc
      catering_name
    }
  }
`

export const DELETE_CATERING = gql`
  mutation DeleteCaterings($catering_id: Int, $catering_ids: bigint) {
    delete_catering_media(where: { catering_id: { _eq: $catering_id } }) {
      affected_rows
      returning {
        catering_id
        catering_media_id
        media_id
      }
    }
    delete_caterings(where: { catering_id: { _eq: $catering_ids } }) {
      returning {
        catering_desc
        catering_id
        catering_name
      }
      affected_rows
    }
  }
`
