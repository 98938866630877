import React, { useEffect, useState } from "react"
import ResetDoneButton from "../common/Buttons/resetDoneButton"
import Inputs from "../common/Inputs/InputField"
import UploadImage from "../common/UploadImage/multipleImage"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useMutation } from "@apollo/client"
import {
  ADD_OUR_PRODUCT,
  FETCH_OUR_PRODUCT,
  UPDATE_OUR_PRODUCT,
  updateUploadMedia,
  uploadMedia,
} from "./queries"
import { MakeAmazonProductStore } from "../../store/coursestore/courseSlice"
import {
  errorMessageShow,
  resetMessageShow,
  successMessageShow,
} from "../common/Function/commonFunction"
import "react-toastify/dist/ReactToastify.css"
import { useFormik } from "formik"
import { validationOurProduct } from "../common/Function/validationFunction"
import Titles from "../common/Title/Title"
import { prepareFormDataOurProduct } from "../common/Function/prepareFormData"

const AddOurProductForm = (props: { singleArray?: string[] }) => {
  const { singleArray } = props
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const [addOurProducts] = useMutation(ADD_OUR_PRODUCT)
  const [updateOurProducts] = useMutation(UPDATE_OUR_PRODUCT)

  let newArray: any
  newArray =
    singleArray !== undefined && singleArray.length ? singleArray[0] : ""

  const [isProcess, setIsProcess] = useState<boolean>(false)
  const [selectedImage, setSelectedImage] = useState<any>()
  const [media_id, setMedia_id] = useState("")

  const initialValues = {
    product_id: newArray ? newArray?.product_id : "",
    product_name: newArray ? newArray.product_name : "",
    product_desc: newArray ? newArray?.product_desc : "",
    product_price: newArray ? newArray?.product_price : "",
    discount_price: newArray ? newArray?.discount_price : "",
  }

  useEffect(() => {
    if (newArray) {
      editAllField()
    }
  }, [newArray])

  const editAllField = () => {
    setMedia_id(
      newArray &&
        newArray.product_media[0] &&
        newArray.product_media[0].media_id,
    )
    setSelectedImage(newArray.selectedImage)
  }

  const addOurProduct = (values: any) => {
    setIsProcess(true)
    addOurProducts({
      variables: values,
      refetchQueries: [{ query: FETCH_OUR_PRODUCT }],
      onError: (error) => {
        if (error.message) {
          return errorMessageShow(error)
        }
      },
      onCompleted: (data) => {
        if (data.insert_products.__typename) {
          setIsProcess(false)
          const product_id = data.insert_products.returning[0].product_id
          if (selectedImage !== undefined) {
            selectedImage &&
              selectedImage.file.map((url: any) => {
                return uploadMedia(
                  prepareFormDataOurProduct(product_id, url, media_id),
                  navigate,
                )
              })
          } else {
            navigate(`/our-products-list`)
          }
          setSelectedImage(undefined)
          return successMessageShow("Our product add successfully!")
        }
      },
    }).then((r) => console.log(r))
  }

  const editOurProduct = (values: any) => {
    setIsProcess(true)
    updateOurProducts({
      variables: values,
      refetchQueries: [{ query: FETCH_OUR_PRODUCT }],
      onError: (error) => {
        if (error.message) {
          return errorMessageShow(error)
        }
      },
      onCompleted: (data) => {
        if (data.update_products.__typename) {
          const product_id = data.update_products.returning[0].product_id
          setIsProcess(false)
          if (selectedImage !== undefined) {
            selectedImage &&
              selectedImage.file.map((url: any, ids: any) => {
                if (newArray && newArray?.product_media[0]) {
                  newArray &&
                    newArray?.product_media.map((id: any, index: React.Key) => {
                      if (ids === index) {
                        return updateUploadMedia(
                          newArray?.product_media[ids].media_id,
                          prepareFormDataOurProduct(
                            newArray?.product_media[ids].media_id,
                            url,
                            media_id,
                          ),
                          navigate,
                        )
                      }
                    })
                } else {
                  return uploadMedia(
                    prepareFormDataOurProduct(product_id, url, media_id),
                    navigate,
                  )
                }
              })
          }
          navigate(`/our-products-list`)
          return successMessageShow("Our product edit successfully!")
        }
      },
    })
  }

  //onClick save button
  const submitSaveButton = (values: any) => {
    if (newArray !== "") {
      editOurProduct(values)
    } else {
      addOurProduct(values)
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationOurProduct,
    onSubmit: (values) => {
      submitSaveButton(values)
    },
  })

  useEffect(() => {
    const data = {
      product_name: formik.values.product_name,
      product_desc: formik.values.product_desc,
      product_price: formik.values.product_price,
      discount_price: formik.values.discount_price,
      selectedImage: selectedImage,
      editImage:
        newArray?.product_media &&
        newArray?.product_media[0] &&
        newArray?.product_media[0]?.product_media?.media_url,
    }
    dispatch(MakeAmazonProductStore(data))
  }, [selectedImage, initialValues])

  let fileObj: never[] = []
  let fileArray: never[] = []
  return (
    <>
      <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-5'>
        <form onSubmit={formik.handleSubmit}>
          <div className='add-category-details'>
            <Titles
              label={newArray !== "" ? "EDIT OUR PRODUCT" : "ADD OUR PRODUCT"}
            />
            <div className='only-padding'>
              <Inputs
                MaxLength={30}
                label={"Product Name"}
                type={"text"}
                placeHolder={"Enter Product Name"}
                name='product_name'
                onChange={formik.handleChange}
                value={formik.values.product_name}
                errors={formik.errors.product_name}
                touched={formik.touched.product_name}
              />

              <Inputs
                MaxLength={200}
                label={"Details"}
                type={"text"}
                placeHolder={"Enter Details"}
                name='product_desc'
                onChange={formik.handleChange}
                value={formik.values.product_desc}
                errors={formik.errors.product_desc}
                touched={formik.touched.product_desc}
              />

              <div className='two-side-input-filed'>
                <div className='row'>
                  <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                    <Inputs
                      label={"Price"}
                      type={"number"}
                      placeHolder={"Enter Price"}
                      name='product_price'
                      onChange={formik.handleChange}
                      value={formik.values.product_price}
                      errors={formik.errors.product_price}
                      touched={formik.touched.product_price}
                    />
                  </div>
                  <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                    <Inputs
                      label={"Discounted Price"}
                      type={"number"}
                      placeHolder={"Enter Price"}
                      name='discount_price'
                      onChange={formik.handleChange}
                      value={formik.values.discount_price}
                      errors={formik.errors.discount_price}
                      touched={formik.touched.discount_price}
                    />
                  </div>
                </div>
              </div>

              {/*<!------- upload image section start-----!>*/}
              <UploadImage
                multiple={true}
                fileArray={fileArray}
                fileObj={fileObj}
                selectedImage={selectedImage}
                title={"Upload Image"}
                setSelectedImage={setSelectedImage}
              />

              {/*<!------- button section start-----!>*/}
              <div className='button-div'>
                <ResetDoneButton
                  disableFirst={!formik.dirty}
                  isProcess={isProcess}
                  firstButton={"Reset"}
                  secondButton={"Save"}
                  onClickFirst={() => {
                    formik.resetForm()
                    resetMessageShow().then((r) => console.log(r))
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddOurProductForm
