import React from "react"
import AmazonProductLists from "../../components/amazonProductList/amazonProductList"

const Amazonproductlists = () => {
  return (
    <>
      <AmazonProductLists />
    </>
  )
}

export default Amazonproductlists
