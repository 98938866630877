import React, { useEffect, useState } from "react"
import SingleOnlineCard from "../common/Cards/singleOnlineCard"
import { useMutation } from "@apollo/client"
import { DELETE_COURSE, GET_COURSE } from "./queries"
import { useNavigate } from "react-router-dom"
import {
  errorMessage,
  errorMessageShow,
  flagArray,
  groupBys,
  matchingArray,
  requestOptions,
  successMessageShow,
} from "../common/Function/commonFunction"
import { urls } from "../../constants"
import TabTitle from "../../pages/tab-title/TabTitle"

const CoursesLists = () => {
  let navigate = useNavigate()
  const [deleteCourse] = useMutation(DELETE_COURSE)
  const [users, setUsers] = useState([])

  const [isProcess, setIsProcess] = useState<any>(false)
  const [isIds, setIsIds] = useState<any>()
  const onlineArray = groupBys(users)

  // filter all items from B that satisfy a matching condition with at least one item from A
  const matches = matchingArray(onlineArray?.online)
  const results = flagArray(onlineArray?.online, matches)
  const videoLabel =
    results &&
    results.map((i: any) => {
      return { ...i, isVideo: "Video is Pending" }
    })

  const initialData = matches && matches.concat(videoLabel)

  useEffect(() => {
    fetchData().then((r) => console.log(r))
  }, [])

  const fetchData = async () => {
    const graphql = JSON.stringify({ query: GET_COURSE })

    const Url = `${urls.api_url}`
    // @ts-ignore
    const res = await fetch(Url, requestOptions(graphql))
    const usersData = await res.json()
    setUsers(usersData?.data?.courses)
  }
  if (initialData === undefined) {
    return <div className='loading_message mt-200'>Loading...</div>
  }

  // onClick delete function
  const deleteCourses = (course_id: any) => {
    setIsIds(course_id)
    setIsProcess(true)
    let course_ids = course_id
    deleteCourse({
      variables: { course_id, course_ids },
      onCompleted: (data) => {
        if (data.delete_courses.__typename) {
          setIsProcess(false)
          fetchData().then((r) => console.log(r))
          return successMessageShow("Online course delete successfully!")
        }
      },
      onError: (error) => {
        if (error.message.includes("Foreign key violation.")) {
          setIsProcess(false)
          return errorMessage(
            "Course is not delete because user purchased this course",
          )
        } else {
          return errorMessageShow(error)
        }
      },
    })
  }

  //onClick edit
  const editCourse = (course_id: any) => {
    navigate(`/edit-online-course/${course_id}`)
  }

  return (
    <>
      <TabTitle title={"Online Course"} />
      <div className='common-page-scroll'>
        <section className='couses-list'>
          <div className='common-page-padding'>
            <div className='couses-list-inner'>
              <div className='row'>
                {initialData !== undefined
                  ? initialData &&
                    initialData.map(
                      (item: any, index: React.Key | null | undefined) => {
                        return (
                          <SingleOnlineCard
                            isIds={isIds}
                            isProcess={isProcess}
                            item={item}
                            key={index}
                            onClick={deleteCourses}
                            editClick={editCourse}
                            course_id={item.course_id}
                          />
                        )
                      },
                    )
                  : ""}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default CoursesLists
