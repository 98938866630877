import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { userData, UserListType } from "./types"

const initialState: UserListType = {
  user: {},
}

const UserDetailSlice = createSlice({
  name: "UserDetailSlice",
  initialState,
  reducers: {
    userDetails: function (state, action: PayloadAction<Partial<userData>>) {
      state.user = action.payload
    },
  },
})

export const { userDetails } = UserDetailSlice.actions

export default UserDetailSlice.reducer
