import gql from "graphql-tag"

export const FETCH_CATEGORY = gql`
  query GetCategoryMaster {
    category_master {
      category_id
      category_status
      category_name
      category_type
      created_dt
      updated_dt
    }
  }
`

export const ADD_CATEGORY = gql`
  mutation ($category_name: String) {
    insert_category_master(
      objects: { category_name: $category_name, category_type: "course" }
    ) {
      returning {
        category_id
        category_name
        category_status
        category_type
        created_dt
      }
      affected_rows
    }
  }
`

export const DELETE_CATEGORY = gql`
  mutation ($category_id: bigint) {
    delete_category_master(where: { category_id: { _eq: $category_id } }) {
      affected_rows
    }
  }
`
