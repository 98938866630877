import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import store from "../../store"
import { useParams } from "react-router-dom"
import { SingleCourse } from "../../store/singleCourse/courseSlice"
import { urls } from "../../constants"
import { requestOptions } from "../common/Function/commonFunction"
import Addbanner from "./add-banner"
import { SINGLE_BANNER } from "./queries"

const EditBanner = () => {
  const dispatch = useDispatch()
  let storeReducer: any
  storeReducer = useSelector(
    (state: ReturnType<typeof store.getState>) =>
      state?.singleCourse?.course?.banners,
  )

  const [isProcess, setIsProcess] = useState<any>(true)
  const { id } = useParams()
  useEffect(() => {
    fetchData(id).then((r) => console.log(r))
  }, [])

  // single record fetch
  const fetchData = async (banner_id: any) => {
    const graphql = JSON.stringify({
      query: SINGLE_BANNER,
      variables: { banner_id: banner_id, banner_ids: banner_id },
    })

    const Url = urls.api_url
    // @ts-ignore
    const res = await fetch(Url, requestOptions(graphql))
    const usersData = await res.json()
    setIsProcess(false)
    dispatch(SingleCourse(usersData.data))
  }

  if (isProcess === true) {
    return <div className='loading_message mt-200'>Loading...</div>
  }

  return (
    <>
      <Addbanner id={id} singleArray={storeReducer[0]} />
    </>
  )
}

export default EditBanner
