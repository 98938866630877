import React from "react"
import CategoryAdd from "./categoryAdd"
import Categories from "./Categories"
import TabTitle from "../../pages/tab-title/TabTitle"

const AddCategorys = () => {
  return (
    <>
      <TabTitle title={"Add Categories"} />
      <section className='add-category'>
        <div className='common-page-padding'>
          {/*<!----add category section start------!>*/}
          <CategoryAdd />

          {/*<!-------categories section start------!>*/}
          <Categories />
        </div>
      </section>
    </>
  )
}

export default AddCategorys
