import { useSelector } from "react-redux"
import store from "../../store"
import React from "react"
import Titles from "../common/Title/Title"

const AddCateringVideoPrev = () => {
  const storeReducer = useSelector(
    (state: ReturnType<typeof store.getState>) =>
      state.courseReducers.caterings,
  )

  const totalDuration = storeReducer?.durationUploadVideo

  return (
    <>
      <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6'>
        <div className='add-category-details'>
          <Titles label={`CATERING`} />
          <div className='recipe-uploaded-video-details'>
            <div className='recipe-uploaded-video-image margin-video'>
              {storeReducer.selectedImage &&
              storeReducer.selectedImage !== undefined &&
              storeReducer.selectedImage !== "Thumbnail image" ? (
                <img
                  src={URL.createObjectURL(storeReducer.selectedImage)}
                  alt='Thumb'
                />
              ) : storeReducer?.editImage ? (
                <img src={storeReducer?.editImage} alt='' />
              ) : (
                <img src='/images/catering-img.png' alt='' />
              )}
            </div>
            {storeReducer.clientImage !== undefined &&
              (storeReducer?.clientImage?.media_fk ? (
                <div className='multiple-image-show'>
                  <img
                    src={storeReducer?.clientImage?.media_fk?.media_url}
                    alt={""}
                  />
                  <p>{storeReducer?.clientImage?.media_fk?.media_name}</p>
                </div>
              ) : (
                <div className='multiple-image-show'>
                  <img
                    src={URL.createObjectURL(storeReducer?.clientImage)}
                    alt={""}
                  />
                  <p>{storeReducer?.clientImage?.name}</p>
                </div>
              ))}

            {/*<!----- Upload Catering Video ------!>*/}
            {storeReducer.cateringVideo !== undefined &&
              (storeReducer?.cateringVideo?.media_fk ? (
                <div className='multiple-image-show promo-video'>
                  <div className='icon-section'>
                    <img className='' src={"/images/video-icon.svg"} alt={""} />
                    <p>{storeReducer?.cateringVideo?.media_fk?.media_name}</p>
                  </div>
                  <p className='ml-auto'>
                    {Number(storeReducer?.durationValue)?.toFixed(0) +
                      " Minutes"}
                  </p>
                </div>
              ) : (
                <div className='multiple-image-show promo-video'>
                  <div className='icon-section'>
                    <img className='' src={"/images/video-icon.svg"} alt={""} />
                    <p>
                      {storeReducer.cateringVideo?.media_fk
                        ? storeReducer.cateringVideo?.media_fk?.media_name
                        : storeReducer.cateringVideo?.name}
                    </p>
                  </div>
                  <p className='ml-auto'>
                    {storeReducer?.durationValue !== null
                      ? storeReducer?.durationValue?.toFixed(0) + " Minutes"
                      : ""}
                  </p>
                </div>
              ))}
            {storeReducer?.uploadVideo !== undefined &&
            storeReducer?.uploadVideo?.file
              ? storeReducer?.uploadVideo?.file.map((img: any) => {
                  return (
                    <div className='multiple-image-show promo-video'>
                      <div className='icon-section'>
                        <img
                          className=''
                          src={"/images/video-icon.svg"}
                          alt={""}
                        />
                        <p>{img?.name}</p>
                      </div>
                      <p className='ml-auto'>
                        {storeReducer?.durationUploadVideo !== null
                          ? storeReducer?.durationUploadVideo?.toFixed(0) +
                            " Minutes"
                          : ""}
                      </p>
                    </div>
                  )
                })
              : storeReducer?.uploadVideo &&
                storeReducer?.uploadVideo.map((img: any) => {
                  return (
                    <div className='multiple-image-show promo-video'>
                      <div className='icon-section'>
                        <img
                          className=''
                          src={"/images/video-icon.svg"}
                          alt={""}
                        />
                        <p>{img?.media_fk?.media_name}</p>
                      </div>
                      <p className='ml-auto'>
                        {img?.media_fk?.media_duration
                          ? Number(img?.media_fk?.media_duration)?.toFixed(0) +
                            " Minutes"
                          : totalDuration?.toFixed(0) + " Minutes"}
                      </p>
                    </div>
                  )
                })}
          </div>
        </div>
      </div>
    </>
  )
}

export default AddCateringVideoPrev
