import AddOurProducts from "../../components/addOurProduct/addOurProducts"

const AddOurProduct = () => {
  return (
    <>
      <AddOurProducts />
    </>
  )
}

export default AddOurProduct
