import React, { useEffect, useState } from "react"
import SingleCateringCard from "../common/Cards/singleCateringCard"
import { useNavigate } from "react-router-dom"
import { useMutation } from "@apollo/client"
import {
  errorMessageShow,
  requestOptions,
  successMessageShow,
} from "../common/Function/commonFunction"
import LoadMoreButton from "../common/Buttons/loadMoreButton"
import { DELETE_CATERING, FETCH_CATERING } from "./queries"
import { urls } from "../../constants"
import TabTitle from "../../pages/tab-title/TabTitle"

const CateringVideoList = () => {
  let navigate = useNavigate()
  const [deleteCatering] = useMutation(DELETE_CATERING)
  const [index, setIndex] = useState(6)
  const [users, setUsers] = useState([])
  const [isProcess, setIsProcess] = useState<boolean>(false)
  const [isIds, setIsIds] = useState<any>()
  const initialData = users && users.slice(0, index)

  useEffect(() => {
    fetchData().then((r) => console.log(r))
  }, [])

  const fetchData = async () => {
    const graphql = JSON.stringify({ query: FETCH_CATERING })

    const Url = `${urls.api_url}`
    // @ts-ignore
    const res = await fetch(Url, requestOptions(graphql))
    const usersData = await res.json()
    setUsers(usersData?.data?.caterings)
  }
  if (initialData === undefined) {
    return <div className='loading_message mt-200'>Loading...</div>
  }

  //load more function
  const loadMore = () => {
    setIndex(index + 6)
  }

  // onClick delete function
  const deleteCourses = (catering_id: any) => {
    setIsIds(catering_id)
    setIsProcess(true)
    const catering_ids: any = catering_id
    deleteCatering({
      variables: { catering_id, catering_ids },
      onCompleted: (data) => {
        if (data.delete_caterings.__typename) {
          successMessageShow("Catering delete successfully!")
          fetchData().then((r) => console.log(r))
        }
      },
      onError: (error) => {
        if (error.message) {
          errorMessageShow(error)
        }
      },
    })
  }

  //onClick edit
  const editCatering = (catering_id: any) => {
    navigate(`/edit-catering/${catering_id}`)
  }

  return (
    <>
      <TabTitle title={"Catering Videos"} />
      <div className='common-page-scroll'>
        <section className='offline-class-list'>
          <div className='common-page-padding'>
            <div className='couses-list-inner'>
              <div className='row'>
                {initialData &&
                  initialData.map(
                    (item: any, index: React.Key | null | undefined) => {
                      return (
                        <SingleCateringCard
                          isIds={isIds}
                          isProcess={isProcess}
                          item={item}
                          key={index}
                          editClick={editCatering}
                          onClick={deleteCourses}
                          catering_id={item.catering_id}
                        />
                      )
                    },
                  )}
              </div>
              {/*<!------- Load more button section start-----!>*/}
              {users.length !== initialData.length && users.length > 6 && (
                <LoadMoreButton onClick={loadMore} />
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default CateringVideoList
