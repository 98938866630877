import React from "react"
import { totalEarningsDashboard } from "../../config/commonType"

const TotalEarningsDashboard = (props: totalEarningsDashboard) => {
  const { totalCount, weekCount } = props

  return (
    <div className='dashboard-inner'>
      <div className='add-category-details'>
        <div className='add-category-and-close-button'>
          <div className='add-category-title'>
            <h1>Total Earnings</h1>
          </div>
        </div>
        <div className='today-and-yesterday-earning'>
          <div className='today-earning'>
            <div className='only-border'>
              <div className='star-image'>
                <img src='/images/star-image.svg' alt='' />
              </div>
              <div className='today-earning-show'>
                <p>{totalCount !== null ? "₹" + totalCount : "₹ " + "0"}</p>
              </div>
              <div className='today-title'>
                <span>Today</span>
              </div>
            </div>
          </div>
          <div className='today-earning'>
            <div className='only-border hide-border'>
              <div className='star-image'>
                <img src='/images/dollar-image.svg' alt='' />
              </div>
              <div className='today-earning-show'>
                <p>{weekCount !== null ? "₹" + weekCount : "₹ " + "0"}</p>
              </div>
              <div className='today-title'>
                <span>This Week</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TotalEarningsDashboard
