import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import store from "../../store"
import { SingleCourse } from "../../store/singleCourse/courseSlice"
import { useParams } from "react-router-dom"
import AddRecipes from "./addRecipes"
import { urls } from "../../constants"
import { requestOptions } from "../common/Function/commonFunction"
import { SINGLE_RECORD_FETCH } from "./queries"

const EditRecipes = () => {
  const dispatch = useDispatch()
  const storeReducer: any = useSelector(
    (state: ReturnType<typeof store.getState>) => state?.singleCourse?.course,
  )
  const [isProcess, setIsProcess] = useState<boolean>(true)
  const { id } = useParams()
  useEffect(() => {
    fetchData(id).then((r) => console.log(r))
  }, [id])

  //Fetching single data
  const fetchData = async (recipe_id: any) => {
    const graphql = JSON.stringify({
      query: SINGLE_RECORD_FETCH,
      variables: { recipe_id },
    })

    const Url = urls.api_url
    // @ts-ignore
    const res = await fetch(Url, requestOptions(graphql))
    const usersData = await res.json()
    setIsProcess(false)
    dispatch(SingleCourse(usersData.data?.recipes))
  }

  if (isProcess === true) {
    return <div className='loading_message mt-200'>Loading...</div>
  }

  return (
    <>
      <AddRecipes singleArray={storeReducer} />
    </>
  )
}

export default EditRecipes
