import React from "react"
import EditDeleteButton from "../Buttons/editDeleteButton"
import { singleCateringCardProps } from "../../../config/commonType"
import { uploadImageThumbnailId } from "../Function/commonFunction"

const SingleCateringCard = (props: singleCateringCardProps) => {
  const { item, onClick, catering_id, editClick, isIds, isProcess } = props
  const uploadThumbnailImage = uploadImageThumbnailId([item])

  return (
    <>
      <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4'>
        <div className='catering-list-details'>
          <div className='couses-images'>
            {uploadThumbnailImage && uploadThumbnailImage[0] ? (
              <img src={uploadThumbnailImage[0].media_fk.media_url} alt='' />
            ) : (
              <img src='/images/catering-img.png' alt='' />
            )}
          </div>
          <EditDeleteButton
            matchId={isIds}
            isProcess={isProcess}
            onClickDelete={onClick}
            course_id={catering_id}
            editClick={editClick}
          />
        </div>
      </div>
    </>
  )
}

export default SingleCateringCard
