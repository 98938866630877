import DashBoard from "../../components/dashboard/dashboard"

const Dashboard = () => {
  return (
    <>
      <DashBoard />
    </>
  )
}

export default Dashboard
