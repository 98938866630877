import gql from "graphql-tag"
import apiConfigWithFormData from "../../config/apiConfigWithFormData"
import {
  successMessageShow,
  uploadError,
} from "../common/Function/commonFunction"

export const INSERT_BANNER = gql`
  mutation InsertBanners($banner_desc: String) {
    insert_banners(objects: { banner_desc: $banner_desc }) {
      affected_rows
      returning {
        banner_id
        banner_status
        is_deleted
        banner_name
        banner_desc
        created_dt
        updated_dt
      }
    }
  }
`

export const EDIT_BANNER = gql`
  mutation UpdateBanners($banner_id: bigint) {
    update_banners(where: { banner_id: { _eq: $banner_id } }) {
      returning {
        banner_desc
        banner_id
        banner_name
        banner_status
        updated_dt
        is_deleted
      }
    }
  }
`

export const FETCH_BANNER = `query GetBannerMedia {
  banner_media {
    banner_media_id
    is_default
    banner_id
    media_id
    created_dt
    updated_dt
    media_fk {
      media_url
      media_id
    }
  }
}
`

export const DELETE_BANNER = `
mutation DeleteBanners($banner_id: bigint, $banner_ids: Int) {
  delete_banner_media(where: {banner_id: {_eq: $banner_ids}}) {
    affected_rows
    returning {
      banner_id
      media_id
      banner_media_id
    }
  }
  delete_banners(where: {banner_id: {_eq: $banner_id}}) {
    affected_rows
    returning {
      banner_desc
      banner_name
    }
  }
}
`

export const SINGLE_BANNER = `query GetBanners ($banner_id: bigint, $banner_ids: Int){
  banners(where: {banner_id: {_eq: $banner_id}}) {
    banner_id
    banner_status
    is_deleted
    banner_name
    banner_desc
    created_dt
    updated_dt
    banner_media(where: {banner_id: {_eq: $banner_ids}}) {
      media_id
      media_fk {
       media_id
        media_name
        media_url
        media_type
        media_photo_type
        media_status
      }
    }
  }
}
`

//upload image api call
export function uploadMedia(data: any, navigate: any) {
  apiConfigWithFormData
    .post("upload-media", data)
    .then(() => {
      navigate("/banner-list")
      return successMessageShow("Banner add successfully!")
    })
    .catch((response: any) => {
      return uploadError(response)
    })
}

//update upload image api call
export function updateUploadMedia(media_id: any, data: any, navigate?: any) {
  if (media_id !== undefined) {
    apiConfigWithFormData
      .put(`/update-media/${media_id}`, data)
      .then(() => {
        successMessageShow("Banner edit successfully!").then((r) =>
          console.log(r),
        )
        navigate("/banner-list")
      })
      .catch((response: any) => {
        return uploadError(response)
      })
  }
}
