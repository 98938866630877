import React from "react"
import { InputProps } from "../../../config/commonType"
import { onKeyPressFunction } from "../Function/commonFunction"

const Inputs = (props: InputProps) => {
  const {
    type,
    placeHolder,
    onChange,
    value,
    name,
    errors,
    label,
    touched,
    MaxLength,
  } = props

  return (
    <>
      <div className='category-input-feild'>
        <div className='category-input-feild-inner'>
          <label>{label}</label>
          <input
            onKeyPress={(e: any) => onKeyPressFunction(e, MaxLength)}
            onPaste={(e: any) => onKeyPressFunction(e, MaxLength)}
            min={0}
            type={type}
            placeholder={placeHolder}
            value={value}
            name={name}
            onChange={(event) => (onChange ? onChange(event) : "")}
          />
          <span className='text-danger'>{errors && touched ? errors : ""}</span>
        </div>
      </div>
    </>
  )
}

export default Inputs
