import React from "react"
import AmazonProducts from "../../components/amazonProduct/amazonProduct"

const AmazonProduct = () => {
  return (
    <>
      <AmazonProducts />
    </>
  )
}

export default AmazonProduct
