import ProgressBar from "react-bootstrap/ProgressBar"
import { useQuery } from "@apollo/client"
import { FETCH_COURSE } from "../onlineCoursesList/queries"
import React, { useEffect, useState } from "react"
import {
  FETCH_DASHBOARD,
  FETCH_USER,
  // firstWeekTotalCounts,
  // fourWeekTotalCounts,
  monthTotalCounts,
  // secondWeekTotalCounts,
  // threeWeekTotalCounts,
  // TODAYTOTALCOUNTS,
  // weekTotalCounts,
} from "./queries"
import moment from "moment"
import { urls } from "../../constants"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {
  // dashboard,
  dashboardNewArray,
  // uniqueArrayType,
  uniqueEnrollArrayType,
} from "../../config/commonType"
import { requestOptions } from "../common/Function/commonFunction"
import RecentCourseDashboard from "./recentCourseDashboard"
import TotalEarningsDashboard from "./totalEarningsDashboard"
import TabTitle from "../../pages/tab-title/TabTitle"
import apiConfig from "../../config/apiConfig"

const DashBoard = () => {
  const { error, data } = useQuery(FETCH_COURSE)
  const { data: data1 } = useQuery(FETCH_DASHBOARD)
  const { data: data2 } = useQuery(FETCH_USER)
  const [totalCount, setTotalCount] = useState()
  const [weekCount, setWeekCount] = useState()
  const [monthCount, setMonthCount] = useState<any>()
  // const [firstWeekCount, setFirstWeekCount] = useState<any>()
  // const [secondWeekCount, setSecondWeekCount] = useState<any>()
  // const [threeWeekCount, setThreeWeekCount] = useState<any>()
  // const [fourWeekCount, setFourWeekCount] = useState<any>()
  const [startDateMonth, setStartDateMonth] = useState(new Date())
  const [courseListArray, setCourseListArray] = useState<any>([])
  // const [fiveWeekCount, setFiveWeekCount] = useState<any>()
  const [earningDetail, setEarningDetail] = useState<any>({
    todayEarn: 0,
    total: 0,
    weekData: null,
    weekEarn: 0,
  })
  // today total count
  // const created_dt = moment(new Date()).format("YYYY-MM-DD")
  const today = new Date()
  let tomorrowDate = new Date()
  tomorrowDate.setDate(today.getDate() + 1)
  // const tomorrow = moment(tomorrowDate).format("YYYY-MM-DD")
  // const todayTotalCount = async (created_dt: any, tomorrow: any) => {
  //   const graphql = JSON.stringify({
  //     query: TODAYTOTALCOUNTS,
  //     variables: { created_dt, tomorrow },
  //   })

  //   const Url = `${urls.api_url}`
  //   // @ts-ignore
  //   const res = await fetch(Url, requestOptions(graphql))
  //   const usersData = await res.json()
  //   setTotalCount(
  //     usersData?.data?.course_enrollments_aggregate?.aggregate?.sum
  //       ?.transfer_amount,
  //   )
  // }

  const paymentDetail = async (month: string, year: string) => {
    apiConfig
      .post(`/dashboard`, {
        month: month,
        year: year,
      })
      .then((res) => {
        setTotalCount(res?.data?.todayEarn || 0)
        setWeekCount(res?.data?.weekEarn || 0)
        setEarningDetail(res.data)

        // setFirstWeekCount(res?.data?.weekData?.week0 || 0);
        // setSecondWeekCount(res?.data?.weekData?.week1 || 0);
        // setThreeWeekCount(res?.data?.weekData?.week4 || 0);
        // setFourWeekCount(res?.data?.weekData?.week4 || 0);
        // setFiveWeekCount(res?.data?.weekData?.week4 || 0);
        console.log(earningDetail, monthCount)
      })
  }

  // week total count
  // let startDates = moment().startOf("week")
  // let endDates = moment().endOf("week")
  // const startDate = startDates.format("YYYY-MM-DD")
  // const endDate = endDates.format("YYYY-MM-DD")
  // const weekTotalCount = async (created_dt: any, tomorrow: any) => {
  //   const graphql = JSON.stringify({
  //     query: weekTotalCounts,
  //     variables: { created_dt, tomorrow },
  //   })

  //   const Url = `${urls.api_url}`
  //   // @ts-ignore
  //   const res = await fetch(Url, requestOptions(graphql))

  //   const usersData = await res.json()
  //   setWeekCount(
  //     usersData?.data?.course_enrollments_aggregate?.aggregate?.sum
  //       ?.transfer_amount,
  //   )
  // }

  // month total count
  const date = new Date(startDateMonth)
  const firstDay = moment(
    new Date(date.getFullYear(), date.getMonth(), 1),
  ).format("YYYY-MM-DD")
  const lastDay = moment(
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  ).format("YYYY-MM-DD")
  const monthTotalCount = async (created_dt: any, tomorrow: any) => {
    const graphql = JSON.stringify({
      query: monthTotalCounts,
      variables: { created_dt, tomorrow },
    })

    const Url = `${urls.api_url}`
    // @ts-ignore
    const res = await fetch(Url, requestOptions(graphql))
    const usersData = await res.json()
    setMonthCount(
      usersData?.data?.course_enrollments_aggregate?.aggregate?.sum
        ?.transfer_amount,
    )
  }

  // week total count
  // let firstStartDates = moment(startDateMonth).startOf("week")
  // let firstEndDates = moment(startDateMonth).endOf("week")
  // const a = moment(startDateMonth).format("MM")
  // const firstStartDate = firstStartDates.format(`YYYY-${a}-01`)
  // const firstEndDate = firstEndDates.format("YYYY-MM-07")
  // const secondStartDate = firstStartDates.format(`YYYY-${a}-08`)
  // const secondEndDate = firstEndDates.format("YYYY-MM-15")
  // const threeStartDate = firstStartDates.format(`YYYY-${a}-16`)
  // const threeEndDate = firstEndDates.format("YYYY-MM-22")
  // const fourStartDate = firstStartDates.format(`YYYY-${a}-23`)
  // const fourEndDate = firstEndDates.format("YYYY-MM-30")
  // const firstWeekTotalCount = async (created_dt: any, tomorrow: any) => {
  //   const graphql = JSON.stringify({
  //     query: firstWeekTotalCounts,
  //     variables: { created_dt, tomorrow },
  //   })

  //   const Url = `${urls.api_url}`
  //   // @ts-ignore
  //   const res = await fetch(Url, requestOptions(graphql))
  //   const usersData = await res.json()
  //   setFirstWeekCount(
  //     usersData?.data?.course_enrollments_aggregate?.aggregate?.sum
  //       ?.transfer_amount,
  //   )
  // }

  // const secondWeekTotalCount = async (created_dt: any, tomorrow: any) => {
  //   const graphql = JSON.stringify({
  //     query: secondWeekTotalCounts,
  //     variables: { created_dt, tomorrow },
  //   })

  //   const Url = `${urls.api_url}`
  //   // @ts-ignore
  //   const res = await fetch(Url, requestOptions(graphql))
  //   const usersData = await res.json()
  //   // setSecondWeekCount(
  //   //   usersData?.data?.course_enrollments_aggregate?.aggregate?.sum
  //   //     ?.transfer_amount,
  //   // )
  // }

  // const threeWeekTotalCount = async (created_dt: any, tomorrow: any) => {
  //   const graphql = JSON.stringify({
  //     query: threeWeekTotalCounts,
  //     variables: { created_dt, tomorrow },
  //   })

  //   const Url = `${urls.api_url}`
  //   // @ts-ignore
  //   const res = await fetch(Url, requestOptions(graphql))
  //   const usersData = await res.json()
  //   setThreeWeekCount(
  //     usersData?.data?.course_enrollments_aggregate?.aggregate?.sum
  //       ?.transfer_amount,
  //   )
  // }
  // const fourWeekTotalCount = async (created_dt: any, tomorrow: any) => {
  //   const graphql = JSON.stringify({
  //     query: fourWeekTotalCounts,
  //     variables: { created_dt, tomorrow },
  //   })

  //   const Url = `${urls.api_url}`
  //   // @ts-ignore
  //   const res = await fetch(Url, requestOptions(graphql))
  //   const usersData = await res.json()
  //   setFourWeekCount(
  //     usersData?.data?.course_enrollments_aggregate?.aggregate?.sum
  //       ?.transfer_amount,
  //   )
  // }
  useEffect(() => {
    paymentDetail(moment().format("MMM"), moment().format("YYYY"))
  }, [])
  // useEffect(() => {
  //   // todayTotalCount(created_dt, tomorrow).then((r) => r)
  //   // weekTotalCount(startDate, endDate).then((r) => r)
  //   // monthTotalCount(firstDay, lastDay).then((r) => r)
  //   // firstWeekTotalCount(firstStartDate, firstEndDate).then(
  //   //   (response) => response,
  //   // )
  //   // secondWeekTotalCount(secondStartDate, secondEndDate).then(
  //   //   (response) => response,
  //   // )
  //   // threeWeekTotalCount(threeStartDate, threeEndDate).then(
  //   //   (response) => response,
  //   // )
  //   // fourWeekTotalCount(fourStartDate, fourEndDate).then((response) => response)
  // }, [
  //   firstDay,
  //   lastDay,
  //   monthCount,
  //   firstWeekCount,
  //   secondWeekCount,
  //   threeWeekCount,
  //   fourWeekCount,
  //   startDateMonth,
  // ])

  const newArray: dashboardNewArray[] = []

  useEffect(() => {
    // {
    // data?.courses &&
    // data?.courses.map((item: dashboard) => {
    //   data1?.course_enrollments &&
    //     data1?.course_enrollments.map((it: uniqueArrayType) => {
    //       if (it.course_id === item.course_id) {
    //         newArray.push({
    //           user_id: it.user_id,
    //           course_name: item.course_name,
    //           course_amount: item.course_discount_amount,
    //           course_id: item.course_id,
    //         })
    //         setCourseListArray(newArray)
    //       }
    //     })
    // })
    // console.log(data1)
    data1?.course_enrollments &&
      data1?.course_enrollments.map((it: uniqueEnrollArrayType) => {
        newArray.push({
          user_id: it.user_id,
          course_name: it.course_fk.course_name,
          course_amount: it.transfer_amount,
          course_id: it.course_id,
        })
        setCourseListArray(newArray)
      })
    console.log(data)

    // }
  }, [data1, data])
  if (totalCount === undefined) {
    return <div className='loading_message mt-200'>Loading...</div>
  }
  if (error) {
    return <div className='loading_message mt-200'>Error!</div>
  }

  const initialData = courseListArray && courseListArray.slice(0, 5)

  //on change date picker month
  const onChangeDate = (date: React.SetStateAction<Date>) => {
    const month = moment(`${date}`).format("MMMM")
    const year = moment(`${date}`).format("YYYY")
    paymentDetail(month, year)
    setStartDateMonth(date)

    monthTotalCount(firstDay, lastDay).then((response) => response)
    // firstWeekTotalCount(firstStartDate, firstEndDate).then(
    //   (response) => response,
    // )
    // secondWeekTotalCount(secondStartDate, secondEndDate).then(
    //   (response) => response,
    // )
    // threeWeekTotalCount(threeStartDate, threeEndDate).then(
    //   (response) => response,
    // )
    // fourWeekTotalCount(fourStartDate, fourEndDate).then((response) => response)
  }

  const firstWeekPer = Math.round(
    (earningDetail?.weekData?.week0 / earningDetail?.total) * 100,
  )
  const secondWeekPer = Math.round(
    (earningDetail?.weekData?.week1 / earningDetail?.total) * 100,
  )
  const threeWeekPer = Math.round(
    (earningDetail?.weekData?.week2 / earningDetail?.total) * 100,
  )
  const fourWeekPer = Math.round(
    (earningDetail?.weekData?.week3 / earningDetail?.total) * 100,
  )
  const fiveWeekPer = Math.round(
    (earningDetail?.weekData?.week4 / earningDetail?.total) * 100,
  )

  return (
    <>
      <TabTitle title={"Dashboard"} />
      <section className='add-category dashboard'>
        <div className='common-page-padding'>
          {/*<!--------Total Earnings section start------------!>*/}
          <TotalEarningsDashboard
            totalCount={totalCount}
            weekCount={weekCount}
          />

          {/*<!-------------Recent Courses Purchased section start------------!>*/}
          <div className='recent-courses-and-monthly-revenue'>
            <div className='row'>
              <RecentCourseDashboard initialData={initialData} data2={data2} />
              {/*<!-----------Monthly Revenue section start-----!>*/}
              <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                <div className='add-category-details'>
                  <div className='add-category-and-close-button'>
                    <div className='add-category-title'>
                      <h1>Monthly Revenue</h1>
                    </div>
                    <div className={"position-datepicker"}>
                      <DatePicker
                        selected={startDateMonth}
                        onChange={(date: any) => onChangeDate(date)}
                        dateFormat='MMMM'
                        showMonthYearPicker
                        showFullMonthYearPicker
                        maxDate={new Date()}
                        showTwoColumnMonthYearPicker
                      />
                      <div className='date-image'>
                        <img src='/images/black-arrow.svg' alt='' />
                      </div>
                    </div>
                  </div>
                  <div className='progressbar-section'>
                    <div className='progressbar-inner'>
                      <div className='week-title'>
                        <label>Week 1</label>
                      </div>
                      <div className='range-and-percentage'>
                        <div className='progressbar'>
                          <ProgressBar
                            variant='danger'
                            now={!isNaN(firstWeekPer) ? firstWeekPer : 0}
                          />
                        </div>
                        <div className='percentage'>
                          <p>{!isNaN(firstWeekPer) ? firstWeekPer : 0}%</p>
                        </div>
                      </div>
                    </div>
                    <div className='progressbar-inner'>
                      <div className='week-title'>
                        <label>Week 2</label>
                      </div>
                      <div className='range-and-percentage'>
                        <div className='progressbar'>
                          <ProgressBar
                            variant='danger'
                            now={!isNaN(secondWeekPer) ? secondWeekPer : 0}
                          />
                        </div>
                        <div className='percentage'>
                          <p>{!isNaN(secondWeekPer) ? secondWeekPer : 0}%</p>
                        </div>
                      </div>
                    </div>
                    <div className='progressbar-inner'>
                      <div className='week-title'>
                        <label>Week 3</label>
                      </div>
                      <div className='range-and-percentage'>
                        <div className='progressbar'>
                          <ProgressBar
                            variant='danger'
                            now={!isNaN(threeWeekPer) ? threeWeekPer : 0}
                          />
                        </div>
                        <div className='percentage'>
                          <p>{!isNaN(threeWeekPer) ? threeWeekPer : 0}%</p>
                        </div>
                      </div>
                    </div>
                    <div className='progressbar-inner'>
                      <div className='week-title'>
                        <label>Week 4</label>
                      </div>
                      <div className='range-and-percentage'>
                        <div className='progressbar'>
                          <ProgressBar
                            variant='danger'
                            now={!isNaN(fourWeekPer) ? fourWeekPer : 0}
                          />
                        </div>
                        <div className='percentage'>
                          <p>{!isNaN(fourWeekPer) ? fourWeekPer : 0}%</p>
                        </div>
                      </div>
                    </div>
                    {/* {earningDetail?.weekData?.week4 && */}
                    <div className='progressbar-inner'>
                      <div className='week-title'>
                        <label>Week 5</label>
                      </div>
                      <div className='range-and-percentage'>
                        <div className='progressbar'>
                          <ProgressBar
                            variant='danger'
                            now={!isNaN(fiveWeekPer) ? fiveWeekPer : 0}
                          />
                        </div>
                        <div className='percentage'>
                          <p>{!isNaN(fiveWeekPer) ? fiveWeekPer : 0}%</p>
                        </div>
                      </div>
                    </div>
                    {/* } */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default DashBoard
