import React, { useEffect, useState } from "react"
import Inputs from "../common/Inputs/InputField"
import ResetDoneButton from "../common/Buttons/resetDoneButton"
import UploadImage from "../common/UploadImage/uploadImage"
import SelectDropdown from "../common/Inputs/selectField"
import {
  groupBy,
  imageRecipeGet,
  recipeYoutubeLink,
  resetMessageShow,
  uploadVideoId,
} from "../common/Function/commonFunction"
import { useQuery } from "@apollo/client"
import { FETCH_RECIPE_CATEGORY } from "../recipeAddCategory/queries"
import RecipeVideo from "./addrecipesvideo"
import { MakeRecipeStore } from "../../store/coursestore/courseSlice"
import { useDispatch } from "react-redux"
import "react-toastify/dist/ReactToastify.css"
import { useFormik } from "formik"
import { validationRecipe } from "../common/Function/validationFunction"
import Titles from "../common/Title/Title"
import RichTextEditor, { EditorValue } from "react-rte"
import RichTextEditors from "../common/Inputs/RichTextEditors"

const AddRecipeForm = (props: { singleArray?: any }) => {
  const { singleArray } = props
  const { data } = useQuery(FETCH_RECIPE_CATEGORY)
  const categoryArray = groupBy(data?.category_master, "category_type")
  const uploadVideos = uploadVideoId(singleArray)
  const youtubeLink = recipeYoutubeLink(singleArray)
  const dispatch = useDispatch()

  let newArray: any
  newArray = singleArray !== undefined && singleArray ? singleArray[0] : ""
  const photo = imageRecipeGet(newArray.recipe_media)
  const thumbnail = photo && photo[0] && photo[0].media_fk

  const [pasteLink, setPasteLink] = useState<string>()
  const [uploadVideo, setUploadVideo] = useState<any>()
  const [media_id, setMedia_id] = useState("")
  const [media_id1, setMedia_id1] = useState("")
  const [media_id2, setMedia_id2] = useState("")
  const [durationValue, setDurationValue] = useState<any>(null)
  const [showNextSection, setShowNextSection] = useState<boolean>(false)
  const [values, setValues] = useState()

  const initialValues = {
    recipe_id: newArray ? newArray?.recipe_id : "",
    category_id: newArray ? newArray?.category_id : "",
    recipe_name: newArray ? newArray?.recipe_name : "",
    preparation_time: newArray ? newArray?.preparation_time : "",
    cook_time: newArray ? newArray?.cook_time : "",
    total_time: newArray ? newArray?.total_time : "",
    cuisine: newArray ? newArray?.cuisine : "",
    serving: newArray ? newArray?.serving : "",
    recipe_type: newArray ? newArray?.recipe_type : "",
    recipe_desc: newArray ? newArray?.recipe_desc : "",
    recipe_details: newArray ? newArray?.recipe_details : "",
    selectedImage: thumbnail ? thumbnail.media_desc : "",
  }

  useEffect(() => {
    if (newArray) {
      editAllField()
    }
  }, [newArray])

  //onChange input

  const onChangePasteLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasteLink(e.target.value)
  }

  //on Next Click function
  const onNextClick = (values: any) => {
    setValues(values)
    setShowNextSection(true)
  }

  //on empty field function
  const EmptyAllField = () => {
    setPasteLink("")
    setUploadVideo(undefined)
  }

  const editAllField = () => {
    setMedia_id(photo[0] && photo[0]?.media_id)
    setMedia_id1(uploadVideos && uploadVideos[0]?.media_id)
    setMedia_id2(youtubeLink && youtubeLink[0]?.media_id)
    setPasteLink(youtubeLink && youtubeLink[0]?.media_fk?.media_url)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationRecipe,
    onSubmit: (values) => {
      onNextClick(values)
    },
  })

  useEffect(() => {
    const data = {
      recipe_name: formik.values.recipe_name,
      category_id: formik.values.category_id,
      cuisine: formik.values.cuisine,
      serving: formik.values.serving,
      cook_time: formik.values.cook_time,
      preparation_time: formik.values.preparation_time,
      total_time: formik.values.total_time,
      recipe_desc: formik.values.recipe_desc,
      recipe_details: formik.values.recipe_details,
      selectedImage: formik.values.selectedImage,
      uploadVideo: uploadVideo === undefined ? uploadVideos[0] : uploadVideo,
      recipe_type: formik.values.recipe_type,
      pasteLink: pasteLink,
      editImage: thumbnail?.media_url,
      durationValue:
        uploadVideo === undefined
          ? uploadVideos[0] && uploadVideos[0]?.media_fk?.media_duration
          : durationValue,
    }
    dispatch(MakeRecipeStore(data))
  }, [uploadVideo, pasteLink, initialValues])

  const [mdeState, setMdeState] = useState(
    formik.values.recipe_desc
      ? RichTextEditor.createValueFromString(formik.values.recipe_desc, "html")
      : RichTextEditor.createEmptyValue(),
  )

  const handleMdeChange = (value: React.SetStateAction<EditorValue>) => {
    formik.setFieldValue("recipe_desc", value.toString("html"))
    setMdeState(value)
  }

  return (
    <>
      {showNextSection === false ? (
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-5'>
          <form onSubmit={formik.handleSubmit}>
            <div className='add-category-details'>
              <Titles label={`${newArray !== "" ? "EDIT" : "ADD"} RECIPES`} />
              <div className='only-padding'>
                <Inputs
                  MaxLength={30}
                  type={"text"}
                  placeHolder={"Enter recipe name"}
                  name='recipe_name'
                  onChange={formik.handleChange}
                  value={formik.values.recipe_name}
                  label={"Recipe Name"}
                  errors={formik.errors.recipe_name}
                  touched={formik.touched.recipe_name}
                />

                {/*<!------- select dropdown menu section start-----!>*/}
                <SelectDropdown
                  name={"category_id"}
                  errors={formik.errors.category_id}
                  touched={formik.touched.category_id}
                  title={"Category"}
                  value={formik.values.category_id}
                  data={categoryArray?.recipe}
                  onChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />

                <Inputs
                  MaxLength={200}
                  type={"text"}
                  placeHolder={"Enter Details"}
                  name='recipe_details'
                  onChange={formik.handleChange}
                  value={formik.values.recipe_details}
                  label={"Details"}
                  errors={formik.errors.recipe_details}
                  touched={formik.touched.recipe_details}
                />

                {/*<!------- upload image section start-----!>*/}
                <UploadImage
                  selectedImage={formik.values.selectedImage}
                  title={"Upload Image (Thumbnail)"}
                  setSelectedImage={(selectedImage: any) => {
                    formik.setFieldValue("selectedImage", selectedImage)
                  }}
                  errors={formik.errors.selectedImage}
                  touched={formik.touched.selectedImage}
                  isEdit={formik.values.selectedImage !== "" ? true : false}
                />

                <div className='two-side-input-filed'>
                  <div className='row'>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                      <Inputs
                        type={"number"}
                        placeHolder={"00"}
                        name='preparation_time'
                        onChange={formik.handleChange}
                        label={"Prep Time"}
                        value={formik.values.preparation_time}
                        errors={formik.errors.preparation_time}
                        touched={formik.touched.preparation_time}
                      />
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                      <Inputs
                        type={"number"}
                        placeHolder={"00"}
                        name='cook_time'
                        onChange={formik.handleChange}
                        label={"Cook Time"}
                        value={formik.values.cook_time}
                        errors={formik.errors.cook_time}
                        touched={formik.touched.cook_time}
                      />
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                      <Inputs
                        type={"number"}
                        placeHolder={"00"}
                        name='total_time'
                        onChange={formik.handleChange}
                        label={"Total Time"}
                        value={formik.values.total_time}
                        errors={formik.errors.total_time}
                        touched={formik.touched.total_time}
                      />
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                      <Inputs
                        type={"text"}
                        placeHolder={"Enter Course name"}
                        name={"recipe_type"}
                        onChange={formik.handleChange}
                        label={"Course"}
                        value={formik.values.recipe_type}
                        errors={formik.errors.recipe_type}
                        touched={formik.touched.recipe_type}
                      />
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                      <Inputs
                        type={"text"}
                        placeHolder={"Enter Cuisine type"}
                        name='cuisine'
                        onChange={formik.handleChange}
                        label={"Cuisine"}
                        value={formik.values.cuisine}
                        errors={formik.errors.cuisine}
                        touched={formik.touched.cuisine}
                      />
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                      <Inputs
                        type={"text"}
                        placeHolder={"Enter Servings Details"}
                        name='serving'
                        onChange={formik.handleChange}
                        label={"Servings"}
                        value={formik.values.serving}
                        errors={formik.errors.serving}
                        touched={formik.touched.serving}
                      />
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12'>
                      <RichTextEditors
                        mdeState={mdeState}
                        onChange={handleMdeChange}
                        errors={formik.errors.recipe_desc}
                        touched={formik.touched.recipe_desc}
                        label={"Description"}
                        placeholder={"Enter Description"}
                      />
                    </div>
                  </div>
                </div>

                {/*<!------- button section start-----!>*/}
                <div className='button-div'>
                  <ResetDoneButton
                    disableFirst={!formik.dirty}
                    firstButton={"Reset"}
                    secondButton={"Next"}
                    onClickFirst={() => {
                      formik.resetForm()
                      setMdeState(
                        RichTextEditor.createValueFromString("", "html"),
                      )
                      resetMessageShow().then((r) => console.log(r))
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <RecipeVideo
          setShowNextSection={setShowNextSection}
          setDurationValue={setDurationValue}
          EmptyAllField={EmptyAllField}
          newArray={newArray}
          editAllField={editAllField}
          uploadVideo={uploadVideo}
          onChangePasteLink={onChangePasteLink}
          pasteLink={pasteLink}
          media_id={media_id}
          media_id1={media_id1}
          media_id2={media_id2}
          setUploadVideo={setUploadVideo}
          values={values}
          durationValue={durationValue}
        />
      )}
    </>
  )
}

export default AddRecipeForm
