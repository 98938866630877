import Addcateringvideo from "../../components/catering/add-catering-video"

const AddCateringVideo = () => {
  return (
    <>
      <Addcateringvideo />
    </>
  )
}

export default AddCateringVideo
