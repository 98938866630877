import Bannerlist from "../../components/Home/banner-list"

const BannerList = () => {
  return (
    <>
      <Bannerlist />
    </>
  )
}

export default BannerList
