import React, { useEffect, useState } from "react"
import Inputs from "../common/Inputs/InputField"
import ResetDoneButton from "../common/Buttons/resetDoneButton"
import UploadImage from "../common/UploadImage/uploadImage"
import { useMutation } from "@apollo/client"
import {
  ADD_PRODUCT,
  FETCH_PRODUCT,
  UPDATE_PRODUCT,
  updateUploadMedia,
  uploadMedia,
} from "./queries"
import { MakeAmazonProductStore } from "../../store/coursestore/courseSlice"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  errorMessageShow,
  resetMessageShow,
  successMessageShow,
} from "../common/Function/commonFunction"
import { useFormik } from "formik"
import { validationAmazon } from "../common/Function/validationFunction"
import Titles from "../common/Title/Title"
import { prepareFormDataAmazon } from "../common/Function/prepareFormData"

const AddAmazonProductsForm = (props: { singleArray?: string[] }) => {
  const { singleArray } = props
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const [addProducts] = useMutation(ADD_PRODUCT)
  const [updateProducts] = useMutation(UPDATE_PRODUCT)
  const [isProcess, setIsProcess] = useState<boolean>(false)
  let newArray: any
  newArray =
    singleArray !== undefined && singleArray.length ? singleArray[0] : ""
  const thumbnail =
    newArray?.product_media &&
    newArray?.product_media[0] &&
    newArray?.product_media[0]?.product_media
  const [media_id, setMedia_id] = useState("")
  const initialValues = {
    product_id: newArray ? newArray?.product_id : "",
    product_name: newArray ? newArray.product_name : "",
    product_link: newArray ? newArray?.product_link : "",
    selectedImage: thumbnail ? thumbnail.media_desc : "",
  }

  useEffect(() => {
    if (newArray) {
      setMedia_id(
        newArray.product_media[0] && newArray.product_media[0].media_id,
      )
    }
  }, [newArray])

  const addProduct = (values: any) => {
    setIsProcess(true)
    addProducts({
      variables: values,
      refetchQueries: [{ query: FETCH_PRODUCT }],
      onError: (error) => {
        if (error.message) {
          return errorMessageShow(error)
        }
      },
      onCompleted: (data) => {
        if (data.insert_products.__typename) {
          setIsProcess(false)
          const product_id = data.insert_products.returning[0].product_id
          if (formik.values.selectedImage !== "") {
            uploadMedia(prepareFormDataAmazon(formik, product_id, ""), navigate)
          } else {
            navigate(`/amazon-products-list`)
          }
          return successMessageShow("Amazon product add successfully!")
        }
      },
    }).then((r) => console.log(r))
  }

  const editProduct = (values: any) => {
    setIsProcess(true)
    updateProducts({
      variables: values,
      refetchQueries: [{ query: FETCH_PRODUCT }],
      onError: (error) => {
        if (error.message) {
          return errorMessageShow(error)
        }
      },
      onCompleted: (data) => {
        if (data.update_products.__typename) {
          const product_id = data.update_products.returning[0].product_id
          setIsProcess(false)
          if (
            media_id !== undefined &&
            formik.values.selectedImage !== "" &&
            formik.values.selectedImage !== "amazon product"
          ) {
            updateUploadMedia(
              media_id,
              prepareFormDataAmazon(formik, product_id, media_id),
              navigate,
            )
          } else {
            if (
              formik.values.selectedImage !== "" &&
              formik.values.selectedImage !== "amazon product"
            ) {
              uploadMedia(
                prepareFormDataAmazon(formik, product_id, ""),
                navigate,
              )
            }
            navigate(`/amazon-products-list`)
          }
          return successMessageShow("Amazon product edit successfully!")
        }
      },
    }).then((r) => console.log(r))
  }

  //onClick save button
  const submitDoneButton = (values: any) => {
    if (newArray !== "") {
      editProduct(values)
    } else {
      addProduct(values)
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationAmazon,
    onSubmit: (values) => {
      submitDoneButton(values)
    },
  })

  useEffect(() => {
    const data = {
      product_name: formik.values.product_name,
      selectedImage: formik.values.selectedImage,
      editImage: thumbnail?.media_url,
    }
    dispatch(MakeAmazonProductStore(data))
  }, [initialValues])

  return (
    <>
      <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-5'>
        <form onSubmit={formik.handleSubmit}>
          <div className='add-category-details'>
            <Titles
              label={`${newArray !== "" ? "EDIT" : "ADD"} AMAZON PRODUCTS`}
            />
            <div className='only-padding'>
              <Inputs
                MaxLength={30}
                label={"Product Name"}
                type={"text"}
                placeHolder={"Enter Product Name"}
                name='product_name'
                onChange={formik.handleChange}
                value={formik.values.product_name}
                errors={formik.errors.product_name}
                touched={formik.touched.product_name}
              />

              <Inputs
                MaxLength={200}
                label={"Paste Product Link"}
                type={"text"}
                placeHolder={"Paste Link"}
                name='product_link'
                onChange={formik.handleChange}
                value={formik.values.product_link}
                errors={formik.errors.product_link}
                touched={formik.touched.product_link}
              />

              {/*<!------- upload image section start-----!>*/}
              <UploadImage
                selectedImage={formik.values.selectedImage}
                title={"Upload Image (Thumbnail)"}
                setSelectedImage={(selectedImage: any) => {
                  formik.setFieldValue("selectedImage", selectedImage)
                }}
                errors={formik.errors.selectedImage}
                touched={formik.touched.selectedImage}
                isEdit={formik.values.selectedImage !== "" ? true : false}
              />

              {/*<!------- button section start-----!>*/}
              <div className='button-div'>
                <ResetDoneButton
                  disableFirst={!formik.dirty}
                  isProcess={isProcess}
                  firstButton={"Reset"}
                  secondButton={"Done"}
                  onClickFirst={() => {
                    formik.resetForm()
                    resetMessageShow().then((r) => console.log(r))
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddAmazonProductsForm
