import React from "react"
import {
  dashboard,
  dashboardCourseProps,
  recentCourseDashboard,
} from "../../config/commonType"

const RecentCourseDashboard = (props: recentCourseDashboard) => {
  const { initialData, data2 } = props

  return (
    <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
      <div className='add-category-details'>
        <div className='add-category-and-close-button'>
          <div className='add-category-title'>
            <h1>Recent Courses Purchased</h1>
          </div>
        </div>
        <div className='recent-courses-table mb-4'>
          <table className='recent-courses-table-inner'>
            <thead>
              <tr className='recent-table-heading'>
                <th>Courses Name</th>
                <th>Name</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {initialData &&
                initialData.map((item: dashboard, index: React.Key) => {
                  return (
                    <>
                      <tr className='recent-table-details' key={index}>
                        <td>{item.course_name}</td>
                        <td>
                          {data2?.user_master
                            ? data2?.user_master.map(
                                (
                                  name: dashboardCourseProps,
                                  index: React.Key,
                                ) => {
                                  return name.user_id === item.user_id ? (
                                    <span key={index}>{name.full_name}</span>
                                  ) : (
                                    ""
                                  )
                                },
                              )
                            : ""}
                        </td>
                        <td>
                          {item.course_amount
                            ? "₹ " + item.course_amount
                            : "₹ " + "0"}
                        </td>
                      </tr>
                    </>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default RecentCourseDashboard
