import Form from "react-bootstrap/Form"
import { useNavigate, useParams } from "react-router-dom"
import {
  ALL_USERS,
  FETCH_COURSE_SINGLE,
  GET_COURSE,
  INSERT_COURSE_ENROLLMENT,
  UPDATE_ENROLLMENT,
} from "./queries"
import React, { useEffect, useState } from "react"
import { PropertyUserActive } from "../../config/commonType"
import { urls } from "../../constants"
import {
  requestOptions,
  fetchCourseEnrollment,
  fetchUserName,
  errorMessageShow,
  successMessageShow,
  fetchCourseEnrollmentIds,
  groupBys,
} from "../common/Function/commonFunction"
import TabTitle from "../../pages/tab-title/TabTitle"
import Titles from "../common/Title/Title"
import { useMutation, useQuery } from "@apollo/client"
import { useSelector } from "react-redux"
import store from "../../store"

const UserActivePage = () => {
  const { id } = useParams()
  let navigate = useNavigate()

  const { data } = useQuery(ALL_USERS)
  const userName = fetchUserName(data?.user_master, id)

  const [course_status, setCourse_status] = useState<any>([])
  const [newResult, setNewResult] = useState<any>()
  const [addEnrollment] = useMutation(INSERT_COURSE_ENROLLMENT)
  const [updateEnrollment] = useMutation(UPDATE_ENROLLMENT)
  const storeReducer: any = useSelector(
    (state: ReturnType<typeof store.getState>) => state?.userDetails?.user,
  )

  useEffect(() => {
    fetchData().then((r) => console.log(r))
    fetchDatas().then((r) => console.log(r))
  }, [])

  //Fetching single data
  const fetchData = async () => {
    const graphql = JSON.stringify({
      query: GET_COURSE,
      variables: { user_id: id, user_ids: id },
    })

    const Url = urls.api_url
    // @ts-ignore
    const res = await fetch(Url, requestOptions(graphql))
    const usersData = await res.json()
    const onlineArray = groupBys(usersData?.data?.courses)
    setCourse_status(onlineArray?.online)
  }

  //Fetching single data
  const fetchDatas = async () => {
    const graphql = JSON.stringify({
      query: FETCH_COURSE_SINGLE,
      variables: { user_id: id },
    })

    const Url = urls.api_url
    // @ts-ignore
    const res = await fetch(Url, requestOptions(graphql))
    const usersData = await res.json()
    setNewResult(usersData.data?.course_enrollments)
  }

  const onSwitchChange = (
    course_discount_amount?: any,
    course_id?: any,
    is_deleted?: boolean | undefined,
    ids?: any,
  ) => {
    if (ids === undefined) {
      addSwitch(course_discount_amount, course_id)
    } else if (ids !== undefined) {
      if (ids.course_id === course_id) {
        updateSwitch(
          course_discount_amount,
          course_id,
          is_deleted,
          ids.course_enrollment_id,
        )
      } else {
        addSwitch(course_discount_amount, course_id)
      }
    }
  }

  const addSwitch = (transfer_amount: any, course_id: any) => {
    addEnrollment({
      variables: {
        course_id: course_id,
        user_id: id,
        is_deleted: false,
        created_by: storeReducer?.user_id,
        updated_by: storeReducer?.user_id,
        transfer_amount: transfer_amount,
      },
      onError: (error) => {
        if (error.message) {
          return errorMessageShow(error)
        }
      },
      onCompleted: (data) => {
        if (data.insert_course_enrollments.__typename) {
          fetchData().then((r) => console.log(r))
          fetchDatas().then((r) => console.log(r))
          return successMessageShow("User Active successfully!")
        }
      },
    }).then((r) => console.log(r))
  }

  const updateSwitch = (
    transfer_amount: any,
    course_id: any,
    is_deleted: boolean | undefined,
    course_enrollment_id: any,
  ) => {
    updateEnrollment({
      variables: {
        is_deleted: is_deleted,
        course_enrollment_id: course_enrollment_id,
        course_id: course_id,
        updated_by: storeReducer?.user_id,
        transfer_amount: transfer_amount,
      },
      onError: (error) => {
        if (error.message) {
          return errorMessageShow(error)
        }
      },
      onCompleted: (data) => {
        if (data.update_course_enrollments.__typename) {
          fetchData().then((r) => console.log(r))
          fetchDatas().then((r) => console.log(r))
          return successMessageShow("User updated successfully!")
        }
      },
    }).then((r) => console.log(r))
  }

  if (course_status === undefined) {
    return <div className='loading_message mt-200'>Loading...</div>
  }

  const onRedirectUserList = () => {
    navigate("/user-list")
  }

  return (
    <>
      <TabTitle title={"User List"} />
      <section className='add-category dashboard'>
        <div className='common-page-padding'>
          <div className='recent-courses-and-monthly-revenue'>
            <div className='add-category-details'>
              <Titles
                closeIcon={true}
                onClick={onRedirectUserList}
                label={userName && userName[0] && userName[0]?.full_name}
              />
              <div className='recent-courses-table'>
                <table className='recent-courses-table-inner'>
                  <thead>
                    <tr className='recent-table-heading'>
                      <th>Courses Name</th>
                      <th>Duration</th>
                      <th>Amount</th>
                      <th>Active / Inactive</th>
                    </tr>
                  </thead>

                  {course_status &&
                    course_status.map(
                      (item: PropertyUserActive, index: React.Key) => {
                        return (
                          <tbody key={index}>
                            <tr className='recent-table-details'>
                              <td>{item?.course_name}</td>
                              <td>
                                {item?.total_time
                                  ? item.total_time + " min "
                                  : "00" + " min "}
                              </td>
                              <td>
                                {item?.course_discount_amount
                                  ? " ₹ " + item?.course_discount_amount
                                  : " ₹ " + "0"}
                              </td>
                              <td>
                                <div className='active-inactive-radio-button'>
                                  <Form>
                                    <Form.Check
                                      onChange={() => {
                                        onSwitchChange(
                                          item.course_discount_amount,
                                          item.course_id,
                                          fetchCourseEnrollment(
                                            newResult,
                                            item.course_id,
                                          ),
                                          fetchCourseEnrollmentIds(
                                            newResult,
                                            item.course_id,
                                          ),
                                        )
                                      }}
                                      checked={fetchCourseEnrollment(
                                        newResult,
                                        item.course_id,
                                      )}
                                      type='switch'
                                      id='custom-switch'
                                    />
                                  </Form>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        )
                      },
                    )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default UserActivePage
