import React from "react"
import { useSelector } from "react-redux"
import store from "../../store"
import Titles from "../common/Title/Title"

const Recipes = () => {
  const storeReducer = useSelector(
    (state: ReturnType<typeof store.getState>) => state.courseReducers.recipe,
  )

  return (
    <>
      <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6'>
        <div className='add-category-details'>
          <Titles label={`RECIPES`} />
          <div className='recipe-uploaded-video-details'>
            <div className='recipe-uploaded-video-image'>
              {storeReducer.selectedImage &&
              storeReducer.selectedImage !== undefined &&
              storeReducer.selectedImage !== "recipe image" ? (
                <img
                  src={URL.createObjectURL(storeReducer?.selectedImage)}
                  alt='Thumb'
                />
              ) : storeReducer?.editImage ? (
                <img src={storeReducer?.editImage} alt='' />
              ) : (
                <img src='/images/recipe-video-img.png' alt='' />
              )}
            </div>
            <div className='uploaded-video-title'>
              <p>{storeReducer.recipe_name}</p>
            </div>
            <div className='recommended-block-list recipe-recommended-block-list'>
              <ul>
                <li>{storeReducer.recipe_details}</li>
              </ul>
            </div>
            {storeReducer.uploadVideo && (
              <div className='multiple-image-show promo-video'>
                <div className='icon-section'>
                  <img className='' src={"/images/video-icon.svg"} alt={""} />
                  <p>
                    {storeReducer.uploadVideo?.media_fk
                      ? storeReducer.uploadVideo?.media_fk?.media_name
                      : storeReducer.uploadVideo?.name}
                  </p>
                </div>
                <p className='ml-auto'>
                  {storeReducer?.durationValue !== 0
                    ? Number(storeReducer?.durationValue)?.toFixed(0) +
                      " Minutes"
                    : ""}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Recipes
