import React from "react"
import Products from "./Products"
import AddAmazonProductsForm from "./addAmazonProductForm"
import TabTitle from "../../pages/tab-title/TabTitle"

const AmazonProducts = (props: { singleArray?: string[] }) => {
  const { singleArray } = props
  return (
    <>
      <TabTitle title={"Add Amazon Product"} />
      <section className='add-recipes'>
        <div className='common-page-padding'>
          <div className='row'>
            {/*<!------ add amazon products section start -----!>*/}
            <AddAmazonProductsForm singleArray={singleArray} />
            {/*<!------ Products section start -----!>*/}
            <Products />
          </div>
        </div>
      </section>
    </>
  )
}

export default AmazonProducts
