import React, { useEffect, useState } from "react"
import { urls } from "../../constants"
import { showBannerProps } from "../../config/commonType"
import {
  requestOptions,
  successMessageShow,
} from "../common/Function/commonFunction"
import { DELETE_BANNER, FETCH_BANNER } from "./queries"
import { useNavigate } from "react-router-dom"
import SingleBannerCard from "../common/Cards/singleBannerCard"

const ShowBannerList = () => {
  let navigate = useNavigate()
  const [users, setUsers] = useState<any>([])
  const [isProcess, setIsProcess] = useState<any>(false)
  const [isIds, setIsIds] = useState<any>()

  useEffect(() => {
    fetchData().then((r) => console.log(r))
  }, [])

  const fetchData = async () => {
    const graphql = JSON.stringify({ query: FETCH_BANNER })

    const Url = `${urls.api_url}`
    // @ts-ignore
    const res = await fetch(Url, requestOptions(graphql))
    const usersData = await res.json()
    setUsers(usersData?.data?.banner_media)
  }

  if (users && users.length <= 0) {
    return <div className='loading_message'>Loading...</div>
  }

  const onClickDelete = async (banner_id: any) => {
    setIsIds(banner_id)
    setIsProcess(true)
    const graphql = JSON.stringify({
      query: DELETE_BANNER,
      variables: { banner_id: banner_id, banner_ids: banner_id },
    })

    const Url = `${urls.api_url}`
    // @ts-ignore
    const res = await fetch(Url, requestOptions(graphql))
    const usersData = await res.json()
    if (usersData.data.delete_banners.affected_rows === 1) {
      setIsProcess(false)
      await successMessageShow("Banner delete successfully!")
      fetchData().then((r) => console.log(r))
    }
  }

  //onClick edit
  const editBanner = (banner_id: any) => {
    navigate(`/edit-banner/${banner_id}`)
  }

  return (
    <>
      <div className='banner-images-list-show-section'>
        <div className='row'>
          {users &&
            users.map((item: showBannerProps, index: any) => {
              return (
                <SingleBannerCard
                  item={item}
                  key={index}
                  banner_id={item.banner_id}
                  isIds={isIds}
                  editClick={editBanner}
                  isProcess={isProcess}
                  onClick={onClickDelete}
                />
              )
            })}
        </div>
      </div>
    </>
  )
}

export default ShowBannerList
