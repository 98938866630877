import React from "react"
import EditDeleteButton from "../Buttons/editDeleteButton"
import { OnlineCardProps } from "../../../config/commonType"
import { uniqueByThumbnail } from "../Function/commonFunction"

const SingleOnlineCard = (props: OnlineCardProps) => {
  const { item, onClick, course_id, editClick, isIds, isProcess } = props
  const thumbnail = uniqueByThumbnail(item)

  return (
    <>
      <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4'>
        <div className='couses-list-details'>
          <div className='couses-images'>
            {thumbnail && thumbnail[0] && thumbnail[0].media_fk?.media_url ? (
              <img
                src={
                  thumbnail && thumbnail[0] && thumbnail[0].media_fk?.media_url
                }
                alt=''
              />
            ) : (
              <img src='/images/courses-img.png' alt='' />
            )}
          </div>
          <div className='recommended-block'>
            <div className='recommended-block-title'>
              <h3>{item?.course_name ? item.course_name : ""}</h3>
            </div>
            <div className='recommended-block-list'>
              <ul className='scroll-list'>
                <li>{item?.course_details ? item?.course_details : ""}</li>
              </ul>
            </div>
            <div className='course-price-details'>
              <div className='real-and-discounted-price justify-content-between'>
                <div className='d-flex justify-content-between align-items-end'>
                  <div className='real-price'>
                    <p>
                      {item?.course_discount_amount
                        ? " ₹ " + item?.course_discount_amount
                        : ""}
                    </p>
                  </div>
                  <div className='discounted-price'>
                    <p>
                      {item?.course_amount ? " ₹ " + item?.course_amount : ""}
                    </p>
                  </div>
                </div>
                <div>
                  <p className='mb-0 font-size-14'>
                    {item?.isVideo ? item?.isVideo : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <EditDeleteButton
            matchId={isIds}
            isProcess={isProcess}
            onClickDelete={onClick}
            course_id={course_id}
            editClick={editClick}
          />
        </div>
      </div>
    </>
  )
}

export default SingleOnlineCard
