import gql from "graphql-tag"

export const GET_RECIPE = `query GetRecipes {
  recipes {
    recipe_id
    cuisine
    recipe_name
    recipe_status
    recipe_type
    serving
    time_unit
    category_id
    cook_time
    preparation_time
    total_time
    recipe_desc
    recipe_details
    created_dt
    updated_dt
    recipe_media {
      media_id
      media_fk {
        media_type
        media_url
        media_status
        media_name
        media_id
        media_desc
        media_video_type
        media_photo_type
      }
    }
  }
}

`

export const DELETE_RECIPES = gql`
  mutation ($recipe_id: bigint!, $recipe_ids: Int!) {
    delete_recipe_media(where: { recipe_id: { _eq: $recipe_ids } }) {
      affected_rows
      returning {
        media_id
        recipe_id
      }
    }
    delete_recipes(where: { recipe_id: { _eq: $recipe_id } }) {
      affected_rows
      returning {
        category_id
        cook_time
        created_dt
        cuisine
        preparation_time
        recipe_desc
        recipe_details
        recipe_id
        recipe_name
        recipe_status
        recipe_type
        serving
        time_unit
        total_time
        updated_dt
      }
    }
  }
`
