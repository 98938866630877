import React from "react"
import { useSelector } from "react-redux"
import store from "../../store"
import Titles from "../common/Title/Title"

const BannerPrev = () => {
  const storeReducer: any = useSelector(
    (state: ReturnType<typeof store.getState>) => state.courseReducers?.home,
  )

  return (
    <>
      <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6'>
        <div className='add-category-details'>
          <Titles label={"BANNER"} />
          <div className='recipe-uploaded-video-details'>
            <div className='recipe-uploaded-video-image'>
              {storeReducer.selectedImage !== undefined ? (
                <img
                  src={URL.createObjectURL(storeReducer.selectedImage)}
                  alt='Thumb'
                />
              ) : storeReducer.editImage ? (
                <img src={storeReducer.editImage} alt='Thumb' />
              ) : (
                <img src='/images/dal-img.png' alt='' />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BannerPrev
